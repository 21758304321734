import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Tooltip } from "@mui/material";
import styled from "styled-components";
import { CustomLabel, TextInput } from "./style";
import Typography from "../../Typography/Typography";
import { useGetConditionalErrorMessage } from "../../../hooks/useGetConditionalErrorMessage";
// import { StyledValue } from "../../../modules/Proposal-Journey/Proposal-Listing/ProposalListingView";
import { useGetSummaryValue } from "../../../hooks/useGetSummaryValue";
import { acronymFormatValue } from "../../../HelperFunctions/HelperFunctions";
import { removeExtraSpaces } from "../../../utils";
import { themeObj } from "../../../modules/theme/theme";

const Input = ({
  blockDetails,
  editDetails,
  id,
  label,
  required,
  onChange,
  onFocus,
  onBlur,
  name,
  value,
  error,
  inputRef,
  disabled,
  type = "text",
  defaultValue,
  isRequired,
  onEdit,
  icon,
  labelStyle,
  noFocusChange,
  proposalRead,
  fontSize,
  themeType,
  readOnly,
  testId,
  capitalize,
  disableMarginTop,
  description,
  errors,
  watch,
  numberToWord = false,
  Width,
  minHeight,
  borderNone,
  endAddornmentText,
  onKeyDown,
  disableOnPaste,
  labelSize,
  claim,
  ...rest
}) => {
  const theme = themeObj;
  const { Error } = Typography;
  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id,
  });

  const { fieldValue } = useGetSummaryValue({
    watch,
    id,
  });

  const handleInputChange = (e) => {
    let value = e.target.value;
    e.target.value = capitalize
      ? value.charAt(0).toUpperCase() + value.slice(1)
      : value;
  };

  const handleCombinedBlur = (e) => {
    e.target.value = removeExtraSpaces(e?.target?.value);
    inputRef?.onBlur(e);
    if (onBlur && typeof onBlur === "function") {
      onBlur(e);
    }
  };

  const numberAcronym = numberToWord ? acronymFormatValue(fieldValue) : "";
  // const numberInWords = numberToWord
  //   ? convertNumberToWord(Number(fieldValue))
  //   : "";

  return (
    <InputContainer Width={Width} minHeight={minHeight}>
      {label && (
        <CustomLabel
          htmlFor={id}
          title={label}
          labelSize={labelSize}
          claim={claim}
        >
          {/* {label?.length > 55 ? `${label.slice(0, 60)}...` : */}
          {label}
          {/* } */}
          {isRequired && <span className="isRequired">*</span>}
          {description && !proposalRead && (
            <Tooltip title={description} arrow placement="top">
              <InfoOutlinedIcon
                sx={{
                  fontSize: "12px",
                  marginLeft: "3px",
                  position: "relative",
                  top: "2px",
                }}
              />
            </Tooltip>
          )}
        </CustomLabel>
      )}
      {proposalRead ? (
        <StyledValue>{fieldValue || "- - -"}</StyledValue>
      ) : (
        <div style={{ position: "relative" }}>
          <TextInput
            {...inputRef}
            editDetails
            blockDetails={blockDetails}
            name={name}
            type={type}
            disabled={disabled}
            required={required}
            onKeyUp={(e) => handleInputChange(e)}
            onChange={(e) => [
              inputRef?.onChange && inputRef.onChange(e),
              onChange && onChange(e),
            ]}
            onKeyDown={(e) => (onKeyDown ? onKeyDown(e) : undefined)}
            placeholder=" "
            id={id}
            onFocus={onFocus}
            onBlur={handleCombinedBlur}
            defaultValue={defaultValue}
            value={value}
            error={error || errorMessage}
            readOnly={readOnly}
            proposalRead={proposalRead}
            data-testid={testId}
            onPaste={(e) => (disableOnPaste ? e.preventDefault() : undefined)}
            claim={claim}
            {...rest}
          />
          {icon && (
            <i
              className={icon}
              style={{
                position: "absolute",
                top: "30px",
                right: "35px",
                fontSize: fontSize ? `calc(15px + ${fontSize - 92}%)` : "15px",
                cursor: "pointer",
              }}
              onClick={() => {
                onEdit && onEdit();
              }}
            ></i>
          )}
          {!!errorMessage && <Error>{errorMessage}</Error>}
          {numberToWord && <ToAcronym>{numberAcronym}</ToAcronym>}
          {/* {numberToWord && <ToWord>{numberInWords}</ToWord>} */}
          {endAddornmentText && (
            <EndAddornment>{endAddornmentText}</EndAddornment>
          )}
        </div>
      )}
    </InputContainer>
  );
};

export default Input;

const InputContainer = styled.div`
  position: relative;
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : "75px")};
  width: ${({ Width }) => (Width ? Width && `${Width}px` : "100%")};
  .isRequired {
    color: red;
    margin-left: 3px;
  }
`;

const ToAcronym = styled("label")(({ isInsideInput }) => ({
  color: "rgb(17, 85, 95)",
  paddingLeft: "1px",
  fontSize: "12px",
  position: "absolute",
  right: "8px",
  top: "7px",
  padding: "0px 5px",
  backgroundColor: "#80808045",
  minWidth: "65px",
}));

const EndAddornment = styled.span`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
  margin-right: 10px;
  font-size: 12px;
  color: #808080;
`;
const StyledValue = styled("span")({
  gridArea: "1 / 1 / 2 / 3",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "pre-line",
  color: "#000c",
  marginLeft: "3px",
  marginRight: "2px",
  boxSizing: "border-box",
  fontWeight: "600",
  fontSize: "14px",
  display: "inline-block",
  wordWrap: "break-word",
});

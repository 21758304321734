import React from "react";
import PropTypes from "prop-types";
import { Label, SelectInput, LoaderDiv } from "./style";

const Select = ({
  userViewInput,
  id,
  label,
  options,
  required,
  onChange,
  name,
  value,
  data,
  placeholder,
  error,
  inputRef,
  disabled,
  isLoader,
  isRequired,
  labelStyle,
  themeType,
  ...rest
}) => (
  <div style={{ position: "relative", display: "flex", marginTop: "20px" }}>
    {isLoader ? (
      <LoaderDiv>
        <div>
          <div className="bounce1"></div>
          <div className="bounce2"></div>
          <div className="bounce3"></div>
        </div>
      </LoaderDiv>
    ) : (
      <SelectInput
        userViewInput={userViewInput}
        error={error}
        {...(!!data ? { value: data } : { value: value })}
        name={name}
        ref={inputRef}
        id={id}
        onChange={onChange}
        required={required}
        disabled={disabled}
        {...rest}
      >
        {/* option map */}
        {options ? (
          <>
            {!!placeholder && (
              <option id={`select_${id}`} key={`select-${id}`} value="">
                {placeholder}
              </option>
            )}
            {options.map((item) => (
              <option
                key={item.id + id}
                id={id + "_" + item.id}
                value={item.value}
              >
                {item.name}
              </option>
            ))}
          </>
        ) : null}
      </SelectInput>
    )}
    {/*  top span*/}
    <Label
      themeType={themeType}
      style={labelStyle}
      htmlFor={name}
      userViewInput={userViewInput}
    >
      {label}
      {isRequired ? <sup> *</sup> : null}
    </Label>
  </div>
);

Select.defaultProps = {
  label: "label",
  required: true,
  id: "default",
  disabled: false,
  isLoader: false,
  // value: "",
};

Select.propTypes = {
  label: PropTypes.string,
  option: PropTypes.array,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  id: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Select;

import styled from "styled-components";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";

const GlobalModal = ({
  open,
  onClose,
  title,
  width,
  maxHeight,
  overflowVisible,
  overflowHidden,
  children,
  enableBackdropClick = false,
  Margin,
  reset,
  color,
  border,
  noPadding,
  ...rest
}) => {
  const handleBackdropClick = (event) => {
    // Prevent closing the modal when clicking outside of it
    event.stopPropagation();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      BackdropProps={
        !enableBackdropClick && {
          onClick: handleBackdropClick,
        }
      }
    >
      <ModalContainer
        width={width}
        maxHeight={maxHeight}
        overflowVisible={overflowVisible}
        overflowHidden={overflowHidden}
        Margin={Margin}
        {...rest}
      >
        <ModalHeader border={border}>
          <ModalTitle color={color}>{title}</ModalTitle>
          <CloseButton onClick={onClose}>
            <CloseIcon />
          </CloseButton>
        </ModalHeader>
        <div>{children}</div>
      </ModalContainer>
    </Modal>
  );
};

export default GlobalModal;

const ModalContainer = styled.div`
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: ${(props) => (props.width ? props.width : "1000px")};
  max-height: ${(props) =>
    props.maxHeight ? props.maxHeight + "px" : "600px"};
  overflow: ${(props) =>
    props.overflowVisible
      ? "visible"
      : props.overflowHidden
      ? "hidden"
      : "auto"};
  max-width: 90%;
  scrollbar-width: none;
  margin: ${(props) => props.Margin && props.Margin + "px"};
  border-radius: 10px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ noPadding }) =>
    noPadding ? "9px 24px 0px" : "16px 25px 6px 25px"};
  height: ${({ noPadding }) => noPadding && "74px"};
  background-color: #fff;
  position: relative;
  font-weight: 700;
  font-size: 27px;
  color: #000;
  border-bottom: ${({ border }) => (border ? border : "1px solid #cacacacc")};
  border-radius: ${({ border }) => (border ? "10px 10px 0 0" : "")};
  svg {
    font-size: 20px;
    color: #178e80;
  }
  position: sticky;
  top: 0;
  z-index: 2;
`;

const ModalTitle = styled.h3`
  margin: 0;
  color: ${({ color }) => (color ? color : "#4373eb")};

  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
`;

const CloseButton = styled(Button)`
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 30px;
  svg {
    font-size: 18px;
    color: #4373eb;
  }
`;

import { Box, Container, Grid, useMediaQuery, useTheme } from "@mui/material";
import React from "react";
import HelpForm from "./HelpForm";
import SupportAndFAQ from "./SupportAndFAQ";
import FAQ from "./FAQ";
import RaisedRequest from "./RaisedRequest";

const Help = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Grid
        container
        style={{
          flexWrap: "nowrap",
        }}
        sx={{
        }}
        direction={isMobile ? "column" : "row"}
        alignItems={isMobile ? "flex-end" : "flex-start"}
      >
        <Grid lg={8} xs={12} item>

          <HelpForm />
        </Grid>

        <Grid item lg={4} xs={12} margin={2}>
          <RaisedRequest />
        </Grid>
      </Grid>
      <Grid item xs={12} marginTop={1}>
        <FAQ />
      </Grid>
    </>

  );
};

export default Help;

import React from "react";
import { Select } from "../../components";
import { Grid } from "@mui/material";
import styled from "styled-components";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const optionsData = [
  {
    id: "",
    name: "Select",
    value: "",
  },
  {
    id: 2,
    name: "Data",
    value: "data",
  },
];

export const BarChart = () => {
  const data = {
    labels: ["January", "February", "March", "April", "May", "June"],
    datasets: [
      {
        label: "Cold Leads",
        data: [100, 200, 300, 490, 500, 300],
        backgroundColor: "#84E8F4",
        borderColor: "black",
        barThickness: 30,
        borderRadius: 5,
      },
      {
        label: "Hot Leads",
        data: [100, 200, 300, 490, 500, 300],
        backgroundColor: "#51459E",
        borderColor: "red",
        barThickness: 30,
        borderRadius: 5,
      },
    ],
  };
  const options = {
    scales: {
      x: {
        display: false, //this will remove all the x-axis grid lines
        // grid: {
        //   display: false,
        // },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <>
      <InputWrape>
        <div>
          <Select
            themeType="theme1"
            name="select_channel"
            id="select1"
            value="select_channel"
            label="Select Channel"
            options={optionsData}
          />
        </div>
        <div>
          <Select
            themeType="theme1"
            name="select_region"
            id="select2"
            value="select_region"
            label="Select Region"
            options={optionsData}
          />
        </div>
        <div>
          <Select
            themeType="theme1"
            name="select_type"
            id="select3"
            value="select_type"
            label="Business Type"
            options={optionsData}
          />
        </div>
      </InputWrape>
      <div style={{ width: "auto", height: "250px", marginLeft: "20px" }}>
        <Bar data={data} options={options} />
      </div>
    </>
  );
};

const InputWrape = styled.div`
  display: flex;

  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1420px) {
    flex-wrap: wrap;
  }
  div {
    flex: 1;
  }
`;

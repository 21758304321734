// all error
export const serializeError = (payload) => {
  let message = "";
  if (typeof payload === "string") message = payload;
  else if (Array.isArray(payload)) {
    message = payload;
  } else {
    for (const property in payload) {
      message = `${message}
    ${payload[property][0]}`;
    }
  }
  return message;
};

// common action creatre(single get & post)
export const actionStructre = async (
  dispatch,
  onSuccess,
  onError,
  api,
  payload,
  specifyError
) => {
  const { data, message, errors, success, errorSpecific } = await api(payload);
  if (data?.data || success) {
    dispatch(onSuccess(data?.data || message));
  } else {
    specifyError && errorSpecific && dispatch(specifyError(errorSpecific));
    dispatch(onError(errors || message));
    console.error("Error", errors || message);
  }
};

// common action creatre(single get & post)
export const actionStructreBoth = async (
  dispatch,
  onSuccess,
  onError,
  api,
  payload,
  specifyError
) => {
  const { data, message, errors, success, errorSpecific } = await api(payload);
  if (data.data && success) {
    dispatch(onSuccess(data.data || message));
  } else {
    specifyError && errorSpecific && dispatch(specifyError(errorSpecific));
    dispatch(onError(errors || message));
    console.error("Error", errors || message);
  }
};

export const numOnlyNoZero = (event) => {
  let key = event.keyCode || event.which;
  var startPos = event.currentTarget.selectionStart;

  if (startPos === 0 && (key === 48 || key === 96)) {
    event.preventDefault();
  } else if (
    event.shiftKey === false &&
    ((key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46)
    // key === 144
  ) {
  } else {
    event.preventDefault();
  }
};

export const numOnly = (event) => {
  let key = event.keyCode || event.which;
  if (
    event.shiftKey === false &&
    ((key >= 48 && key <= 57) ||
      (key >= 96 && key <= 105) ||
      key === 8 ||
      key === 9 ||
      key === 13 ||
      key === 16 ||
      key === 17 ||
      key === 20 ||
      key === 35 ||
      key === 36 ||
      key === 37 ||
      key === 39 ||
      key === 46)
    // key === 144
  ) {
  } else {
    event.preventDefault();
  }
};

export const alpha = (e) => {
  let k = e.keyCode || e.which;
  return (
    (k > 64 && k < 91) ||
    (k > 96 && k < 123) ||
    k === 8 ||
    k === 32 ||
    (k >= 48 && k <= 57)
  );
};

export const noSpace = (event) => {
  let key = event.keyCode || event.which;
  if (key === 32) {
    event.preventDefault();
  }
};

export const toDate = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day);
};

export const toDateOld = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year - 3, month - 1, day);
};

export const toDateDayOld = (dateStr) => {
  const [day, month, year] = dateStr.split("-");
  return new Date(year, month - 1, day - 1);
};

export const scrollToTargetAdjusted = (id, offsetVal) => {
  var element = document.getElementById(`${id}`);
  if (element) {
    const offset = offsetVal || 45;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }
};

export const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export const reloadPage = (url) => {
  const link = document.createElement("a");
  link.href = url;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const randomString = () =>
  Math.random().toString(36).substring(2, 15) +
  Math.random().toString(36).substring(2, 15);

export const currencyFormater = (amount, decimal) => {
  if (amount) {
    let formatedAmount = Number(amount)
      ?.toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .slice(0, -3);
    if (decimal) {
      formatedAmount = Number(amount)
        ?.toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,");
    }
    return formatedAmount;
  } else {
    return 0;
  }
};

export const camelToUnderscore = (key) => {
  var result = key.replace(/([A-Z])/g, " $1");
  return result.split(" ").join("_").toLowerCase();
};

export const camelToSnakeCase = (str) => {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, (s) => s.toUpperCase());
};

export const _b64ToDataurl = (b64Data) => {
  //converting base64 to blob
  const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: "image/png" });
    return blob;
  };
  //returning data url
  const blob = b64Data && b64toBlob(b64Data);
  const blobUrl = blob && URL.createObjectURL(blob);
  return blobUrl;
};

export const getUrlPathName = () => {
  const pathName = window.location.pathname?.slice(1)?.toLowerCase();
  return pathName;
};

export function removeExtraSpaces(inputString) {
  const trimmedString = inputString.trim().replace(/\s+/g, " ");
  return trimmedString;
}

export const urlToBase64 = async (imageUrl) => {
  // Fetch the image as a blob
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  // Convert blob to base64
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });
};

export function formatIndianPrice(price) {
  const number = +price;
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(number);
}

export const downloadFile = (
  url,
  options,
  isTarget,
) => {

    const link = document.createElement("a");
    if (options) link.setAttribute("href", `options${encodeURIComponent(url)}`);
    if (isTarget) {
      link.setAttribute("target", `_blank`);
    }
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};
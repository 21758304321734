import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetClaimListing = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return httpClient("claim-final/listing", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetStageListing = (id) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["claim-stage/listing", id],
    () =>
      httpClient("claim-Stage-History/listing", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortOrder: "asc",
          searchValues: {
            id: "",
            claim_data_ref_id: id,
          },
        },
      }),
    { enabled: !!id }
  );
  return { data, isLoading, isError, isSuccess };
};

// export const useGetStageListing = () => {
//   const { data, mutate } = useMutation(() => {
//     return httpClient("claim-Stage-History/listing", {
//       method: "POST",
//       data: {
//         page: 0,
//         size: 0,
//         sortOrder: "",
//         searchValues: {
//           id: "",
//           claim_data_ref_id: "",
//         },
//       },
//     });
//   });
//   return { data, mutate };
// };

import React, { useState } from "react";
import { Grid } from "@mui/material";
import { ReactComponent as HeartMonitor } from "../../utils/hearmonitor.svg";
import { ReactComponent as CrossHeart } from "../../utils/crossHeart.svg";
import { ReactComponent as Health } from "../../utils/rect896.svg";
import styled from "styled-components";
import ButtonWrapper from "../../components/ButtonWrapper";
import { Button } from "../../components";
import { useForm } from "react-hook-form";

const ManualPolicy = ({ setShow, setpolicyFormShow }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index, color) => {
    activeIndex === index ? setActiveIndex(null) : setActiveIndex(index);
  };

  const isActive = (index) => activeIndex === index;
  const { handleSubmit } = useForm();

  const onSubmit = () => {
    setShow(false);
    setpolicyFormShow(true);
  };

  return (
    <Wrapper onSubmit={handleSubmit(onSubmit)}>
      <Grid container columnSpacing={{ lg: 2 }}>
        {[
          {
            icon: <HeartMonitor />,
            color: "#EE5742",
            fill: "#EE5742",
            text: "Life",
          },
          {
            icon: <CrossHeart />,
            color: "#4483DF",
            fill: "#4483DF",
            text: "Non-life",
          },
          {
            icon: <Health />,
            color: "#2DAF6E",
            fill: "#2DAF6E",
            text: "Health",
          },
        ].map((item, index) => (
          <Grid item lg={4} key={index} sx={{ margin: 0 }}>
            <Col
              onClick={() => handleClick(index + 1, item.color)}
              bg={isActive(index + 1) ? item.color : "#ffffff"}
              fill={item.fill}
              isActive={isActive(index + 1)}
            >
              {item.icon}
              <span>{item.text}</span>
            </Col>
          </Grid>
        ))}
      </Grid>
      <ButtonWrapper style={{ margin: "10px 0 10px 0 " }}>
        <Button disabled={!activeIndex} type={"submit"} bgColor={"#5E5ADB"}>
          Submit
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.form`
  width: 450px;
  margin: 2px;
  padding: 20px 20px 0 20px;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  height: 100px;
  width: 100px;
  border-radius: 16px;
  background: ${(props) => props.bg || "#ffffff"};
  svg {
    fill: ${(props) => (props.isActive ? "#ffffff" : props.fill)};
  }
  color: ${(props) => (props.isActive ? "#ffffff" : "#000000")};
  span {
    font-size: 18px;
    font-weight: bold;
    margin: 5px 0 0 0;
  }
  &:hover {
    box-shadow: 1px 2px 3px 4px #efeaea;
    cursor: pointer;
  }
`;

export default ManualPolicy;

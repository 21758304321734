import * as React from "react";
import PropTypes from "prop-types";
import { Button } from "../../components";
import { styled as styledMui } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import styled from "styled-components";

const BootstrapDialog = styledMui(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    maxWidth: "800px",
    // minWidth: "600px",
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiPaper-root": {
    width: "700px",
    position: "relative",
    maxWidth: "none",
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, DialogFooter, ...other } = props;

  return (
    <StyledDialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </StyledDialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const DialogBox = ({
  inputRef,
  themeType,
  headerBG,
  MainBody,
  buttonText,
  TitleText,
  buttonBG,
  isOpen,
  handleClose,
}) => {
  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={isOpen}
    >
      <BootstrapDialogTitle
        headerBG={headerBG}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        <StyledTitle>{TitleText}</StyledTitle>
      </BootstrapDialogTitle>
      <DialogContent style={{ marginTop: "10px" }}>
        <MainBody inputRef={inputRef} />
      </DialogContent>
      <DialogActions>
        {buttonText ? (
          <Button
            themeType={themeType}
            round
            autoFocus
            // onClick={() => handleClose()}
          >
            {buttonText}
          </Button>
        ) : (
          <noscript></noscript>
        )}
      </DialogActions>
    </BootstrapDialog>
  );
};

const StyledDialogTitle = styled(DialogTitle)`
  height: 80px;
  padding-left: 24px;
  background: ${({ headerBG }) => (headerBG ? headerBG : "#ffff")};
`;
const StyledTitle = styled.span`
  color: #000000 !important;
  position: relative;
  font-weight: 700;
  font-size: 20px;
  padding-right: 20px;
  
  &::before {
    content: "";
    height: 4px;
    width: 20%;
    position: absolute;
    bottom: -8px;
    left: 0px;
    border-radius: 10px;
    background: #f9ae2b;
  }
  @media (max-width: 600px) {
    font-size: 15px;
  }
`;

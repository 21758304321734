export const useGetConditionalErrorMessage = ({ errors, id }) => {
  let errorMessage = "";
  const isMultipleFieldsErrors = id?.includes(".");

  const get_fields_in_id = (id) => {
    const fieldData = id?.split(".");

    const memberPartToFormat = fieldData?.[0];
    const idPartForMutlipleFields = fieldData?.[1];

    const isMemberPartToFormatDirty = /[\[]/.test(memberPartToFormat);

    if (isMemberPartToFormatDirty) {
      const index = memberPartToFormat.indexOf("[");
      const memberPart = memberPartToFormat?.substring(0, index);
      const indexPart = memberPartToFormat?.substring(index);

      return [memberPart, indexPart, idPartForMutlipleFields];
    }

    if (id) {
      return id?.split(".");
    }

    return [];
  };

  const [memberPart, indexPart, fieldId] = get_fields_in_id(id);

  const index = indexPart?.split("")?.[1];

  if (isMultipleFieldsErrors && !errors?.[memberPart]) {
    errorMessage = errors?.[fieldId]?.message;
  } else if (isMultipleFieldsErrors) {
    errorMessage = errors?.[memberPart]?.[index]?.[fieldId]?.message;
  } else {
    errorMessage = errors?.[id]?.message;
  }
  return { errorMessage };
};

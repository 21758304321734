import React from "react";
import NoDataFoundImage from "../../utils/nodatafound.png";
import styled from "styled-components";
import { Grid } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";

const NoDataFound = ({ image }) => {
  return (
    <>
      {image ? (
        <Grid item lg={12} xs={12} sx={{ margin: "auto" }}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                height: "50vh",
                width: "50vw",
              }}
              src={NoDataFoundImage}
            ></img>
          </Grid>
        </Grid>
      ) : (
        <NoDataContainer>
          <InfoIcon />
          <StyledPositionedSpan>No Pending Tasks</StyledPositionedSpan>
        </NoDataContainer>
      )}
    </>
  );
};

export default NoDataFound;

const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: grey;
`;

const StyledPositionedSpan = styled.span`
`;

import SearchOffRoundedIcon from "@mui/icons-material/SearchOffRounded";
import { Tooltip } from "@mui/material";
import { debounce, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Input } from "../";
import { CustomDropdownStyled } from "./CustomDropdown";
import { useGetSmartSearchOptions } from "./services/useGetSmartSearchOptions";

function SmartSearchDropdown({
  id,
  name,
  register,
  errors,
  watch,
  setValue,
  smartSearchEndpoint,
  inputFocus,
  setInputFocus,
  setShowSmartSearch,
  selectRef,
}) {
  const [debouncedValue, setDebouncedValue] = useState("");

  const watchedValue = watch && watch(id);
  const selectionValues = watch && watch("selection");

  useEffect(() => {
    if (watchedValue) {
      const debouncedFunction = debounce(() => {
        setDebouncedValue(watchedValue);
      }, 300);
      debouncedFunction();
      return () => {
        debouncedFunction.cancel();
      };
    }
  }, [watchedValue]);

  useEffect(() => {
    if (!watchedValue) {
      setDebouncedValue("");
    }
  }, [watchedValue]);

  const { data: response } = useGetSmartSearchOptions({
    input: debouncedValue,
    smartSearchEndpoint,
    enabled: !!debouncedValue && debouncedValue?.length >= 2,
  });

  const options = !isEmpty(response?.data?.data)
    ? response?.data?.data?.map((item) => {
        return {
          label: `${item?.firstName || item?.first_name}- ${
            item?.mobileNumber || item?.mobileNo
          }`,
          value: item?.user_id || item?.userId,
        };
      })
    : [];

  function filterUnique(array1, array2) {
    if (!array2) return array1;
    const map2 = new Map(array2?.map((obj) => [obj.label, obj]));
    return array1.filter((obj) => !map2?.has(obj.label));
  }

  const uniqueArray = filterUnique(options, selectionValues);

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const handleInputBlur = (e) => {
    setTimeout(() => {
      setInputFocus(false);
    }, 400);
  };

  return (
    <CustomDropdownStyled>
      <div
        style={{
          position: "relative",
          height: "32px",
        }}
      >
        <Input
          themeType="theme1"
          id={id}
          name={name}
          maxLength="50"
          placeholder="Search with name or mobile no."
          autoComplete="none"
          defaultValue={""}
          required={false}
          inputRef={register(id)}
          errors={errors}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
        />
        <Tooltip title="Close smart search" placement="top" arrow>
          <SearchOffRoundedIcon
            style={{
              position: "absolute",
              top: "7px",
              right: "4px",
            }}
            onClick={() => {
              selectRef.current.blur();
              setShowSmartSearch(false);
            }}
          />
        </Tooltip>
      </div>
      {inputFocus && uniqueArray?.length > 0 ? (
        <ul className="dropdown" style={{ top: "38px" }}>
          {uniqueArray?.map((item) => (
            <li
              key={item.label}
              onClick={() => {
                if (selectionValues?.length) {
                  setValue("selection", [
                    ...selectionValues,
                    {
                      label: item.label,
                      value: item.value,
                    },
                  ]);
                } else {
                  setValue("selection", [
                    {
                      label: item.label,
                      value: item.value,
                    },
                  ]);
                }
              }}
            >
              {item.label}
            </li>
          ))}
        </ul>
      ) : (
        <>
          {inputFocus && debouncedValue && (
            <>
              <ul className="dropdown" style={{ top: "38px" }}>
                <li style={{ color: "grey", textAlign: "center" }}>
                  No records found
                </li>
              </ul>
            </>
          )}
        </>
      )}
    </CustomDropdownStyled>
  );
}

export default SmartSearchDropdown;

import React from "react";
import styled from "styled-components";
import { SideBarLinks, icons } from "./layoutConstant";
import { useLocation, useNavigate } from "react-router-dom";
import ViewSidebarIcon from "@mui/icons-material/ViewSidebar";
import LOGO from "./Logo/fyntuneLogo.svg";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userInfo =
    sessionStorage.getItem("userInfo") &&
    JSON.parse(sessionStorage.getItem("userInfo"));

  return (
    <>
      <SidebarContainer>
        <LogoContainer onClick={() => navigate("/")}>
          <StyledLogo
            src={userInfo?.brokerLogo || LOGO}
            alt="Fyntune"
            loading="lazy"
          />
        </LogoContainer>
        <div>
          {SideBarLinks.map((link, index) => {
            // Fall Back Icon
            const IconComponent = icons[link.icon] || ViewSidebarIcon;
            return (
              <SideBarCapsule
                onClick={() => navigate(link.location)}
                key={index}
                isActive={link.location === location.pathname}
              >
                <IconComponent
                  htmlColor={
                    link.location === location.pathname ? "#4373eb" : "#495057"
                  }
                  className="icon"
                />
                <SideBarText isActive={link.location === location.pathname}>
                  {link.name}
                </SideBarText>
              </SideBarCapsule>
            );
          })}
        </div>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const StyledLogo = styled.img`
  height: 60px;
  width: 120px;
  object-fit: contain;
  cursor: pointer;
`;

const SideBarCapsule = styled.div`
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  border-radius: 5px;
  padding: 0px 8px;
  background-color: ${({ isActive }) => (isActive ? "white" : "#eff3f6")};
  &:hover {
    background-color: ${({ isActive }) =>
      isActive ? "rgba(230, 230, 230, 1)" : "white"};
    span {
      color: #4373eb;
    }
    .icon {
      fill: #4373eb;
    }
  }
`;

const SideBarText = styled.span`
  color: ${({ isActive }) => (isActive ? "#4373eb" : "#495057")};
  width: 100%;
  height: 18px;
  font-size: 16px;
  font-weight: 700;
  line-height: 18px;
  margin-left: 9px;
`;

const SidebarContainer = styled.div`
  
  position: fixed;
  height: 80vh;
  background: #eff3f6;
  padding: 0px 0px 0px 15px;
  div {
    margin-top: 10px;
  }
`;

import React from "react";
import { Grid } from "@mui/material";
import { Button, DatePickerInput, Input } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
import styled from "styled-components";
import ButtonWrapper from "../../components/ButtonWrapper";
import { useAddPolicyPdf, useGetLobList, useGetMasterData } from "./service";
import { Abc } from "@mui/icons-material";

const PolicyForm = () => {
  const { control, register, watch, handleSubmit } = useForm();
  const lobid = watch("lobId");

  const slug = [
    {
      masterSlug: "business_type_master",
      fieldSlug: "business_type_master",
      lobId: lobid?.value,
    },
    {
      masterSlug: "transaction_type_master_by_id",
      fieldSlug: "transaction_type_master_by_id",
      lobId: lobid?.value,
    },
    {
      masterSlug: "master_company",
      fieldSlug: "master_company",
      lobId: lobid?.value,
    },
  ];

  const { mutate } = useAddPolicyPdf();

  const { data } = useGetLobList();
  const lobObj = data?.data?.data?.map((item) => {
    return { label: item?.lob, value: item?.lob_id };
  });

  const { data: masterData } = useGetMasterData(lobid?.value ? slug : []);

  const businessType = masterData[0]?.data?.data?.[0]?.data?.map((item) => {
    return { label: item?.optionKey, value: item?.optionValue };
  });

  const transactionType = masterData[1]?.data?.data?.[0]?.data?.map((item) => {
    return { value: item?.optionKey, label: item?.optionValue };
  });
  const icCompany = masterData[2]?.data?.data?.[0]?.data?.map((item) => {
    return { value: item?.optionKey, label: item?.optionValue };
  });

  // this is because backend requires all key value irrespective of lob
  const pay = {
    lobId: 0,
    policyNumber: "",
    policyType: "",
    vehicleNumber: "",
    businessType: "",
    transactionTypeMaster: 0,
    policyStartDate: "",
    policyEndDate: "",
    sumAssured: "",
    policyHolderName: "",
    mobileNo: "",
    icName: "",
    icId: 0,
    document: "",
    policyDocument: "",
    modeofTransportation: "",
    nameoforganization: "",
    premiumAmount: "",
    idv: 0,
    renewable: "",
    odstart: "",
    odend: "",
    tpstart: "",
    tpend: "",
  };

  const onSubmit = (data) => {
    let payload = {};
    Object.entries(data)?.forEach(([key, value]) => {
      payload[key] =
        typeof value === "object" && value instanceof Date
          ? new Date(value).toISOString().slice(0, 10)
          : typeof value === "object"
          ? key === "icName"
            ? value.label
            : value.value
          : value;
    });
    payload["icId"] = data?.icName?.value;
    mutate({ ...pay, ...payload });
  };

  const renew = [
    { label: "Yes", value: "Y" },
    { label: "No", value: "N" },
  ];

  return (
    <>
      <InputFrame onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Dropdown
              id="lobId"
              selectObj={lobObj}
              label={"Select LOB"}
              control={control}
              maxMenuHeight={200}
              watch={watch}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              themeType={"theme1"}
              label="Policy Holder name"
              name="policyHolderName"
              id="policyHolderName"
              placeholder="Policy Holder name"
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register("policyHolderName")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              themeType={"theme1"}
              label="Mobile Number."
              name="mobileNo"
              id="mobileNo"
              placeholder="Mobile Number."
              autoComplete="none"
              defaultValue={""}
              required={false}
              inputRef={register("mobileNo")}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Dropdown
              themeType={"theme1"}
              label="IC Name"
              id="icName"
              placeholder="IC Name"
              autoComplete="none"
              defaultValue={""}
              required={false}
              control={control}
              selectObj={icCompany}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              themeType={"theme1"}
              label="Policy Number"
              name="policyNumber"
              id="policyNumber"
              placeholder="Policy Number"
              type="number"
              required={false}
              inputRef={register("policyNumber")}
            />
          </Grid>
          {["Health", "Term"]?.includes(lobid?.label) && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="Policy Start Date"
                  name="policyStartDate"
                  id="policyStartDate"
                  placeholder="Policy Start Date"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="Policy End Date"
                  name="policyEndDate"
                  id="policyEndDate"
                  placeholder="Policy End Date"
                  control={control}
                />
              </Grid>
            </>
          )}

          {["Car", "Bike"]?.includes(lobid?.label) && (
            <>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="OD Start date"
                  name="odstart"
                  id="odstart"
                  placeholder="OD Start date"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="OD Expiry date"
                  name="odend"
                  id="odend"
                  placeholder="OD Expiry date"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="TP Start date"
                  name="tpstart"
                  id="tpstart"
                  placeholder="OD Start date"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <DatePickerInput
                  themeType={"theme1"}
                  label="TP Expiry date"
                  name="tpend"
                  id="tpend"
                  placeholder="OD Expiry date"
                  control={control}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={4}>
                <Input
                  themeType={"theme1"}
                  label="IDV"
                  name="idv"
                  id="idv"
                  placeholder="idv"
                  type="number"
                  inputRef={register("idv")}
                />
              </Grid>
            </>
          )}

          {["Health"].includes(lobid?.label) && (
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Input
                themeType={"theme1"}
                label="Coverage"
                name="coverage"
                id="coverage"
                placeholder="Coverage"
                type="number"
                inputRef={register("coverage")}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Input
              themeType={"theme1"}
              label="Premium Amount"
              name="premiumAmount"
              id="premiumAmount"
              placeholder="Premium Amount"
              type="number"
              inputRef={register("premiumAmount")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Dropdown
              id="businessType"
              selectObj={businessType}
              label={"Select Business Type"}
              control={control}
              maxMenuHeight={200}
              watch={watch}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Dropdown
              id="renewable"
              selectObj={renew}
              label={"Select Renewable"}
              control={control}
              maxMenuHeight={200}
              watch={watch}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Dropdown
              id="transactionTypeMaster"
              selectObj={transactionType}
              label={"Select transaction Type"}
              control={control}
              maxMenuHeight={200}
              watch={watch}
            />
          </Grid>
        </Grid>
        <ButtonWrapper>
          <Button type="submit" themeType={"theme1"} bgColor={"#11555F"}>
            Submit
          </Button>
        </ButtonWrapper>
      </InputFrame>
    </>
  );
};

const InputFrame = styled.form`
  width: 100%;
  position: relative;
  padding: 10px;
`;

export default PolicyForm;

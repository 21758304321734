import React, { useState } from "react";
import styled from "styled-components";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import HelpIcon from "@mui/icons-material/Help";
import LogoutIcon from "@mui/icons-material/Logout";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import Swal from "sweetalert2";
import { useQueryClient } from "@tanstack/react-query";
import { restClient } from "../api/httpClient";
import { Badge, Tooltip } from "@mui/material";
import Notification from "../modules/Notification/Notification";
import { useGetNotifications } from "../modules/Notification/service";
import { useGetCustomerRecordsById } from "../modules/MyProfile/service";
const Navbar = ({ isMobileAndTablet }) => {
  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openNotification, setOpenNotification] = useState(false);
  let { notification } = useGetNotifications();

  const customerId = JSON.parse(
    sessionStorage?.getItem("userInfo")
  )?.customerId;
  // console.log('customerId', customerId)
  const { data: customerDataFromApi, isLoading: isLoadingCustomer } =
    useGetCustomerRecordsById({
      id: customerId,
      fetchFamily: "N",
    });
  const customerData = customerDataFromApi?.data?.data?.[0];

  const fullName = `${customerData?.firstName || ""} ${
    customerData?.lastName || ""
  }`;
  // if (notification.length === 0) {
  //   notification = [
  //     {
  //       "scheduledCommunicationsAudiencesId": 1663,
  //       "content": "Your Claim Request is Approved",
  //       "scheduledAt": "2024-07-16T11:08:00.000+05:30"
  //     },
  //     {
  //       "scheduledCommunicationsAudiencesId": 1663,
  //       "content": "<message>",
  //       "scheduledAt": "2024-07-16T11:08:00.000+05:30"
  //     },
  //   ]
  // }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowNotification = (e) => {
    setOpenNotification(e.currentTarget);
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  const handleLogout = () => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to log out?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, proceed!",
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire("Logged Out!", "You have successfully logged out", "success");
        sessionStorage.clear();
        navigate("/login");
        queryClient.removeQueries(["accessRightsMenus"]);
        delete restClient.defaults.headers.common["token"];
      }
    });
  };

  const userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
  return (
    <NavbarStyled location={location.pathname}>
      <UserContainer>
        <div
          className="profile-icon-div"
          style={{ cursor: "pointer" }}
          onClick={handleShowNotification}
        >
          <Tooltip
            title={`${
              notification?.length ? notification?.length : ""
            } Notification${notification?.length > 1 ? "s" : ""}`}
            arrow
          >
            <StyledBadge
              badgeContent={notification?.length}
              color="error"
              max={9}
            >
              <NotificationsOutlinedIcon
                className="profile-icon"
                style={{ marginRight: "20px" }}
              />
            </StyledBadge>
          </Tooltip>
        </div>
        {/* <AccountCircleIcon htmlColor=" #2C65F5" /> */}
        {/* USERNAME GOES HERE */}
        {/* <div className="icon">{userInfo?.firstName[0]}</div> */}
        <div className="icon">{customerData?.firstName[0]}</div>
        <div
          style={{
            marginRight: "10px",
          }}
        >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
          >
            {/* <span>{`${userInfo?.firstName} ${userInfo?.lastName}`}</span> */}
            <span>{fullName}</span>
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              "& .css-6hp17o-MuiList-root-MuiMenu-list": {
                minWidth: "120px",
              },
            }}
          >
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => {
                handleClose();
                navigate("/my-profile");
              }}
            >
              <AccountCircleIcon
                htmlColor="#495057"
                sx={{
                  height: "35px",
                }}
              />{" "}
              <span>Profile</span>
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={() => {
                handleClose();
                navigate("/help");
              }}
            >
              <HelpIcon
                htmlColor="#495057"
                sx={{
                  height: "35px",
                }}
              />
              <span>Help</span>
            </MenuItem>
            <MenuItem
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "10px",
              }}
              onClick={handleLogout}
            >
              <LogoutIcon
                htmlColor="#495057"
                sx={{
                  height: "35px",
                }}
              />{" "}
              <span>Logout</span>
            </MenuItem>
          </Menu>
        </div>
      </UserContainer>
      <Notification
        open={openNotification}
        handleClose={handleCloseNotification}
        notification={notification}
      />
    </NavbarStyled>
  );
};

export default Navbar;

const NavbarStyled = styled.nav`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* position: sticky; */
  top: 0;
  width: 120%;
  background: #eff3f6;
  color: white;
  padding: 12px 12px;
  border: 0px 0px 1px 0px;
  z-index: 10;
  max-height: ${({ location }) => (location === "/" ? "40px" : "60px")};
`;

const UserContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #000000;
  span {
    color: #2c2d2d;
    font-weight: 600;
  }
  .icon {
    color: white;
    height: 32px;
    width: 32px;
    margin: 0px 5px;
    cursor: pointer;
    border-radius: 50%;
    background-color: #d0121e;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-badge": {
    right: "20px",
    top: "0px",
    height: "16px",
    padding: "0px 5px",
    minWidth: 0,
    color: "white",
    fontSize: "10px",
  },
}));

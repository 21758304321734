import { Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";
import FilePickerForCustomerBulkUpload from "./filepicker";
import { Button, DatePickerInput, Input } from "../../components";
import Dropdown from "../../components/Dropdown/Dropdown";
import { useForm } from "react-hook-form";
import ButtonWrapper from "../../components/ButtonWrapper";
import { useAddPolicyPdf } from "./service";

const PdfUpload = () => {
  const { control, register, handleSubmit, setValue } = useForm();
  const { mutate, data } = useAddPolicyPdf();

  const onSubmit = (data) => {
    mutate(data);
  };

  return (
    <>
      <Wrapper onSubmit={handleSubmit(onSubmit)}>
        <Grid container columnSpacing={{ lg: 2, xs: 2 }}>
          <Grid item lg={6}>
            <FilePickerForCustomerBulkUpload setValue={setValue} />
          </Grid>
          <Grid item lg={6}>
            <Grid container>
              <input type="hidden" {...register("policyDocument")} />
              <Grid item lg={10}>
                <Input
                  themeType={"theme1"}
                  label={"Policy Number"}
                  name={"policyNumber"}
                  id={"policyNumber"}
                  placeholder={"field.placeholder"}
                  autoComplete="none"
                  defaultValue={""}
                  required={false}
                  inputRef={register("policyNumber")}
                />
              </Grid>
              <Grid item lg={10}>
                <Dropdown
                  themeType={"theme1"}
                  label={"Line of Business"}
                  name={"lob"}
                  id={"lob"}
                  placeholder={"Select lob"}
                  autoComplete="none"
                  defaultValue={""}
                  required={false}
                  inputRef={register("lob")}
                  control={control}
                />
              </Grid>
              <Grid item lg={10}>
                <DatePickerInput
                  themeType={"theme1"}
                  label={"Expiry Date"}
                  name={"expiryDate"}
                  id={"expiryDate"}
                  placeholder={"Enter Expiry Date"}
                  autoComplete="none"
                  required={false}
                  control={control}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ButtonWrapper style={{ padding: "0 5px 5px 0" }}>
          <Button type={"submit"} bgColor={"#5E5ADB"}>
            Submit
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </>
  );
};
const Wrapper = styled.form`
  background: #ffffff;
  margin: 2px;
`;
export default PdfUpload;

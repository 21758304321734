import { Fade, Tooltip, tooltipClasses } from "@mui/material";
import styled from "styled-components";

export const ComponentWrapper = styled.div`
  margin: 0 0 20px 0;
  /* width: ${({ width }) => width && width + "px"}; */
`;

export const Heading = styled.h2`
  color: ${(props) => (props.color ? props.color : "#787878")};
  font-size: ${(props) => (props.size ? props.size : "28px")};
  font-weight: normal;
  padding: 0;
  margin: 0;
`;

export const Image = styled.img`
  height: ${(props) => (props.height ? props.height : "48px")};
  width: ${(props) => (props.width ? props.width : "48px")};
  border-radius: ${(props) => (props.radius ? props.radius : "")};
  ${({blend}) => blend ? `mix-blend-mode: darken;` : ``}
`;

export const StatusPolicyCard = styled.div`
  width: 100%;
  height: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  padding: 12px;
  box-shadow: 0px 4px 4px 0px #c8c8c840;
  border-radius: 8px;
  border: 1px 0px 0px 0px;
  justify-content: space-between;
`;

export const TitleValueHolder = styled.div`
  padding: 5px 5px;
`;

export const CardTitle = styled.p`
  margin: 0;
  font-size: ${(props) => (props.size ? props.size : "18px")};
  line-height: 27px;
  text-align: left;
  color: ${(props) => (props.color ? props.color : "#000000")};
`;

export const PolicyStatusTileName = styled.p`
  font-size: 13px;
  font-weight: 600;
  color: #00000080;
  margin: 0;
`;

export const PolicyIcon = styled.div`
  background: #ccfbd4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    color: ${(props) => (props.color ? props.color : "#000000")};
    height: ${(props) => (props.height ? props.height : "25px")};
    width: ${(props) => (props.width ? props.width : "25px")};
  }
  background: ${(props) => (props.bgColor ? props.bgColor : "")};
`;

export const Plans = styled.div`
  width: 100%;
  padding: 16px 20px 20px 20px;
  border-radius: 8px;
  margin-left: 20px;
  height: 100%;
  background: #ffffff;
  h2 {
    margin: 0 0 10px 0;
  }
`;

export const ActionWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  margin-bottom: 8px;
`;

export const ActionContainer = styled.div`
  margin-left: 20px;
  padding: 5px 8px;
  background: #ffffff;
  border-radius: 10px;
  .head-h2 {
    margin-bottom: 5px;
  }
  width: 100%;
`;
export const ActionIcon = styled.div`
  transition: all 0.3s ease;
  border-radius: 50%;
  svg {
    color: #4376f6;
    width: 30px;
    height: 30px;
    margin: 3px;
  }
  &:hover {
    background: #4373eb;
    svg {
      color: #ffffff;
    }
  }

  cursor: pointer;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SingleAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  svg {
    width: 25px;
    height: 25px;
  }
`;

export const TableHead = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;
`;

export const PendingTaskWrap = styled.div`
  display: flex;
  flex-direction: column;
  background: #ffffff;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  margin-left: 20px;
  padding: 10px;

  p {
    margin: 0 0 12px 0;
    color: #444444;
  }
  .pnTsk {
    width: 100%;
    text-align: center;
    color: #000000;
    opacity: 0.5;
    font-weight: bold;
  }
`;

export const TaskContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 10px;
  svg {
    color: #3fa75c;
    font-size: 20px;
  }
  color: #00000080;
  font-size: 13px;
`;

export const RightArrowFilled = styled.div`
  margin-top: 2px;
  width: 22px;
  height: 12px;
  background-image: url('data:image/svg+xml;utf8,<svg width="22" height="12" viewBox="0 0 22 14" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M16.4 0.933333C16.1206 0.643542 15.7582 0.408334 15.3416 0.246336C14.9251 0.0843385 14.4657 0 14 0H3C2.20435 0 1.44129 0.245833 0.87868 0.683418C0.316071 1.121 0 1.71449 0 2.33333V11.6667C0 12.2855 0.316071 12.879 0.87868 13.3166C1.44129 13.7542 2.20435 14 3 14H14C14.4657 14 14.9251 13.9157 15.3416 13.7537C15.7582 13.5917 16.1206 13.3565 16.4 13.0667L20.9 8.4C21.2895 7.99611 21.5 7.50486 21.5 7C21.5 6.49514 21.2895 6.00389 20.9 5.6L16.4 0.933333Z" fill="%233FA75C"/></svg>');
  background-size: cover;
`;

export const QuickActionsButtonContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  position: relative;
  overflow: hidden;

  svg {
    font-size: 24px;
    z-index: 1 !important;
    color: #4376f6;
    transition: all 0.3s ease;
  }

  .quick-action-bg {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    background-color: #4376f6;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 120%;
    transition: all 0.2s linear;
  }

  .quick-action-tooltip {
    position: absolute;
    top: 100%;
    z-index: 11 !important;
  }

  .quick-action-tooltip {
    background-color: #4376f6;
    color: white;
  }

  &:hover {
    .quick-action-bg {
      top: 0;
    }

    svg {
      color: white;
    }
  }
`;

export const QuickAccessTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    TransitionComponent={Fade}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#4376f6",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#4376f6",
  },
}));

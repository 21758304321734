import { Grid, Typography } from '@mui/material'
import React from 'react'

const RequestCard = ({ id, }) => {
    return (
        <Grid container xs={12} alignItems={"start"}
            justifyContent={"center"} border={"1px solid #dbdbdb"}
            padding={2} margin={1} borderRadius={2} boxShadow={"1px 1px 2px #4373EB"} style={{ cursor: "pointer" }}>
            <Grid item xs={6}>
                <Typography fontSize={12} fontWeight={500}>
                    Request Id: {id}
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <Typography fontSize={12} fontWeight={500}>
                    Status
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <Typography fontSize={12} fontWeight={500}>
                    Unable To Raise Claim
                </Typography>
            </Grid>
            <Grid item xs={6} >
                <Typography fontSize={12} fontWeight={500}>
                    Date : 19/6/24
                </Typography>
            </Grid>
        </Grid>
    )
}

export default RequestCard
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetNotifications = () => {
  const tokenId = sessionStorage.getItem('token')
  const { data, isLoading } = useQuery(
    ["notifications"],
    () => {
      return httpClient("scheduled_communications/fetch_notifications");
    },
    {
      refetchInterval: 600000, //10 minutes
      enabled: !!tokenId,
    }
  );
  return {
    notification: data?.data?.data || [],
    isLoading: isLoading,
  };
};

export const useReadNotification = () => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    (data) => {
      return httpClient(`scheduled_communications/update_status`, {
        method: "POST",
        data: data,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["notifications"]);
      },
    }
  );
  return {
    readNotification: mutate,
    data: data,
  };
};

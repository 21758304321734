import moment from "moment";

export const formatPrice = (price, round = true) => {
  price = Number(price);

  if (!price) {
    return "₹ 0.00";
  } else if (!isFinite(price)) {
    return "N/A";
  } else {
    if (round) {
      return `₹ ${Math.round(price).toLocaleString("en-IN")}`;
    } else {
      if (isInt(price)) {
        return `₹ ${price.toLocaleString("en-IN")}.00`;
      } else {
        // here price is in fraction
        // no. of digits after decimal 1 then added 0
        if (price.toLocaleString().split(".").at(-1).length === 1) {
          return `₹ ${price.toLocaleString("en-IN")}0`;
        }
        return `₹ ${price.toLocaleString("en-IN")}`;
      }
    }
  }
};

function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

export function formatPayloadForEndorsement(input) {
  const result = {};

  function traverse(obj, res) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (
          obj[key] &&
          typeof obj[key] === "object" &&
          !Array.isArray(obj[key])
        ) {
          // Check if the object is a date
          if (obj[key] instanceof Date) {
            res[key] = moment(obj[key].toISOString()).format("YYYY-MM-DD");
          } else if (
            obj[key].hasOwnProperty("label") &&
            obj[key].hasOwnProperty("value")
          ) {
            res[key] = obj[key].value; // Keep 'value' or 'label' as needed
          } else {
            res[key] = {};
            traverse(obj[key], res[key]);
          }
        } else if (Array.isArray(obj[key])) {
          if (key === "members") {
            // Filter out null values specifically for the members array
            res[key] = obj[key]
              .filter((item) => item !== null)
              .map((item) => {
                if (typeof item === "object" && item !== null) {
                  const subItem = {};
                  traverse(item, subItem);
                  return subItem;
                }
                return item;
              });
          } else {
            // Handle other arrays
            res[key] = obj[key].map((item) => {
              if (typeof item === "object" && item !== null) {
                const subItem = {};
                traverse(item, subItem);
                return subItem;
              }
              return item;
            });
          }
        } else {
          res[key] = obj[key];
        }
      }
    }
  }

  traverse(input, result);
  return result;
}

import styled from "styled-components";

const StyledH1 = styled.h1`
  ${({ partialU, color, theme, themeType, align }) => `
  
display: inline-block;
padding-bottom: 5px;
position: relative;
font-size: 24px;
margin: 0;
line-height: 36px;
width: 100%;
font-weight: 600;
${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};
}`}
`;
const StyledH2 = styled.h2`
  ${({ partialU, color, theme, themeType, align }) => `  font-weight: 300;
display: inline-block;
padding-bottom: 5px;
position: relative;

${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};
&:before {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    bottom: 0px;
    left: 3px;
    border-bottom: 5px solid ${theme && themeType ? theme?.[themeType]?.secondary : "#F9AE2B"
    };
    border-radius: 3px;
}
}`}
`;
const StyledH3 = styled.h3`
  ${({ partialU, color, theme, themeType, align }) => `  font-weight: 300;
display: inline-block;
padding-bottom: 5px;
position: relative;

${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};
&:before {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    bottom: 0px;
    left: 3px;
    border-bottom: 5px solid ${theme && themeType ? theme?.[themeType]?.secondary : "#F9AE2B"
    };
    border-radius: 3px;
}
}`}
`;
const StyledH4 = styled.h4`
  ${({ partialU, color, theme, themeType, align }) => `  font-weight: 300;
display: inline-block;
padding-bottom: 5px;
position: relative;

${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};

${partialU
      ? `&:before {
  content: "";
  position: absolute;
  width: 7%;
  height: 1px;
  bottom: 0px;
  left: 3px;
  border-bottom: 5px solid ${theme && themeType ? theme?.[themeType]?.secondary : "#F9AE2B"
      };
  border-radius: 3px;
}`
      : ``
    }

}`}
`;
const StyledH5 = styled.h5`
  ${({ partialU, color, theme, themeType, align }) => `  font-weight: 300;
display: inline-block;
padding-bottom: 5px;
position: relative;

${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};
&:before {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    bottom: 0px;
    left: 3px;
    border-bottom: 5px solid ${theme && themeType ? theme?.[themeType]?.secondary : "#F9AE2B"
    };
    border-radius: 3px;
}
}`}
`;
const StyledH6 = styled.h6`
  ${({ partialU, color, theme, themeType, align }) => `  font-weight: 300;
display: inline-block;
padding-bottom: 5px;
position: relative;

${color ? `color: ${color}` : ``};
${align ? `text-align: ${align}` : ``};
&:before {
    content: "";
    position: absolute;
    width: 7%;
    height: 1px;
    bottom: 0px;
    left: 3px;
    border-bottom: 5px solid ${theme && themeType ? theme?.[themeType]?.secondary : "#F9AE2B"
    };
    border-radius: 3px;
}
}`}
`;

const StyledImageText = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 50px;
    height: 30px;
    object-fit: contain;
  }
  h6 {
    font-size: 1.25rem;
    margin: 0;
  }
`;

const Head = styled.p`
  font-size: ${({ theme, fontSize }) =>
    theme.fontSize
      ? `calc(${fontSize || "13px"} + ${theme.fontSize - 92}%)`
      : fontSize || "13px"};
  font-weight: 500;
  line-height: 18px;
  color: ${({ theme }) => (theme.dark ? "#FAFAFA" : "#444444")};
  margin-bottom: 8px;
  text-align: left;
  letter-spacing: 1px;
  
`;

const Text = styled.p`
  color: ${({ theme }) => (theme.dark ? "#FAFAFA" : "#606060")};
  font-size: ${({ theme, fontSize }) =>
    theme.fontSize
      ? `calc(${fontSize || "12px"} + ${theme.fontSize - 92}%)`
      : fontSize || "12px"};
  line-height: 18px;
  margin-bottom: 25px;
  letter-spacing: 1px;
  overflow-wrap: break-word;
  
`;

const TypographyDiv = styled.div`
  cursor: pointer;
  font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(1.2em + ${fontSize - 92}%)` : "1.2em"};
  color: ${({ theme }) => (theme.dark ? "#FAFAFA" : "#606060")};
  display: inline-block;
  
`;

const Marker = styled.div`
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: ${({ theme }) =>
    theme.PrimaryColors?.tableColor || "#d757f6"};
  opacity: 0.9;
  
`;

const Error = styled.p`
  margin-bottom: 0;
  margin-top: ${({ top }) => top || "0px"};
  margin-left: ${({ left }) => left || "0px"};
  font-size: ${({ theme: { fontSize } }) =>
    fontSize ? `calc(13px + ${fontSize - 92}%)` : "12px"};
  text-align: left;
  color: ${({ color }) => color || "red"};
  
  /*  */
  font-weight: 500;
  bottom: ${({ bottom }) => bottom || "0px"};
  margin: 0;
`;

//prettier-ignore
export const Typography = {
  StyledH1, StyledH2, StyledH3, StyledH4, StyledH5, StyledH6, StyledImageText, Head,
  Text, TypographyDiv, Marker, Error
}

export default Typography;

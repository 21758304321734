import { lazy, Suspense } from "react";
import {
	createBrowserRouter,
	RouterProvider,
	useParams,
	useSearchParams,
} from "react-router-dom";
import styled from "styled-components";
//modules
// import BrokerConfig from "./modules/broker-configuration/broker-configuration";
// import { BrokerAdmin } from "./modules/broker-admin-config/broker-admin";
// import { _renderMenu } from "./Layout/Sidebar/helperFunc";
// import { useGetAccessRightsMenu } from "./Layout/Sidebar/service";
import LoadingComponent from "./components/loader/loader";

import Login from "./modules/login/login";
import Authentication from "./modules/login/authentication";

import Activitylogs from "./modules/Activity Logs/Activitylogs";
// import Endorsement from "./modules/endorsement/Endorsement";
import Help from "./modules/Help/Help";
import PolicyUpload from "./modules/policyUpload/policyUpload";
import MyProfile from "./modules/MyProfile";
import EditProfile from "./modules/MyProfile/EditProfile";
import RaiseClaimHealth from "./modules/raise-claims-health/RaiseClaimHealth";
import RenderClaim from "./modules/raise-claims-health/RenderClaim";
import NewClaim from "./modules/NewClaim/NewClaim";
import ErrorBoundary from "./hoc/ErrorBoundary";
import Error404 from "./ErrorPages/error404";
import Error500 from "./ErrorPages/error500";
import Claim from "./modules/Dynamic-Claim/Raise-Claim/Claim";
import Layout from "./layout/Layout";
import ClaimBasicDetail from "./modules/Dynamic-Claim/ClaimBasicDetail";
// const Sidebar = lazy(() => import("./Layout/Sidebar/Sidebar"));
// const LoginPage = lazy(() => import("./modules/login/login-page"));
const CustomerDashboard = lazy(() =>
	import("./modules/customerDashboard/customerDashboard")
);
const PolicyCard = lazy(() => import("./modules/All-Policies/PolicyCard"));
const RenewPolicies = lazy(() =>
	import("./modules/Renew-Policies/RenewPolicies")
);
const MyDocuments = lazy(() => import("./modules/MyDocuments"));
const RaiseClaims = lazy(() => import("./modules/raise-claims/RaiseClaims"));
const ForgotPassword = lazy(() => import("./modules/login/ForgotPassword"));
const ResetPassword = lazy(() => import("./modules/login/ResetPassword"));
const definedRoutes = [
	{ path: "/login", errorElement: <ErrorBoundary />, element: <Login /> },
	{
		path: "/forgot-password",
		element: <ForgotPassword />,
	},
	{
		path: "/reset_password/:token",
		element: <ResetPassword />,
	},
	{
		path: "*",
		element: <Error404 />,
		errorElement: <ErrorBoundary />,
	},
	{
		path: "/500",
		element: <Error500 />,
		errorElement: <ErrorBoundary />,
	},
	{
		element: <Authentication />,
		children: [
			{
				element: <Layout />,
				children: [
					{
						path: "/",
						element: <CustomerDashboard />,
					},
					{
						path: "/all-policies",
						element: <PolicyCard />,
					},
					{
						path: "/renew-policies",
						element: <RenewPolicies />,
					},
					{
						path: "/claims",
						element: <NewClaim />,
					},
					{
						path: "/activity",
						element: <Activitylogs />,
					},
					// {
					//   path: "/endorsement",
					//   element: <Endorsement />,
					// },
					{
						path: "/help",
						element: <Help />,
					},
					{
						path: "/my-documents",
						element: <MyDocuments />,
					},
					{
						path: "/my-profile",
						element: <MyProfile />,
					},
					{
						path: "/policy-upload",
						element: <PolicyUpload />,
					},
					{
						path: "/edit-profile/:id",
						element: <EditProfile />,
					},
					// {
					//   path: "/raise-claims",
					//   element: <Claim />,
					// },
					{
						path: "/edit-claim",
						element: <Claim />,
					},
					{
						path: "/raise-claims",
						element: <ClaimBasicDetail />,
					},
				],
			},
		],
	},
];
const Routes = () => {
	const router = createBrowserRouter(definedRoutes);
	return (
		<>
			<Suspense fallback={<LoadingComponent />}>
				{false ? (
					<LoadingComponent />
				) : (
					<ComponentFrame>
						<RouterProvider router={router} />
					</ComponentFrame>
				)}
			</Suspense>
		</>
	);
};
const ComponentFrame = styled.div`
	min-height: 314px;
`;
export default Routes;

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";

export const useGetPolicyMasterActivityLogs = () => {
  const { data, mutate, isSuccess, isLoading } = useMutation((filter) => {
    return httpClient("policy_master/logActivity/listPaging", {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          tableName: filter || "claim",
        },
      },
    });
  });

  return { data: data?.data?.data || [], mutate, isSuccess, isLoading };
};

// /scheduled_communications/fetch_notifications

export const useGetNotification = () => {
  const { data } = useQuery(["get_notification"], () => {
    return httpClient("scheduled_communications/fetch_notifications");
  });
  return { data: data?.data?.data || [] };
};

export const useGetEntityId = () => { };

export const useGetCommunication = () => {
  const { mutate, data, isSuccess, isLoading } = useMutation((payload) => {
    return httpClient("customer_activity_history/listing", {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortOrder: "",
        searchValues: {
          transactionType: "",
          activityType: "",
          entityId: 0,
        },
      },
    });
  });
  return { data: data?.data?.data, mutate };
};

export const useGetCommunicationDataByIds = (ids = []) => {
  console.log("ids", ids);
  const data = useQueries({
    queries: ids?.map((id) => ({
      queryKey: ["getCommunicationDataById", id],
      queryFn: () =>
        httpClient(
          "/customer_activity_history/listing/transactionAndEntityId",
          {
            method: "POST",
            data: {
              transactionType: "",
              activityType: "",
              entityId: id,
            },
          }
        ),
    })),
  });
  return { data };
};

export const useGetCommunicationDataById = (id) => {
  console.log("id", id);
  const { data } = useQuery({
    queryKey: ["getCommunicationDataById", id],
    queryFn: () =>
      httpClient("/customer_activity_history/listing/transactionAndEntityId", {
        method: "POST",
        data: {
          transactionType: "",
          // activityType: "INITIAL",
          entityId: id,
        },
      }),
  });
  return { data };
};

export const useUpdateClaimStageHistory = () => {
  const queryClient = useQueryClient();
  const { data, mutate } = useMutation(
    (payload) => {
      return httpClient(`/claim-Stage-History/add`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries("ClaimsList");
        return Swal.fire({
          text: `${data?.data?.message}`,
          icon: `${data?.data?.errortype}`,
          confirmButtonText: "Ok",
          confirmButtonColor: "#DC004E",
          reverseButtons: true,
          focusConfirm: false,
          focusCancel: false,
          scrollbarPadding: false,
        });
      },
    }
  );
  return { mutate, data };
};

import React, { useState } from "react";
import Table from "./table";
import GlobalModal from "../../components/Modal/modal";
import PdfUpload from "./pdfUpload";
import ManualPolicy from "./manualPolicy";
import PolicyForm from "./policyForm";
const PolicyUpload = () => {
  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [policyFormShow, setpolicyFormShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <Table setShow={setShow} xyz={1} setShowUpload={setShowUpload} />
      <GlobalModal
        title={"Upload Policy Manually"}
        width="436px"
        open={show}
        onClose={handleClose}
      >
        <ManualPolicy setShow={setShow} setpolicyFormShow={setpolicyFormShow} />
      </GlobalModal>
      <GlobalModal
        width="674"
        title={"Upload Policy"}
        open={showUpload}
        onClose={() => setShowUpload(false)}
      >
        <PdfUpload />
      </GlobalModal>
      <GlobalModal
        title={"Add Policy"}
        open={policyFormShow}
        overflowVisible={true}
        onClose={() => setpolicyFormShow(false)}
      >
        <PolicyForm />
      </GlobalModal>
    </>
  );
};

export default PolicyUpload;

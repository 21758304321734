import { createSlice } from "@reduxjs/toolkit";

const Theme = createSlice({
  name: "Theme",
  initialState: {
    theme: {
      primaryColor: "#11555f",
      secondaryColor: "#D2F8E9",
      tertiaryColor: "#F5FFFF",
    },
  },
  reducers: {
    setTheme: (state, { payload }) => {
      state.theme = payload;
    },
    resetTheme: (state) => {
      state.theme = {
        primaryColor: "#11555f",
        secondaryColor: "#D2F8E9",
        tertiaryColor: "#F5FFFF",
      };
    },
  },
});

export const { setTheme, resetTheme } = Theme.actions;
export default Theme.reducer;

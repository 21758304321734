import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Box,
  Card,
  Grid,
  Tooltip,
  // Input,
  Button,
} from "@mui/material";
import {
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import styled from "styled-components";
import moment from "moment";
import { useForm } from "react-hook-form";
import ProposalFilePicker from "../../modules/Dynamic-Claim/ProposalFilePicker";
import { useUpdateClaimStageHistory } from "../../modules/Activity Logs/service";
import Input from "../form-components/input/input";
import { useSelector } from "react-redux";
import _ from "lodash";

const CommunicationLog = ({ data, key, index, popup }) => {
  const [files, setFiles] = useState({});
  const [showForm, setShowForm] = useState(null);
  const { handleSubmit, watch, register, control, setValue, trigger } = useForm(
    {
      shouldUnregister: true,
    }
  );

  const { uploadedFileURL } = useSelector((state) => state.proposalConfig);

  useEffect(() => {
    return () => {
      setShowForm(null);
    };
  }, []);

  useEffect(() => {
    setValue();
  }, []);

  const { mutate: updateClaimStageHistory } = useUpdateClaimStageHistory();

  const onSubmit = (data, queryData) => {
    const docPayload = data?.docIssue?.map((item, index) => {
      const docName = item && item?.document && Object.keys(item?.document)?.[0];
      const docPath = uploadedFileURL[`docIssue[${index}].document.${docName}`];
      const comment = Object.values(item?.comment)?.[0];

      return {
        doc_name: docName,
        doc_path: docPath,
        comment: comment,
        doc_button: "Approve"
      };
    });
    console.log(queryData)
    const payload = {
      claimDataRefId: queryData?.claim_data_ref_id || "",
      claimStage: queryData?.claimStatus || "",
      remark: queryData?.remark || "",
      subStatus: queryData?.subStatus || "",
      ...(!_.isEmpty(data?.docIssue) && { missingdocuments: docPayload }),

    };
    updateClaimStageHistory(payload);
  };
  const getStatus = (claimStatus, policyNo) => {
    switch (claimStatus) {
      case "Intimated":
        return `Your Claim for Policy Number ${policyNo} has been intimated`;
      case "In Progress":
        return `Your Claim for Policy Number ${policyNo} is In Progress`;
      case "Pending":
        return `Discrepancy has been found in your Claim for Policy Number ${policyNo}`;
      case "Approved":
        return `Your Claim for Policy Number ${policyNo} has been approved`;
      case "Rejected":
        return `Your Claim for Policy Number ${policyNo} has been rejected`;
      case "Out Standing":
        return `Your Claim for Policy Number ${policyNo} is Out Standing`;
      case "AL issued":
        return `AL issued for a Claim for Policy Number ${policyNo}`;
      default:
        return "";
    }
  };

  return (
    <>
      <div style={{ padding: popup ? "15px 25px 15px" : "0px" }}>
        {data?.map((item, index) => (
          console.log("item", item),
          <CommunicationLogItem key={index}>
            <CommunicationLogTimelineSeparator>
              <CommunicationLogCheckCircleIcon />
              {index < data.length - 1 && <CommunicationLogTimelineConnector />}
            </CommunicationLogTimelineSeparator>
            <CommunicationLogTimelineContent>
              <CommunicationLogPaper>
                <CommunicationLogTypography>
                  {getStatus(item?.claimStatus, item?.policyNo)}
                </CommunicationLogTypography>
                <CommunicationLogTimelineOppositeContent>
                  <p style={{ paddingBottom: "5px", margin: 0, textAlign: 'left', fontWeight: 700 }}>{item?.heading}</p>
                  <CommunicationLogTypography
                    color="textSecondary"
                    normal={true}
                  >
                    {item?.updated_at ? item?.updated_at : item?.createdAt}
                  </CommunicationLogTypography>
                </CommunicationLogTimelineOppositeContent>
                {item?.claimStatus ? item?.claimStatus : item?.stage && (
                  <CommunicationLogTimelineStage stage={item?.claimStatus}>
                    <CommunicationLogCircleIcon />
                    {item?.claimStatus ? item?.claimStatus : item?.stage}
                  </CommunicationLogTimelineStage>
                )}
                {item?.remark?.length > 0 && item?.claimStatus != "Intimated" && (
                  <FlexBox mt={2}>
                    <NameBox>
                      {item?.broker_name ? item?.broker_name : item?.agentName
                        ?.split(" ")
                        ?.map((i) => i[0])
                        ?.join("")}
                    </NameBox>
                    <MessageCard>{item?.remark}</MessageCard>
                  </FlexBox>
                )}
              </CommunicationLogPaper>
              {item?.claimStatus === "Query Raised" && (
                <div>
                  <span style={{}}>Document Issues</span>
                  {/* {showForm && ( */}
                  <Button
                    variant="contained"
                    type="button"
                    onClick={() => setShowForm(index.toString())}
                    style={{ float: "right" }}
                  >
                    Add
                  </Button>
                  <ul>
                    {[...(item?.missing_doc ?? []), ...(item?.additional_doc ?? [])]?.map((doc, docIndex) => {
                      return (
                        <>
                          <Grid container spacing={1} px={4}>
                            <Grid item xs={12} sm={12} md={3} lg={4}>
                              <li>
                                <a
                                  href={doc?.doc_path}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{
                                    textDecoration: "none",
                                    color: "inherit",
                                    width: "100%",
                                  }}
                                >
                                  <Tooltip
                                    title={"View Document"}
                                    arrow
                                    placement="top"
                                  >
                                    <sapn
                                      style={{ textDecoration: "underline" }}
                                    >
                                      {doc?.doc_name}
                                    </sapn>
                                  </Tooltip>
                                </a>{" "}
                                - {doc?.comment}
                              </li>
                            </Grid>
                            {showForm == index && (
                              <>
                                <Grid item xs={12} sm={12} md={3} lg={4}>
                                  <Input
                                    themeType={"theme1"}
                                    label="Comment"
                                    name={`docIssue[${docIndex}].comment.comment_${doc?.doc_name?.replace(/\./g, '_').replace(/'/g, '-')}`}
                                    id={`docIssue[${docIndex}].comment.comment_${doc?.doc_name?.replace(/\./g, '_').replace(/'/g, '-')}`}
                                    maxLength="100"
                                    placeholder={"Enter comment"}
                                    autoComplete="none"
                                    defaultValue={""}
                                    required={false}
                                    inputRef={register(
                                      `docIssue[${docIndex}].comment.comment_${doc?.doc_name?.replace(/\./g, '_').replace(/'/g, '-')}`
                                    )}
                                    testId={`docIssue[${docIndex}].comment.comment_${doc?.doc_name?.replace(/\./g, '_').replace(/'/g, '-')}`}
                                    watch={watch}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={3} lg={4}>
                                  <ProposalFilePicker
                                    label={`${doc?.doc_name}`}
                                    id={`docIssue[${docIndex}].document.${doc?.doc_name?.replace(/\./g, '_').replace(/'/g, '-')}`}
                                    files={files}
                                    setFiles={setFiles}
                                    setValue={setValue}
                                    trigger={trigger}
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </>
                      );
                    })}
                  </ul>
                  {showForm == index && (
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => onSubmit(watch(), item)}
                      style={{ float: "right" }}
                    >
                      Submit
                    </Button>
                  )}
                </div>
              )}
            </CommunicationLogTimelineContent>
          </CommunicationLogItem>
        ))}
      </div>
    </>
  );
};

export default CommunicationLog;

const StyledMainDiv = styled.div`
  border-radius: 10px;
  padding: 10px;
`;

const CommunicationLogItem = styled(TimelineItem)`
  min-height: 110px !important;
  &:before {
    content: "";
    display: none;
  }
`;

const CommunicationLogTimelineSeparator = styled(TimelineSeparator)`
  display: flex;
  align-items: center;
`;

const CommunicationLogCheckCircleIcon = styled(CheckCircleIcon)`
  width: 30px !important;
  height: 30px !important;
  fill: #1c71ff !important;
`;

const CommunicationLogTimelineConnector = styled(TimelineConnector)`
  background-color: #1c71ff !important;
`;

const CommunicationLogTimelineContent = styled(TimelineContent)`
  padding-top: 0;
`;

const CommunicationLogPaper = styled(Paper)`
  box-shadow: none !important;
`;

const CommunicationLogTypography = styled(Typography)`
  font-size: 13px !important;
  font-weight: ${({ normal }) => (normal ? "400" : "700")} !important;
  line-height: 15.73px !important;
  color: #444444 !important;
  text-align: left;
`;

const CommunicationLogTimelineOppositeContent = styled(TimelineOppositeContent)`
  padding: 0px 0 6px !important;
`;

const CommunicationLogTimelineStage = styled.div`
  display: flex;
  align-items: center;
  max-width: fit-content;
  border-radius: 4px;
  padding: 3px 5px;
  color: ${({ stage }) =>
    stage === "Intimated"
      ? "#14804A"
      : stage === "In Progress"
        ? "#5E5ADB"
        : stage === "Pending"
          ? "#AA5B00"
          : "#5e5adb"};
  background-color: ${({ stage }) =>
    stage === "Intimated"
      ? "#E1FCEF"
      : stage === "In Progress"
        ? "#EDEDFC"
        : stage === "Pending"
          ? "#FCF2E6"
          : "#ededfc"};
  font-size: 12px;
`;

const CommunicationLogCircleIcon = styled(CircleIcon)`
  width: 9px !important;
  height: 9px !important;
  margin-right: 5px;
`;

export const FlexBox = styled(Box)`
  display: flex;
  align-items: center;
  gap: 8px;
  border: ${({ border }) => (border ? `1px solid ${border}` : "")} !important;
  background-color: ${({ bg }) => (bg ? bg : "")} !important;
`;

export const NameBox = styled(Box)`
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: #14804a;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e1fcef;
  font-size: 12px;
`;

export const MessageCard = styled(Box)`
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  // border: 1px solid red;
  // background-color: #f2f2f2 !important;
`;
export const DocIssues = styled(Box)`
  padding: 10px;
  border-radius: 7px;
  border: 1px solid #e0e0e0;
  font-size: 12px;
  // border: 1px solid red;
  margintop: 30px;
  // gap: 20px;
  // background-color: #f2f2f2 !important;
`;

const HelpFormField = [
  {
    label: "Enter Full Name",
    placeholder: "Enter Full Name",
    name: "fullName",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 6,
      sm: 12,
      md: 12,
    },
  },
  {
    label: "Email Id",
    placeholder: "Enter Email Id",
    name: "emailId",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 6,
      sm: 12,
      md: 12,
    },
  },
  {
    label: "How can we help you?",
    placeholder: "Enter How can we help you?",
    name: "howCanWeHelpYou",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 6,
      sm: 12,
      md: 12,
    },
  },
  // {
  //   label: "Endorsement Reason",
  //   placeholder: "Enter Endorsement Reason",
  //   name: "endorsementReason",
  //   fieldType: "input",
  //   style: {
  //     xs: 12,
  //     lg: 12,
  //     sm: 12,
  //     md: 12,
  //   },
  // },
  // {
  //   label: "Select Policy",
  //   placeholder: "Enter Policy",
  //   name: "select Policy",
  //   fieldType: "textarea",
  //   style: {
  //     xs: 12,
  //     lg: 12,
  //     sm: 12,
  //     md: 12,
  //   },
  // },
  {
    label: "Select Policy",
    placeholder: "Enter Policy Number",
    name: "policyNo",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 6,
      sm: 12,
      md: 12,
    },
  },
  {
    label: "Subject",
    placeholder: "Enter Subject",
    name: "subject",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 12,
      sm: 12,
      md: 12
    }
  },
  {
    label: "Enter Message",
    placeholder: "Enter Message",
    name: "message",
    fieldType: "input",
    style: {
      xs: 12,
      lg: 12,
      sm: 12,
      md: 12,
    },
  },
];

const FAQaccordianContent = [
  {
    name: "panel1",
    accordianHeader: "How is insurance premium determined?",
    accordianContent:
      "Insurance premiums are based on several factors, including the type of coverage, the insured's risk profile (e.g., age, health, driving record), the value of the insured item, and the insurer's underwriting criteria. Generally, higher-risk individuals or properties will have higher premiums.",
  },
  {
    name: "panel2",
    accordianHeader: "What types of insurance coverage are available?",
    accordianContent:
      "There are various types of insurance coverage available, including health insurance, auto insurance, home insurance, life insurance, and travel insurance. Each type of insurance provides specific protections tailored to different aspects of life and assets.",
  },
  {
    name: "panel3",
    accordianHeader: "What is a deductible in an insurance policy?",
    accordianContent:
      "A deductible is the amount of money you agree to pay out-of-pocket before your insurance coverage kicks in. Higher deductibles generally result in lower premiums, while lower deductibles lead to higher premiums.",
  },
  {
    name: "panel4",
    accordianHeader:
      "What is the difference between term and whole life insurance?",
    accordianContent:
      "Term life insurance provides coverage for a specific period, such as 10, 20, or 30 years, and pays out a death benefit only if the insured passes away during that term. Whole life insurance, on the other hand, provides lifelong coverage and includes a savings component that can build cash value over time.",
  },
];

export { HelpFormField, FAQaccordianContent };

import styled from "styled-components";
import Input from "../form-components/input/input";
import { useState } from "react";

function CustomDropdown({
  label,
  id,
  isRequired,
  proposalRead,
  readOnly,
  register,
  setValue,
  options,
  stateUpdaterFn,
  placeholder,
  onChange,
  description,
  errors,
  watch,
  claim,
}) {
  const [inputFocus, setInputFocus] = useState(false);

  const handleInputFocus = () => {
    setInputFocus(true);
  };

  const handleInputBlur = (e) => {
    setTimeout(() => {
      setInputFocus(false);
    }, 400);
  };

  const handleDropdownSelect = (value, customerId) => {
    const formatVal = value.replace(/\d/g, "");

    setValue(id, formatVal);
    if (stateUpdaterFn) {
      stateUpdaterFn(customerId);
    }
  };

  return (
    <CustomDropdownStyled>
      <Input
        themeType={"theme1"}
        label={label}
        name={id}
        id={id}
        maxLength="100"
        placeholder={placeholder}
        autoComplete="off"
        defaultValue={""}
        required={false}
        proposalRead={proposalRead}
        onChange={onChange}
        readOnly={readOnly}
        testId="modal_input"
        isRequired={isRequired}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        inputRef={register(id)}
        description={description}
        errors={errors}
        watch={watch}
        claim={claim}
      />
      {inputFocus && options?.length > 0 ? (
        <ul className="dropdown">
          {options.map((item) => (
            <li
              key={item.id + id}
              onClick={() => handleDropdownSelect(item.value, item.id)}
              id={id + "_" + item.id}
            >
              {item.value}
            </li>
          ))}
        </ul>
      ) : null}
    </CustomDropdownStyled>
  );
}

export default CustomDropdown;

export const CustomDropdownStyled = styled.div`
  position: relative;
  input {
    cursor: default;
  }

  .dropdown {
    padding: 0;
    margin: 0;
    max-height: 125px;
    overflow: auto;
    position: absolute;
    left: 0;
    top: 60px;
    z-index: 100;
    border: 0.25px solid #e4e4e4;
    right: 0;
    background: #fff;
    border-radius: 5px;

    ::-webkit-scrollbar {
      width: 3px;
    }

    ::-webkit-scrollbar-track {
      background-color: lightgrey;
    }

    ::-webkit-scrollbar-thumb {
      background-color: #2bb9a8;
    }

    li {
      font-weight: 600;
      letter-spacing: 0.5px;
      font-size: 12px;

      color: #2bb9a8;
      border-bottom: 1px solid #cadedd;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
      padding: 10px;
      cursor: pointer;
      transition: all 0.2s ease;
      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
`;

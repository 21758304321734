import React from "react";
import Button from "@mui/material/Button";
import styled from "styled-components";

//prettier-ignore
const Btn = ({ children, variant = "contained", bgColor, textColor, id,
  startIcon, startIconColor, endIcon, endIconColor, isDisabled,
  disabled = false, type, ...rest
}) => {

  return (
    <StyledButton
      variant={variant}
      startIcon={startIcon}
      startIconColor={startIconColor}
      endIcon={endIcon}
      id={id}
      endIconColor={endIconColor}
      disabled={disabled}
      bgColor={bgColor}
      textColor={textColor}
      type={type}
      isDisabled={isDisabled}
      {...rest}
    >
      {children}
    </StyledButton>
  );
};
const StyledButton = styled(Button)`
  ${({ textColor }) => (textColor ? `color: ${textColor} !important;` : ``)};
  font-family: ${({ theme }) => theme?.regularFont || "Manrope"};
  background: ${({ theme, themeType, btnBack, bgColor }) =>
    bgColor ? bgColor : theme?.ascentColor}!important;
  ${({ round }) => (round ? `border-radius: 18px !important;` : ``)}
  ${({ disabled }) => disabled && `color: white !important;`}
  ${({ borderColor }) =>
    borderColor ? `border: 1px solid ${borderColor} !important;` : ``}
    opacity: ${({ disabled }) => (disabled ? "0.5" : "")};
  font-weight: 500;
  font-size: 14px !important;
  box-shadow: none !important;
  text-transform: capitalize !important;
  cursor: ${(props) =>
    props.isDisabled ? "not-allowed !important" : "pointer"};
  padding: 4px 12px !important;
  margin: 0 !important;
  /* add other styles here */
  // &:hover {
  //   background: #009673 !important;
  //   color: #fff !important;
  // }
  .MuiButton-startIcon {
    ${({ startIconColor }) =>
      startIconColor ? `color: ${startIconColor} !important;` : ``}
  }

  .MuiButton-endIcon {
    ${({ endIconColor }) =>
      endIconColor ? `color: ${endIconColor} !important;` : ``}
  }
`;

export default Btn;

import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { BsFiletypeXls, BsImages } from "react-icons/bs";
import styled from "styled-components";
import Swal from "sweetalert2";

import { Button } from "../../components";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Excel from "../../utils/excel.png";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

const thumb = {
  display: "inline-flex",
  borderRadius: 2,
  border: "2px solid #eaeaea",
  marginBottom: 8,
  marginRight: 8,
  alignItems: "center",
  padding: "5px",
  boxSizing: "border-box",
};

const thumbInner = {
  display: "flex",
  minWidth: 0,
  // overflow: "hidden",
};
const filename = {
  fontSize: "13px",
  whiteSpace: "nowrap",
  position: "relative",
  left: "5px",
  verticalAlign: "middle",
  fontWeight: "bold",
};
const img = {
  display: "block",
  width: "40px",
  height: "40px",
};

const Filepicker = ({ themeType, setValue }) => {
  const [files, setFiles] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    setFiles([...acceptedFiles]);

    // Handle the file(s) here
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result; // Base64 string

        // Now you can send this base64 string to your server or manipulate it as needed
        setValue("policyDocument", base64String);
      };
      reader.readAsDataURL(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    onDrop,
  });

  const removeSelection = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledContainer className="container">
      <PickerWrap themeType={themeType} editDetails AddDeleteFiles>
        <div {...getRootProps({ className: "dropzone" })}>
          <BackupOutlinedIcon className="ImageIcon" />
          <input {...getInputProps()} />
          <p
            style={{
              margin: "0px",
              textAlign: "center",
              padding: "5px",
              fontSize: "13px",
            }}
          >
            Choose a file or drag & drop it here
          </p>
          <p style={{ fontSize: "12px", textAlign: "center" }}>
            JPEG, PNG and PDF formats, up to 5MB
          </p>
          <Button
            variant="outlined"
            borderColor={"#CBD0DC"}
            textColor={"#54575C"}
          >
            Browse Computer
          </Button>
        </div>

        <aside style={thumbsContainer}>
          {files.map((file) => (
            <div style={thumb} key={file.name}>
              <div style={thumbInner}>
                <p style={filename}>
                  {file.name.slice(0, file.path.indexOf("."))}
                </p>
                <CloseOutlinedIcon
                  onClick={() => removeSelection()}
                  style={{
                    position: "relative",
                    top: "10px",
                    marginLeft: "10px",
                    cursor: "pointer",
                  }}
                />
              </div>
            </div>
          ))}
        </aside>
      </PickerWrap>
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  h1 {
    margin: 0 0 10px 0;
  }
  .CloseIcon {
    cursor: pointer;
    color: #000000;
    position: relative;
    left: 50%;
    bottom: 3px;
    font-size: 24px;
  }
`;
const PickerWrap = styled.div`
  padding: 18px;
  background: #fcfcff;
  width: ${(props) => (props.AddDeleteFiles ? "100%" : "95%")};
  .dropzone {
    cursor: pointer;
    background: #fcfcff;
    border: 2px dashed #a4a4cb;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 10px;
  }
  .ImageIcon {
    font-size: 46px;
    margin: 10px;
  }

  .filename {
    position: relative;
  }
`;

const XlsAndButtonWrap = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
  a {
    margin: auto;
    color: ${({ themeType, theme }) =>
      themeType && theme ? theme?.[themeType]?.litePrimary : "#24aa9a"};
    margin-left: 10px;
    font-weight: 600;
  }
`;
export default Filepicker;

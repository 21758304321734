import styled from "styled-components";
import Error404Image from "../utils/error404.png";
import { useNavigate, Navigate, useLocation } from "react-router";

function Error404() {
  const navigate = useNavigate();
  const location = useLocation();

  const isAuthenticated =
    sessionStorage.getItem("tokenID") && sessionStorage.getItem("tokenID");

  // if (!isAuthenticated) {
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  return (
    <ErrorStyled>
      <div>
        <h1>404 Not Found</h1>
        <p>Oops! The page you're looking for does not exist.</p>
        <p>
          You might want to check the URL or go back to the{" "}
          <span onClick={() => navigate("/")}>Home Page</span>.
        </p>
      </div>
    </ErrorStyled>
  );
}

export default Error404;

const ErrorStyled = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  background-image: url(${Error404Image});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50%;
  text-align: center;
  padding: 2rem;

  & > div {
    position: relative;
    height: 105%;
    display: flex;
    flex-direction: column;
    align-items: center;
    p:nth-of-type(1) {
      position: absolute;
      bottom: 10%;
    }
    p:last-child {
      position: absolute;
      bottom: 0;
      span {
        color: blue;
        cursor: pointer;
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }

  h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
    margin-top: 0;
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 1.2rem;
  }

  a {
    font-size: 1.2rem;
    text-decoration: none;
    color: #007bff;
  }

  button {
    padding: 5px 10px;
    border-radius: 5px;
    color: /* your button color */ ;
    position: absolute;
    left: 50%;
    top: 80%;
    transform: translateX(-50%);
  }
`;

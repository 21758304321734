import React from "react";
import { useGetPolicyListing } from "./service";
import { Box } from "@mui/material";
import { TableHead } from "../customerDashboard/styles";
import ButtonWrapper from "../../components/ButtonWrapper";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "../../components";
import AddIcon from "@mui/icons-material/Add";
import UploadIcon from "@mui/icons-material/Upload";

const Table = ({ setShow, setShowUpload }) => {
  const { data } = useGetPolicyListing();

  const columns = [
    {
      field: "lobName",
      headerName: "LOB",
      width: 150,
      flex: 1,
    },
    {
      field: "Policy No",
      headerName: "Policy Number.",
      width: 150,
      flex: 1,
    },
    {
      field: "Policy Expiry date",
      headerName: "Policy Expiry Date",
      width: 150,
      flex: 1,
    },
  ];

  const rows =
    (data?.data?.data &&
      data?.data?.data?.map((item) => {
        return {
          id: item?.["Transaction Id"],
          ...item,
        };
      })) ||
    [];

  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#ffffff",
          padding: "14px",
          borderRadius: "8px",
          border: "1px solid #ebe7e7",
        }}
      >
        <TableHead>
          <div>
            <p
              style={{
                margin: 0,
                color: "#444444",
                fontSize: "20px",
                fontWeight: "bolder",
              }}
            >
              Upcoming Renewal Policies
            </p>
            <p style={{ margin: 0, color: "#444444", fontSize: "14px" }}>
              Upload a Policy or Add a policy Manually
            </p>
          </div>
          <ButtonWrapper>
            <Button
              variant="outlined"
              textColor={"#4373EB"}
              borderColor="#4373EB"
              borderRadius="20px"
              startIcon={<AddIcon />}
              onClick={() => setShow(true)}
            >
              Add Manually
            </Button>
            <Button
              variant="round"
              textColor={"#4373EB"}
              borderColor="#4373EB"
              startIcon={<UploadIcon />}
              onClick={() => setShowUpload(true)}
            >
              Upload Policy
            </Button>
          </ButtonWrapper>
        </TableHead>
        <DataGrid
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 10]}
          disableRowSelectionOnClick
          sx={{
            //   border: "none",
            borderRadius: "4px",
            "& .MuiDataGrid-cell": {
              border: "none",
              color: "#7A7A7A",
            },
            "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
              fontSize: "14px",
              fontWeight: "bolder",
              color: "#4373EB",
            },
          }}
        />
      </Box>
    </>
  );
};

export default Table;

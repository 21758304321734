import { colors, createTheme } from "@mui/material";

// const userTheme = JSON.parse(sessionStorage.getItem("userTheme"));
// console.log("ttt", userTheme);

const theme = createTheme({
  palette: {
    primary: {
      main: "#178e80",
      dark: "#11555F",
      light: "#6f9c45",
      contrastText: "#290404",
    },
    secondary: {
      main: "#3b45c7",
      contrastText: "#eeeeee",
    },
    tertiary: {
      main: colors.green[500],
      contrastText: colors.grey[500],
    },
  },
});
export default theme;

export const themeObj = {
  primaryColor: "#444444", // black
  secondaryColor: "#5A5A5A", // gray
  ascentColor: "#4F46E5", // Soft blue
  bgColor: "#F1F1F1", // light gray
  headingFont: "Manrope",
  regularFont: "Manrope",
};

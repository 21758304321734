import { Call } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import RequestCard from './RequestCard'
import Help from '../../utils/help.jpg'
const RaisedRequest = () => {
    return (
        <Grid container xs={12} padding={2} margin={"auto"} style={{ border: "1px solid #b3b3b3", borderRadius: "15px", boxShadow: "2px 2px 5px #e8efff", maxHeight: "600px", height: "600px" }} direction={"row"}>
            <Typography style={{ color: "#303030", fontWeight: "bold" }}>
                Raised Requests
            </Typography>
            <Grid container xs={12} direction={"row"} alignItems={"center"} justifyContent={"center"} style={{ overflowY: "scroll", height: "50%" }} margin={1}>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((id) => {
                    return <RequestCard id={id} />
                })}
            </Grid>
            <Grid container display={"flex"} alignItems={"center"} justifyContent={"center"} direction={"column"}>
                <img src={Help} width={180} height={150}></img>
                <Typography style={{ fontWeight: "bold", color: "#4373EB", marginTop: "1rem", marginBottom: "1rem" }}>
                    Still Have Questions ?
                </Typography>
                <Button variant='outlined' size='medium' startIcon={<Call />} sx={{ width: "100%", borderRadius: "10px" }}>
                    Get A Call Back
                </Button>
            </Grid>
        </Grid>
    )
}

export default RaisedRequest
import styled from "styled-components";
import background from "../../utils/fyn-background.png";

export const LoginWrapper = styled.div`
  background-image: url(${background});
  backgroundSize: cover;
  height: 100vh;
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
  background
`;

export const LoginSection = styled.section`
  flex-1: flex;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const LoginHeading = styled.h1`
  font-size: 60px;
  
  font-weight: 600;
  line-height: 1.5;
  margin: 50px;
  .animate-charcter {
    background-image: linear-gradient(
      -90deg,
      #231557 0%,
      #0077ff 50%,
      #000 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    font-size: 60px;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
`;

export const LoginContainer = styled.div`
  display: flex;
  align-items: self-start;
  flex-direction: column;
`;

export const StyledImage = styled.img`
  border-radius: 5px;
  padding: 10px;
  width: auto;
  height: 38px;
  align-self: self-start;
  margin-bottom: 15px;
`;

export const StyledFont = styled.b`
  color: #0077ff;
  font-style: italic;
  font-weight: bold;
  background-image: linear-gradient(60deg, rgb(3, 86, 245), rgb(0, 0, 0));
  background-clip: text;
  color: transparent;
`;

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Button } from "../../components";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { useGetQueryTypeMaster } from "./service";
const FAQ = () => {
  const { data: queryData, mutate: addQueryTypeMaster } =
    useGetQueryTypeMaster();

  const dataOfQuery = queryData?.data?.data;

  const filterTags = [
    "Claims",
    "Renewals",
    "Endorsement",
    "Policy Related Issues",
  ];
  const [filterSelected, setFilterSelected] = useState(filterTags[0]);
  const handleFilterClick = (clicked) => {
    setFilterSelected(clicked);
  };
  const [expanded, setExpanded] = useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  const payload = {
    page: 0,
    size: 0,
    sortBy: "",
    sortOrder: "",
    queryTypeSearchValues: {
      lobId: 0,
      transactionType: filterSelected.toUpperCase(),
    },
  };

  useEffect(() => {
    addQueryTypeMaster(payload);
  }, [filterSelected]);

  return (
    <Grid
      container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "24px",
        gap: "24px",
        minHeight: "312px",
        backgroundColor: "#FFFFFF",
        border: "1px solid #F4F4F4",
        boxShadow: "0px 4px 8px 0px #0000001A",
        borderRadius: "24px",
        marginLeft: "30px"
      }}
    >
      <Box
        style={{
          fontSize: "24px",
          color: "#011340",
          height: "36px",
          width: "100%",
          lineHeight: "36px",
          fontWeight: "600",
        }}
      >
        FAQs
      </Box>
      <Grid
        container
      >
        {filterTags?.map((tag) => (
          <Grid
            key={tag}
            item
            xs={1}
            style={{
              padding: "2px 5px",
              minheight: "20px",
              minWidth: "fit-content",

              textAlign: "start",
            }}
          >
            <Button onClick={() => handleFilterClick(tag)}>
              <span
                style={{
                  color: "#5D5C5C",
                  fontWeight: "500",
                  fontSize: "13px",
                  padding: "5px 10px",
                  borderBottom: filterSelected === tag && "3px solid #4373EB",
                }}
              >
                {tag}
              </span>
            </Button>
          </Grid>
        ))}
      </Grid>
      <Grid
        container
        sx={{
          padding: 0,
          borderBottom: "1px solid #D9D9D9",
          boxShadow: "none",
        }}
      >
        {dataOfQuery?.map((content) => (
          <Accordion
            key={content?.queryTypeMasterId}
            expanded={expanded === content?.queryTypeMasterId}
            onChange={handleChange(content?.queryTypeMasterId)}
            sx={{
              width: "100%",
            }}
          >
            <AccordionSummary
              expandIcon={
                expanded === content?.queryTypeMasterId ? (
                  <RemoveIcon />
                ) : (
                  <AddIcon />
                )
              }
              aria-controls={`${content?.queryTypeMasterId}d-content`}
              id={`${content?.queryTypeMasterId}d-header`}
            >
              <Typography color={"#000000"} fontSize={13} fontWeight={600}>
                {content?.queryTitle}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color={"#000000"} fontSize={13} fontWeight={400}>
                {content?.queryDescription}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Grid>
    </Grid>
  );
};

export default FAQ;

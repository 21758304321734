import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  Paper,
  Button,
  StepLabel,
  Stepper,
} from "@mui/material";
import golfHoleSVG from "./styles/golf-hole 2.svg";
import vectorSVG from "./styles/Vector.svg";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import SendRoundedIcon from "@mui/icons-material/SendRounded";
import styled from "styled-components";
import moment from "moment";
import { formatDate } from "../../HelperFunctions/HelperFunctions";
import { useGetCommunication, useGetCommunicationDataByIds } from "./service";
import CommunicationLog from "../../components/CommunicationLog/CommunicationLog";

const Communicationcomponent = () => {
  const data = [
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-21T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-22T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "In progress",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-22T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-19T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Pending",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-19T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-22T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Pending",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-22T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-19T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-24T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-23T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-21T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-18T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-17T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-18T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
    {
      scheduledCommunicationsAudiencesId: 15482,
      scheduledAt: "2024-06-16T06:03:37.344+00:00",
      content: "Claim for Policy Number ICICI000366 has been raised",
      status: "Initiated",
    },
  ];
  const parsedData = data.map((item) => ({
    ...item,
    scheduledAt: new Date(item.scheduledAt),
  }));

  // Creates an Object of Datelabel and Message.
  const groupedData = parsedData?.reduce((acc, curr) => {
    // const dateLabel = formatDate(curr?.scheduledAt);
    const dateLabel = curr?.scheduledAt;

    // If the date label does not exist in the accumulator, It adds a new key to it.
    if (!acc[dateLabel]) {
      acc[dateLabel] = [];
    }
    // If key exists we push the message to that key's array.
    acc[dateLabel]?.push(curr);

    return acc;
  }, {});

  const sortedKeys = Object.keys(groupedData).sort(
    (a, b) => new Date(b) - new Date(a)
  );

  // Create a new object with sorted keys
  const sortedData = sortedKeys.reduce((sortedObj, key) => {
    sortedObj[key] = groupedData[key];
    return sortedObj;
  }, {});

  const { data: communicationDataIds, mutate } = useGetCommunication();
  useEffect(() => {
    mutate();
  }, []);

  const entityIds =
    communicationDataIds && communicationDataIds?.map((obj) => obj?.entityId);
  const { data: communicationDataByIds } =
    useGetCommunicationDataByIds(entityIds);
  const communicationsDataToShow = communicationDataByIds?.map(
    (obj) => obj?.data?.data?.data
  );

  const flattenedData = communicationsDataToShow?.flat();

  // Parse dates and add scheduledAt field
  const parsedCommunicationsDataToShow = flattenedData?.map((item) => ({
    ...item,
    scheduledAt: new Date(item?.createdAt),
  }));

  // Group by date
  const groupedCommunicationsDataToShow =
    parsedCommunicationsDataToShow?.reduce((acc, curr) => {
      const dateLabel =
        curr?.scheduledAt &&
        new Date(curr?.scheduledAt).toLocaleDateString("en-IN");
      console.log(new Date(curr?.scheduledAt).toLocaleDateString("en-IN")); // Use only the date part

      if (!acc[dateLabel]) {
        acc[dateLabel] = [];
      }
      acc[dateLabel]?.push(curr);

      return acc;
    }, {});

  // Sort keys and create sorted data object
  const sortedKeysCommunicationsDataToShow = Object.keys(
    groupedCommunicationsDataToShow
  )?.sort((a, b) => new Date(b) - new Date(a));

  const sortedDataCommunicationsDataToShow =
    sortedKeysCommunicationsDataToShow?.reduce((sortedObj, key) => {
      sortedObj[key] = groupedCommunicationsDataToShow[key];
      return sortedObj;
    }, {});

  console.log(
    "sortedDataCommunicationsDataToShow",
    sortedDataCommunicationsDataToShow,
    "\nsortedData",
    sortedData
  );

  const dataToShow = [
    [
      {
        customerActivityHistory: 39,
        customerId: 2624,
        transactionType: "RENEWAL",
        activityType: "INITIAL",
        stage: "INITIAL",
        remark: "",
        activityBy: "CUSTOMER",
        createdBy: 2624,
        createdAt: "2024-06-17T12:59:56.970+00:00",
        entityId: 24,
        policyNumber: "85412305",
        heading: "Renewal  for policy number  85412305  has been raised",
        agentName: "Amit Patil",
      },
    ],
    [],
    [
      {
        customerActivityHistory: 41,
        customerId: 2624,
        transactionType: "RENEWAL",
        activityType: "INITIAL",
        claimStatus: "INITIAL",
        remark: "",
        activityBy: "CUSTOMER",
        createdBy: 2624,
        createdAt: "2024-06-17T13:50:24.007+00:00",
        entityId: 26,
        policyNumber: "D8009363682",
        heading: "Renewal  for policy number  D8009363682  has been raised",
        agentName: "Amit Patil",
        policyNo: '531'
      },
    ],
    [
      {
        customerActivityHistory: 42,
        customerId: 2624,
        transactionType: "RENEWAL",
        activityType: "INITIAL",
        claimStatus: "INITIAL",
        remark: "",
        activityBy: "CUSTOMER",
        createdBy: 2624,
        createdAt: "2024-06-17T13:50:35.850+00:00",
        entityId: 27,
        policyNumber: "D8009363682",
        heading: "Renewal  for policy number  D8009363682  has been raised",
        agentName: "Amit Patil",
        policyNo: '531'
      },
    ],
    [
      {
        customerActivityHistory: 43,
        customerId: 2624,
        transactionType: "RENEWAL",
        activityType: "INITIAL",
        claimStatus: "INITIAL",
        remark: "ere rthgb sehb erg",
        activityBy: "CUSTOMER",
        createdBy: 2624,
        createdAt: "2024-06-17T13:50:59.730+00:00",
        entityId: 28,
        policyNumber: "D8009363682",
        heading: "Renewal  for policy number  D8009363682  has been raised",
        agentName: "Amit Patil",
        policyNo: '531'
      },
      {
        customerActivityHistory: 43,
        customerId: 2624,
        transactionType: "RENEWAL",
        activityType: "INITIAL",
        claimStatus: "INITIAL",
        remark: "hggdfsdfbdfghb ",
        activityBy: "CUSTOMER",
        createdBy: 2624,
        createdAt: "2024-06-17T13:50:59.730+00:00",
        entityId: 28,
        policyNumber: "D8009363682",
        heading: "Renewal  for policy number  D8009363682  has been raised",
        agentName: "Amit Patil",
        policyNo: '531'
      },
    ],
  ];

  return (
    <Container
      sx={{
        padding: "0px !important",
      }}
    >
      <div style={{ padding: "0 5px 5px" }}>
        <div style={{ padding: "5px" }}>
          {Array.isArray(dataToShow) && dataToShow?.map((data, index) => (
            <div style={{ marginBottom: "26px" }}>
              <p>Today</p>
              <StyledMainDiv key={index}>
                <StyledHeader>
                  <div
                    style={{
                      width: "32px !important",
                      height: "32px !important",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#35C17B",
                      borderRadius: "15px",
                      marginRight: "10px",
                    }}
                  >
                    <SendRoundedIcon
                      style={{
                        color: "white",
                        height: "20px",
                        width: "20px",
                        margin: "5px",
                      }}
                    />
                  </div>
                  <TitleSpan>Claim Communication for Policy</TitleSpan>
                  <TitleSpan small={true}>&nbsp;ICICI000366</TitleSpan>
                </StyledHeader>
                <CommunicationLog key={index} data={data} index={index} />
              </StyledMainDiv>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default Communicationcomponent;

export const StyledMainDiv = styled.div`
  border-radius: 10px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
`;
export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cacaca;
  padding-bottom: 10px;
  margin-bottom: 10px;
`;

export const StyledSpan = styled.span`
  fontsize: 15px;
  fontweight: 500;
  lineheight: 19.53px;
  letterspacing: 0.02em;
  color: "#444444";
`;

export const StyledStepper = styled.div`
  // border:1px solid yellow;
  padding: 10px;
`;

export const TitleSpan = styled.span`
  font-size: 20px;
  font-weight: ${({ small }) => (small ? "700" : "400")};
  line-height: 19.53px;
  letter-spacing: 0.02em;
  color: "#444444";
`;

// import Dropdown from "../../../components/Dropdown/Dropdown";
import { useLocation } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import {
  COMPREHENSIVE_1_PLUS_1,
  COMPREHENSIVE_2_PLUS_2,
  COMPREHENSIVE_3_PLUS_3,
  COMPREHENSIVE_BUNDLED_1_PLUS_3,
  COMPREHENSIVE_BUNDLED_1_PLUS_5,
  COMPREHENSIVE_BUNDLED_3_PLUS_3,
  COMPREHENSIVE_BUNDLED_5_PLUS_5,
  NEW,
  RENEWAL,
  RENEWAL_BREAK_IN,
  RENEWAL_ROLL_OVER,
  ROLL_OVER,
  SAOD,
  TP_1_YEAR,
  TP_LONG_TERM_3_YEARS,
  TP_LONG_TERM_5_YEARS,
} from "./constants";

function ProposalDropdown({
  id,
  label,
  control,
  setValue,
  masterTableData,
  isRequired,
  proposalRead,
  manufacturerData,
  modelData,
  versionData,
  branchData,
  icData,
  locationData,
  fuelData,
  userData,
  placeholder,
  type,
  filter,
  description,
  errors,
  watchMembers,
  watchNominee,
  onChange,
  isClearable,
  policyType,
  lobName,
  isMulti,
  readOnly,
  labelSize,
  policyDetails,
  modeofTransportation,
  claim,
}) {
  let masterTableField = masterTableData?.find(
    (value) => value?.data?.data?.[0]?.fieldSlug === filter
  );

  let selectObj = [];

  switch (id) {
    case "fuelType":
      const fuelType_Obj = fuelData?.data?.map((value) => ({
        label: value,
        value,
      }));

      selectObj = fuelType_Obj ? [...fuelType_Obj] : [];
      break;

    case "make":
      const makeType_Obj =
        Array.isArray(manufacturerData?.data) &&
        manufacturerData?.data?.map((value) => ({
          label: value.manf_name,
          value: value.manf_name,
          id: value.manf_id,
        }));

      selectObj = makeType_Obj ? [...makeType_Obj] : [];
      break;

    case "model":
      const model_Obj = modelData?.data?.map((value) => ({
        label: value.model_name,
        value: value.model_name,
        id: value.model_id,
      }));

      selectObj = model_Obj ? [...model_Obj] : [];
      break;

    case "version":
      const version_Obj = versionData?.data?.map((value) => ({
        label: value.version_name,
        value: value.version_name,
      }));

      selectObj = version_Obj ? [...version_Obj] : [];
      break;

    case "branchNameic":
      const branch_Obj =
        Array.isArray(branchData?.data?.data) &&
        branchData?.data?.data?.map((value) => ({
          label: value.branchName,
          value: value.branchName,
        }));

      selectObj = branch_Obj ? [...branch_Obj] : [];
      break;

    case "branchLocation":
      const location_Obj =
        Array.isArray(locationData) &&
        locationData?.map((value) => ({
          label: `${value.city} - ${value.officeCode}`,
          value: value.brokerOfficeLocationRelationId,
          id: value.brokerOfficeLocationRelationId,
        }));

      selectObj = location_Obj ? [...location_Obj] : [];
      break;

    case "icName":
    case "insuranceCompanyname":
      const company_Obj =
        Array.isArray(icData?.data?.data) &&
        icData.data.data.map((value) => {
          return {
            value: value.companyName,
            label: value.companyName,
            id: value.companyId,
          };
        });

      selectObj = company_Obj ? [...company_Obj] : [];
      break;

    default:
      selectObj = [];
  }

  if (userData && id === "Name") {
    selectObj = userData?.data?.data?.map((value) => {
      return {
        label: `${value?.first_name} ${value?.last_name}`,
        value: value?.user_id,
        id: value?.user_id,
      };
    });
  }

  if (id === "policyType") {
    const uniqueOptions = masterTableField?.data?.data?.[0]?.data?.reduce(
      (accumulator, currentValue) => {
        const existingOption = accumulator?.find(
          (item) => item?.optionValue === currentValue?.optionValue
        );
        if (!existingOption) {
          accumulator?.push(currentValue);
        }
        return accumulator;
      },
      []
    );

    selectObj = uniqueOptions?.map((value) => {
      return {
        label: value?.optionValue,
        value: value?.optionValue,
        id: value?.optionKey,
      };
    });
  }

  let optionsRequired = [];
  if (lobName === "Car") {
    if (policyType === NEW) {
      optionsRequired = [
        TP_LONG_TERM_3_YEARS,
        COMPREHENSIVE_BUNDLED_3_PLUS_3,
        COMPREHENSIVE_BUNDLED_1_PLUS_3,
      ];
    } else if (policyType === RENEWAL) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === RENEWAL_ROLL_OVER) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else if (policyType === RENEWAL_BREAK_IN) {
      optionsRequired = [COMPREHENSIVE_1_PLUS_1, TP_1_YEAR, SAOD];
    } else {
      optionsRequired = [];
    }
  }
  if (lobName === "Bike") {
    if (policyType === NEW) {
      optionsRequired = [
        TP_LONG_TERM_5_YEARS,
        COMPREHENSIVE_BUNDLED_1_PLUS_5,
        COMPREHENSIVE_BUNDLED_5_PLUS_5,
      ];
    } else if (policyType === RENEWAL) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === RENEWAL_ROLL_OVER) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === ROLL_OVER) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else if (policyType === RENEWAL_BREAK_IN) {
      optionsRequired = [
        COMPREHENSIVE_1_PLUS_1,
        TP_1_YEAR,
        SAOD,
        COMPREHENSIVE_2_PLUS_2,
        COMPREHENSIVE_3_PLUS_3,
      ];
    } else {
      optionsRequired = [];
    }
  }

  if (id === "businessType" && (lobName === "Bike" || lobName === "Car")) {
    selectObj = masterTableField?.data?.data?.[0]?.data
      ?.reduce((accumulator, currentValue) => {
        const existingOption = accumulator?.find(
          (item) => item?.optionValue === currentValue?.optionValue
        );
        if (!existingOption) {
          accumulator?.push(currentValue);
        }
        return accumulator;
      }, [])
      ?.filter((item) => optionsRequired?.includes(item?.optionValue) && item)
      ?.map((value) => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });
  }

  if (
    (id === "modeofTranspo" || id === "modetransport") &&
    lobName === "Marine"
  ) {
    selectObj = modeofTransportation?.map((item) => {
      return {
        label: item,
        value: item,
      };
    });
  }

  const hardCodedDropdowns = [
    "fuelType",
    "make",
    "model",
    "version",
    "branchNameic",
    "Name",
    "branchLocation",
    "icName",
    "insuranceCompanyname",
    "policyType",
    "modeofTranspo",
    "modetransport",
  ];

  if (lobName === "Bike" || lobName === "Car") {
    hardCodedDropdowns.push("businessType");
  }

  if (!hardCodedDropdowns.includes(id)) {
    selectObj = masterTableField?.data?.data?.[0]?.data?.map((value) => {
      return {
        label: value.optionValue,
        value: value.optionValue,
        id: value.optionKey,
      };
    });
  }

  if (id === "manufactureMonth") {
    const monthsArray = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    selectObj = monthsArray.map((value) => {
      return {
        label: value,
        value,
      };
    });
  }

  const isSelfAlreadySelected =
    Array.isArray(watchMembers) &&
    watchMembers?.some((item) => item?.relWithprop?.value === "Self");
  const selectObjWithoutSelf =
    isSelfAlreadySelected &&
    masterTableField?.data?.data?.[0]?.data
      ?.filter((item) => item?.optionValue !== "Self")
      ?.map((value) => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });

  const isSelfNomineeAlreadySelected =
    Array.isArray(watchNominee) &&
    watchNominee?.some((item) => item?.nomineeRelation?.value === "Self");
  const selectObjectWithoutSelf =
    isSelfNomineeAlreadySelected &&
    masterTableField?.data?.data?.[0]?.data
      ?.filter((item) => item?.optionValue !== "Self")
      ?.map((value) => {
        return {
          label: value.optionValue,
          value: value.optionValue,
          id: value.optionKey,
        };
      });

  // const selectedEmployeeDescriptions =
  //   Array.isArray(watchEmployee) &&
  //   watchEmployee
  //     ?.map((item) => {
  //       return item?.descriptionofWorkdonebyemployee?.value;
  //     })
  //     ?.filter((item) => item);
  // const remainingEmployeeDescriptions =
  //   Array.isArray(selectObj) &&
  //   selectObj?.filter(
  //     (item) => !selectedEmployeeDescriptions?.includes(item?.value)
  //   );

  if (type === "CHAR") {
    selectObj = [
      { label: "Yes", value: "Yes" },
      { label: "No", value: "No" },
    ];
  }
  const members = policyDetails?.members;
  if (id === "relWithprop" && lobName === "Health") {
    const relWithProp = members?.map((item) => {
      return {
        label: `${item?.relWithprop} - ${item?.insuredmemberName}`,
        value: `${item?.relWithprop} - ${item?.insuredmemberName}`,
      };
    });
    selectObj = relWithProp;
    // selectObj = relationWpObj
  }
  if (id === "insuredmemberName" && lobName === "Health") {
    const insuredName = members?.map((item) => {
      return {
        label: item?.insuredmemberName,
        value: item?.insuredmemberName,
      };
    });
    selectObj = insuredName;
  }
  if (id === "relationWithlifeassured" && lobName === "Term") {
    const relationWithlifeassured = [
      {
        label: policyDetails?.relationWithlifeassured,
        value: policyDetails?.relationWithlifeassured,
      },
    ];
    selectObj = relationWithlifeassured;
  }
  return (
    <Dropdown
      id={id}
      label={label}
      control={control}
      setValue={setValue}
      selectObj={
        id.includes("relWithprop") && isSelfAlreadySelected
          ? selectObjWithoutSelf
          : id.includes("nomineeRelation") && isSelfNomineeAlreadySelected
          ? selectObjectWithoutSelf
          : // : id?.includes("descriptionofWorkdonebyemployee") &&
            //   selectedEmployeeDescriptions?.length
            // ? remainingEmployeeDescriptions
            selectObj
      }
      isRequired={isRequired}
      placeholder={placeholder}
      maxMenuHeight={200}
      proposalRead={proposalRead}
      description={description}
      errors={errors}
      onChange={onChange}
      isClearable={isClearable}
      isMulti={isMulti}
      isDisabled={readOnly}
      labelSize={labelSize}
      claim={claim}
    />
  );
}

export default ProposalDropdown;

import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";

export const useAddClaims = () => {
  const { data, mutateAsync, isSuccess, isLoading } = useMutation((payload) => {
    return httpClient("claim_draft/add", {
      method: "POST",
      data: payload,
    });
  });

  return { data: data?.data?.data || [], mutateAsync, isSuccess, isLoading };
};
export const useFinalSubmit = ({ reset }) => {
  const navigate = useNavigate();
  const { data, mutate, isSuccess, isLoading } = useMutation(
    (mongodbId) => {
      return httpClient(`claim-final/Final-Submit/${mongodbId}`, {
        method: "POST",
      });
    },
    {
      onSuccess: (res) => {
        if (res?.data?.status) {
          Swal.fire("Claim Submitted Successfully", "", "success").then(
            (res) => {
              if (res.isConfirmed) {
                reset();
                navigate(`/claims`);
              }
            }
          );
        }
      },
    }
  );

  return { data: data?.data?.data || [], mutate, isSuccess, isLoading };
};

export const useGetBusinessTypeList = () => {
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const { data } = useQuery(["customerRecord"], () => {
    return httpClient(
      `segregation/get_data/business_type_master/business_type_master?lobId=${lob}`,
      { method: "GET" }
    );
  });
  return { data };
};

export const useGetMasterCompanyTypeList = () => {
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const { data } = useQuery(["masterCompanyRecord"], () => {
    return httpClient(
      `segregation/get_data/master_company/master_company?lobId=${lob}`,
      { method: "GET" }
    );
  });
  return { data };
};

export const useGetPlanTypeList = () => {
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const { data } = useQuery(["planType"], () => {
    return httpClient(
      `segregation/get_data/plan_type_master/plan_type_master?lobId=${lob}`,
      { method: "GET" }
    );
  });
  return { data };
};

export const useGetRelationList = () => {
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const { data } = useQuery(["relationList"], () => {
    return httpClient(
      `segregation/get_data/relation_master/relation_master?lobId=${lob}`,
      { method: "GET" }
    );
  });
  return { data };
};

export const useGetPrefillDataFromTransactionId = (payload) => {
  const { data } = useQuery(["prefillDataFromTransactionId"], () => {
    return httpClient(
      `policy_master/list/${payload?.lob}?id=${payload?.transactionId}`,
      {
        method: "POST",
      }
    );
  });
  return { data };
};

import styled from "styled-components";
import Error500Image from "../utils/error500.jpg";

function Error500() {
    return (
        <ErrorStyled></ErrorStyled>
    );
}

export default Error500;


const ErrorStyled = styled.div`
  width: 100%;
  height: 90vh;
  position: relative;
  background-image: url(${Error500Image});
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #fff;
  background-position: 50%;
  text-align: center;
  padding: 2rem;
`;
import styled from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
`;

export const StyledBg = styled.div`
  background: #fff;
  padding: 20px;
`;

export const TableFrame = styled.div`
  display: flex;
  position: relative;
  align-items: ${({ position }) => (position ? "start" : "center")};
  flex-direction: column;
  
  box-shadow: 0px 22px 45px -15px #dddde2;
  background-color: #ffffff;
  margin-top: 15px;
  padding: 20px;
  max-width: 100% !important;
`;

export const TableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 10px;
`;

export const TableContainer = styled.div`
  width: 100%;
  background: #fff;
`;
export const Heading = styled.h2`
  margin-top: -3px;
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  color: #178e80;
  
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
  svg {
    color: #4373eb;
    font-size: 20px;
  }
`;

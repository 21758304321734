import moment from "moment";
export const preventNumbersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\d/g, "");
  e.target.value = sanitizedValue;
};

export const preventSpecialInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const allowAlphanumericHyphenAndSpace = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^A-Z0-9- ]/g, "");
  e.target.value = sanitizedValue;
};

export const allowAlphanumericHyphenUndescore = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^a-zA-Z0-9-_]/g, "");
  e.target.value = sanitizedValue;
};

export const preventSpecialAndNumbersInInput = (e) => {
  const value = e.target.value;
  if (value === " ") {
    const sanitizedValue = value.replace(/\s/g, "");
    e.target.value = sanitizedValue;
    return;
  }
  const sanitizedValue = value.replace(/[^a-zA-Z\s]+/g, "");
  e.target.value = sanitizedValue;
};

export const preventCharactersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\D/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidPincode = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/^0+|[^0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidNumbers = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/^[0-5][0-9]*$|\D/g, "");
  e.target.value = sanitizedValue;
};

export const allowPercentageNumbersUpto100 = (e) => {
  const value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Ensure the value is a valid number and limit it to 99
  if (sanitizedValue > 100) {
    sanitizedValue = "100";
  }

  e.target.value = sanitizedValue;
};

export const allowPercentageNumbers = (e) => {
  const value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Ensure the value is a valid number and limit it to 99
  if (sanitizedValue > 99) {
    sanitizedValue = 99;
  }

  e.target.value = sanitizedValue;
};

export const preventZeroAsInitial = (e) => {
  const value = e.target.value;
  let sanitizedValue = value.replace(/^[0]*$|\D/g, "");
  e.target.value = sanitizedValue;
};

export const preventWhiteSpaceInInput = (e) => {
  const sanitizedValue = e.target.value.replace(/\s/g, "");
  e.target.value = sanitizedValue;
};

export const handleFloatInput = (e) => {
  let value = e.target.value;

  // Replace non-digit characters except for a dot
  let sanitizedValue = value.replace(/[^\d.]/g, "");

  // If there's more than one dot, keep only the first one
  const dotIndex = sanitizedValue.indexOf(".");
  if (dotIndex !== -1) {
    sanitizedValue =
      sanitizedValue.substring(0, dotIndex + 1) +
      sanitizedValue.substring(dotIndex + 1).replace(/\./g, "");
  }

  // Limit to two decimal places
  const decimalIndex = sanitizedValue.indexOf(".");
  if (decimalIndex !== -1) {
    const decimalPart = sanitizedValue.substring(
      decimalIndex + 1,
      decimalIndex + 3
    );
    sanitizedValue =
      sanitizedValue.substring(0, decimalIndex + 1) + decimalPart;
  }

  e.target.value = sanitizedValue;
};

export const verifyValidEmail = (e) => {
  //all custom email validation here
  return e.target.value;
};

export const handleInput = (e) => {
  //use this fn if input looses focus when typing
  //only use this if task is priority else try to resolve the error
  return e.target.value;
};

export const allowAlphabetsAndNumbersInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/[^a-zA-Z0-9\s]+/g, "");
  e.target.value = sanitizedValue;
};

export const verifyValidAadhar = (e) => {
  let value = e.target.value;

  // Remove non-digit characters
  let sanitizedValue = value.replace(/\D/g, "");

  // Prevent adding '0' or '1' at the start
  if (sanitizedValue.startsWith("0") || sanitizedValue.startsWith("1")) {
    sanitizedValue = sanitizedValue.substring(1); // Remove the first character
  }

  // Limit to 12 characters
  sanitizedValue = sanitizedValue.substr(0, 12);

  // Update the input value
  e.target.value = sanitizedValue;
};

export const verifyValidPAN = (e) => {
  const value = e.target.value.toUpperCase();
  const sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, "");
  e.target.value = sanitizedValue;
};

export const defaultDateFormat = (date) => {
  if (!date) {
    return moment(new Date()).format("DD-MM-YYYY");
  }
  const formattedDate = moment(date, "YYYY-MM-DD").format("DD-MM-YYYY");
  return formattedDate;
};
export const allowOnlyNumbers = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/g, "");
};
export const allowOnlyNumbersAndDecimal = (e) => {
  // e.target.value = e.target.value.replace(/[^0-9.]/g, "");
  const regex = /^[0-9]*\.?[0-9]*$/;
  if (!regex.test(e.target.value)) {
    e.target.value = e.target.value
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");
  }
};

export const allowLowerCaseAndUnderscore = (e) => {
  const value = e.target.value.toLowerCase();
  const sanitizedValue = value.replace(/[^a-z_]/g, "");
  e.target.value = sanitizedValue;
};
export const allowOnlySlug = (e) => {
  e.target.value = e.target.value.replace(/[^a-zA-Z0-9]/gi, "");
};

export const allowOnlyNumbersWithoutSpace = (e) => {
  e.target.value = e.target.value.replace(/[^0-9]/gi, "");
};

export const allCapitalize = (e) => {
  const cursorPosition = e.target.selectionStart;
  e.target.value = e.target.value.toUpperCase();
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const allowOnlyAlphabetsNoSpace = (e) => {
  const value = e.target.value;
  if (value === " ") {
    const sanitizedValue = value.replace(/\s/g, "");
    e.target.value = sanitizedValue;
    return;
  }
  const sanitizedValue = value.replace(/[^a-zA-Z]+/g, "");
  e.target.value = sanitizedValue;
};

export const allowOnlyAlphabetsWithSpace = (e) => {
  const sanitizedValue = e.target.value.replace(/[^a-zA-Z\s]+/g, "");
  e.target.value = sanitizedValue;
};

// export const allowOnlyAlphabetsWithSpace = (e) => {
//   const inputValue = e.target.value;
//   e.target.value = inputValue.replace(/^[a-zA-Z\s]+$/g, "");
// };
export const allowAlphaSpace = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^a-zA-Z\s]/g, "");
};

export const allowAlphaNumSpaceHyphen = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^a-zA-Z0-9\s-]/g, "");
};

export const allowAlphabetNumberSpace = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/[^a-zA-Z0-9\s]/g, "");
};

export const allowOnlyAlphabetsNumberNoSpace = (e) => {
  const value = e.target.value;
  if (value === " ") {
    const sanitizedValue = value.replace(/\s/g, "");
    e.target.value = sanitizedValue;
    return;
  }
  const sanitizedValue = value.replace(/[^a-zA-Z0-9]+/g, "");
  e.target.value = sanitizedValue;
};

export const allowLowerCase = (e) => {
  const cursorPosition = e.target.selectionStart;
  e.target.value = e.target.value.toLowerCase();
  e.target.setSelectionRange(cursorPosition, cursorPosition);
};

export const convertCamelCaseToNormal = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export function convertNumberToWord(num) {
  // Split the number into integer and decimal parts
  const integerPart = Number(String(num).split(".")[0]);
  const decimalPart = String(num).split(".")[1];

  const single_digit = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const double_digit = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const below_hundred = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  // Indian number naming conventions
  const crore = "Crore";
  const lakh = "Lakh";
  const thousand = "Thousand";
  const hundred = "Hundred";
  const zero = "Zero";
  const point = "Point";

  if (integerPart < 0) return false;

  // Function to translate integer part of the number into words
  function translateIntegerPart(integerPart) {
    if (integerPart < 10) {
      return single_digit[integerPart];
    } else if (integerPart < 20) {
      return double_digit[integerPart - 10];
    } else if (integerPart < 100) {
      let rem = translateIntegerPart(integerPart % 10);
      return below_hundred[Math.floor(integerPart / 10) - 2] + " " + rem;
    } else if (integerPart < 1000) {
      return (
        single_digit[Math.floor(integerPart / 100)] +
        " " +
        hundred +
        " " +
        translateIntegerPart(integerPart % 100)
      );
    } else if (integerPart < 100000) {
      return (
        translateIntegerPart(Math.floor(integerPart / 1000)) +
        " " +
        thousand +
        " " +
        translateIntegerPart(integerPart % 1000)
      );
    } else if (integerPart < 10000000) {
      return (
        translateIntegerPart(Math.floor(integerPart / 100000)) +
        " " +
        lakh +
        " " +
        translateIntegerPart(integerPart % 100000)
      );
    } else {
      return (
        translateIntegerPart(Math.floor(integerPart / 10000000)) +
        " " +
        crore +
        " " +
        translateIntegerPart(integerPart % 10000000)
      );
    }
  }

  // Function to translate decimal part of the number into words
  function translateDecimalPart(decimals) {
    let word = "";
    for (let i = 0; i < String(decimals)?.length; i++) {
      word += single_digit[String(decimals)[i]] + " ";
    }
    return word.trim();
  }

  // Get the result for the integer part
  let result = translateIntegerPart(parseInt(integerPart));

  // Append the decimal part if exists
  if (!!decimalPart) {
    result += " " + point + " " + translateDecimalPart(decimalPart);
  }

  return "(" + result.trim() + ")";
}

export const acronymFormatValue = (inputValue) => {
  if (inputValue < 1000) {
    return;
  } else if (inputValue >= 10000000) {
    return (
      (inputValue / 10000000)?.toFixed(6)?.toString()?.slice(0, -4) + " Cr"
    );
  } else if (inputValue >= 100000) {
    return (inputValue / 100000)?.toFixed(6)?.toString()?.slice(0, -4) + " Lac";
  } else if (inputValue >= 1000) {
    return (inputValue / 1000)?.toFixed(6)?.toString()?.slice(0, -4) + " k";
  } else {
    return inputValue;
  }
};

export const checkAllZero = (array) => {
  // Check if all values in the array are zero
  return array.every((value) => value === 0);
};

export const allowValidPhoneNumber = (e) => {
  let { value } = e.target;
  // Check if the input starts with a number less than 6
  if (/^[1-5]/.test(value)) {
    // If it does, remove the first character
    value = value.slice(1);
  }
  // Replace any non-numeric characters
  e.target.value = value.replace(/[^0-9]/g, "");
};

export const allow10DigitsForNumber = (e) => {
  let { value } = e.target;

  // Remove non-numeric characters
  value = value.replace(/[^0-9]/g, "");

  // Check if the input starts with a number less than 6
  if (/^[1-5]/.test(value)) {
    // If it does, remove the first character
    value = value.slice(1);
  }

  // Limit to a maximum of 10 digits
  if (value.length > 10) {
    value = value.slice(0, 10);
  }

  e.target.value = value;
};

export const preventFirstZero = (e) => {
  const abc = e.target.value;
  const xyz = abc[0] === "0" && abc.length >= 1 ? abc.slice(1) : abc;
  e.target.value = xyz;
};

export const convertCamelCaseToNormalStr = (str) => {
  if (typeof str !== "string") return; // Fail Safe if str is not string.

  // Check if the input string is in camelCase or snake_case
  if (str?.includes("_")) {
    // If snake_case, replace underscores with spaces
    str = str?.replace(/_/g, " ");
  } else {
    // If camelCase, insert space before capital letters
    str = str?.replace(/([a-z])([A-Z])/g, "$1 $2").replace(/,/g, ", ");
  }

  // Convert to lowercase, split into words, capitalize each word, and join with spaces
  return str
    ?.toLowerCase()
    ?.split(" ")
    ?.map((word) => word?.charAt(0).toUpperCase() + word?.slice(1))
    ?.join(" ");
};

export const getFilePath = (link) => {
  // Extract file extension from the link
  const extension = link?.split(".")?.pop()?.toLowerCase();

  // File path based on file extension
  switch (extension) {
    case "png":
      return require("../utils/png.png");
    case "pdf":
      return require("../utils/pdf.png");
    case "xlsx":
      return require("../utils/excel.png");
    case "doc":
      return require("../utils/word.png");
    case "ppt":
      return require("../utils/ppt.png");
    case "tif":
      return require("../utils/tif.png");
    case "txt":
      return require("../utils/txt.png");
    case "jpeg":
    case "jfif":
    case "jpg":
      return require("../utils/jpg.png");
    // Add more cases as needed for other file extensions
    default:
      return "Unknown file type.";
  }
};

export const NoSpaceAllowed = (e) => {
  const inputValue = e.target.value;
  e.target.value = inputValue.replace(/\s/g, "");
};
export const preventSpacesInInput = (e) => {
  const value = e.target.value;
  const sanitizedValue = value.replace(/\s/g, "");
  e.target.value = sanitizedValue;
};

export const isValidURL = (url) => {
  // Regular expression pattern for URL validation
  var urlPattern = /^(?:http|https):\/\/(?:www\.)?[^\s.]+\.[^\s]{2,}$/i;
  return urlPattern.test(url);
};

export const preventHyphenInInput = (event) => {
  const sanitizedValue = event.target.value.replace(/-/g, "");
  event.target.value = sanitizedValue;
};

export const handleDrivingLicense = (event) => {
  const inputValue = event.target.value;
  if (inputValue && inputValue.includes("-")) return;

  if (inputValue.length === 4 && event.key !== "Backspace") {
    event.target.value = inputValue + " ";
  }
};

export const formatDate = (inputDate) => {
  const date = moment(inputDate);
  const today = moment().startOf("day");
  const yesterday = moment().subtract(1, "days").startOf("day");

  if (date.isSame(today, "day")) {
    return "Today";
  } else if (date.isSame(yesterday, "day")) {
    return "Yesterday";
  } else {
    return date.format("DD/MM/YYYY");
  }
};

export const engineNumber = (e) => {
  const inputValue = e.target.value;
  const sanitizedValue = inputValue.replace(/[^A-Za-z0-9-]/g, '');
  e.target.value = sanitizedValue;
}

export const allowTwoNamesWithSpace = (e) => {
  let inputValue = e.target.value;

  // Sanitize the input: remove non-alphabetic characters, replace multiple spaces with a single space, and trim spaces
  inputValue = inputValue
    .replace(/[^a-zA-Z\s]+/g, "") // Remove non-alphabetic characters
    .replace(/\s+/g, " ")         // Replace multiple spaces with a single space
    .trimStart();                 // Remove leading spaces

  // Split the value by space to get the individual words
  const words = inputValue.split(" ");

  // If there are more than two words, keep only the first two
  if (words.length > 2) {
    inputValue = words.slice(0, 2).join(" ");
  }

  e.target.value = inputValue;
};



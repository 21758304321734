import { Box, Container, Grid } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { Button, Input } from "../../components";
import TextArea from "../../components/form-components/textarea/textarea";
import { HelpFormField } from "./HelpFormField";
import { useGetAllPolicyNumbers, useSupportRequestCustomer } from "./service";
import CustomerFilePicker from "../../components/CustomerFilePicker/CustomerFilePicker";
import { useLocation } from "react-router-dom";
import Dropdown from "../../components/Dropdown/Dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup"
const HelpForm = () => {
  const validationSchema = yup.object().shape({
    fullName: yup
      .string()
      .required('Full Name is required')
      .matches(/^[a-zA-Z\s]*$/, 'Full Name can only contain letters and spaces'),
    emailId: yup
      .string()
      .email('Enter a valid email')
      .required('Email is required'),
    howCanWeHelpYou: yup
      .string()
      .required('This field is required'),
    policyNo: yup
      .string(),
    // .required('Policy Number is required'),
    subject: yup
      .string(),
    message: yup
      .string()
  });
  const {
    register,
    control,
    watch,
    setValue,
    unregister,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validationSchema) }); // Initializing the form
  const { data, mutate: addSupportCustomer } = useSupportRequestCustomer();

  const [files, setFiles] = useState({});
  const { data: policyNumber } = useGetAllPolicyNumbers();

  useMemo(() => {
    const newFiles = { ...files };
    delete newFiles.uploadDocument;
    setFiles(newFiles);
  }, []);

  const onSubmit = (data) => {
    const payload = {
      emailId: data?.emailId,
      fullName: data?.fullName,
      howCanWeHelpYou: data?.howCanWeHelpYou,
      message: data?.message,
      subject: data?.subject,
      attachment: data?.attachment?.[0]?.base64String,
    };
    console.log(data)
    addSupportCustomer(payload);
  };

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const policyNo = params.get("pn");

  useEffect(() => {
    if (policyNo) {
      setValue("policyNo", policyNo);
    }
  }, [policyNo]);

  const policyNoObjs = policyNumber?.data?.data?.map((item) => {
    return { label: item["Policy No"], value: item["Policy No"] };
  });
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid
          item
          lg={12}
          sx={{
            backgroundColor: "#FFFFFF",
            border: "1px solid #F4F4F4",
            borderRadius: "20px",
            width: "100%",
          }}
        >
          <Box
            style={{
              fontSize: "24px",
              color: "#011340",
              width: "100%",
              fontWeight: "600",
            }}
          >
            Get in Touch
          </Box>
          <Box
            style={{
              fontSize: "15px",
              color: "#011340",
              width: "100%",
              fontWeight: "100",
              marginBottom: "15px",
            }}
          >
            Find answers to your questions and get assistance with our comprehensive help resources.
          </Box>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            {HelpFormField?.map((field) => {
              if (field.name === "policyNo" && policyNo) {
                return (
                  <>
                    <Grid key={field.name} item {...field.style}>
                      <Input
                        // themeType={"theme"}
                        label={field.label}
                        name={field.name}
                        id={field.name}
                        placeholder={field.placeholder}
                        defaultValue={""}
                        required={false}
                        inputRef={register(field.name)}
                        proposalRead={true}
                        watch={watch}
                        errors={errors}
                      />
                    </Grid>
                  </>
                );
              }
              if (field.name === "policyNo") {
                return (
                  <>
                    <Grid key={field.name} item {...field.style}>
                      <Dropdown
                        // themeType={"theme1"}
                        label={field.label}
                        name={field.name}
                        id={field.name}
                        placeholder={field.placeholder}
                        required={false}
                        selectObj={policyNoObjs}
                        errors={errors}

                      />
                    </Grid>
                  </>
                );
              }
              if (field.fieldType === "input" && field.name !== "policyNo") {
                return (
                  <Grid key={field.name} item {...field.style}>
                    <Input
                      // themeType={"theme1"}
                      label={field.label}
                      name={field.name}
                      id={field.name}
                      placeholder={field.placeholder}
                      defaultValue={""}
                      required={false}
                      inputRef={register(field.name)}
                      errors={errors}

                    />
                  </Grid>
                );
              } else if (field.fieldType === "textarea") {
                return (
                  <Grid key={field.name} item {...field.style}>
                    <TextArea
                      // themeType={"theme1"}
                      label={field.label}
                      name={field.name}
                      id={field.name}
                      placeholder={field.placeholder}
                      autoComplete="none"
                      defaultValue={""}
                      required={false}
                      inputRef={register(field.name)}
                    />
                  </Grid>
                );
              }
            })}
          </Grid>
          <Grid container>
            <Grid
              item
              sx={{ position: "relative" }}
              xs={12}
              sm={12}
              md={12}
              lg={12}
              margin={1}
            >
              <CustomerFilePicker
                name="attachment"
                // label={"Document"}
                control={control}
                files={files}
                setFiles={setFiles}
                setValue={setValue}
                watch={watch}
                trigger={trigger}
                unregister={unregister}
                isRequired={false}
              />
            </Grid>
            <Grid
              container
              lg={3}
              sx={{
                backgroundColor: "#4373EB",
                height: "35px",
                borderRadius: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "10px",
                marginLeft: "auto"
              }}
            >
              <Button
                textColor={"white"}
                id={`done`}
                type="submit"
                style={{
                  width: "100%",
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default HelpForm;

import { Grid, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import { useTheme } from "@mui/material/styles";
import { Outlet, useLocation } from "react-router-dom";
import { createGlobalStyle } from "styled-components";
import "./styles/layout.css";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

export default function Layout() {
  const theme = useTheme();
  const location = useLocation();
  const isMobileAndTablet = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <>
      <CssBaseline />

      <Box
        component="main"
        sx={{
          width: "100%",
          background: "#eff3f6",
        }}
      >
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            lg={12}
          >
            <Grid container>
              {!isMobileAndTablet && (
                <div
                  style={{
                    minWidth: "200px",
                    height: "100vh",
                  }}
                >
                  <Sidebar />
                </div>
              )}
              <Grid
                sx={{
                  minHeight: `calc(100vh - 80px)`,
                  padding: 1,
                  paddingRight: 0,
                  marginTop: 1,
                }}
                item
                lg={10}
              >
                <Grid lg={10}>
                  <Navbar isMobileAndTablet={isMobileAndTablet} />
                </Grid>
                <Outlet />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <GlobalStyle />
    </>
  );
}

const GlobalStyle = createGlobalStyle`
.css-h4y409-MuiList-root{
  overflow-y: scroll !important;
}

.outlet-content{
  padding: 16px;
}
@-moz-document url-prefix() {
  ul, html, .MuiDataGrid-virtualScroller {
    scrollbar-width: none;
  }
}
 
 ::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: rgba(100, 100, 100, .5);
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background-color:rgb(16 128 193);
    border-radius: 9px;
    height:20px;
    width: 3px;
    cursor: pointer
}

.linkTag{
 
  text-decoration: none;
  color: ${({ selectedItem }) => (selectedItem ? "#057E70" : "#010101")};
  transition: all 0.3s ease-in-out;
 }

 .closeIcon {
  position: absolute;
    left: 52px;
    top: 6px;
    z-index: 999999;
    box-shadow: gray 1px 1px 5px;
    border-radius: 20px;
    background: white;
    transition: width 185ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
 }
`;

import Routes from "./routes";
import "./App.css";
import ErrorBoundary from "./hoc/ErrorBoundary";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Provider } from "react-redux";
import store from "./app/store";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
// import { createGlobalStyle } from "styled-components";
import { ThemeProvider } from "styled-components";
import theme from "./modules/theme/theme";
import { FormProvider, useForm } from "react-hook-form";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 5000, //5 seconds
    },
  },
});

// const GlobalStyle = createGlobalStyle`
// @font-face {
//   
//   src: url("../../public/assets/fonts/Montserrat/Montserrat-Regular.ttf")
//     format("truetype");
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   
//   src: url("../../public/assets/fonts/Montserrat/Montserrat-Medium.ttf")
//     format("truetype");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   
//   src: url("../../public/assets/fonts/Montserrat/Montserrat-SemiBold.ttf")
//     format("truetype");
//   font-weight: 600;
//   letter-spacing: 0.15px;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   
//   src: url("../../public/assets/fonts/Montserrat/Montserrat-Bold.ttf")
//     format("truetype");
//   font-weight: 700;
//   font-style: normal;
//   font-display: swap;
// }

// /* Roboto */

// @font-face {
//  
//   src: url("../../public/assets/fonts/Roboto/Roboto-Regular.ttf")
//     format("truetype");
//   font-weight: 400;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   
//   src: url("../../public/assets/fonts/Roboto/Roboto-Medium.ttf")
//     format("truetype");
//   font-weight: 500;
//   font-style: normal;
//   font-display: swap;
// }

//   body {
//     
//   }
//   .required {
//     color: red;
//     margin: 0 2px;
//   }

//   // This is for calendar UI
//   // !important tag is added to overwrite MUI's default styles
//   .MuiPickersCalendarHeader-root {
//     margin: 5px 0 !important;
//   }
//   .MuiPickersYear-yearButton {
//     margin: 0 !important;
//     padding: 0 !important;
//   }
//   .MuiYearCalendar-root {
//     max-height: 250px !important;
//     padding-bottom: 5px !important;
//   }
// `;

function App() {
  const methods = useForm();
  return (
    <ThemeProvider theme={theme}>
      <ErrorBoundary>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            {/* <GlobalStyle /> */}
            <FormProvider {...methods}>
              <div className="App">
                <Routes />
              </div>
            </FormProvider>
          </Provider>
          <ReactQueryDevtools position="top-left" initialIsOpen={false} />
        </QueryClientProvider>
      </ErrorBoundary>
    </ThemeProvider>
  );
}

export default App;

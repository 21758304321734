import { isArray } from "lodash";
import moment from "moment";
import { convertCamelCaseToNormalStr } from "../HelperFunctions/HelperFunctions";

export const useGetSummaryValue = ({ watch, id }) => {
  if (watch) {
    const isMultipleFields = id?.includes(".");

    const get_fields_in_id = (id) => {
      const fieldData = id.split(".");

      const memberPartToFormat = fieldData?.[0];
      const idPartForMutlipleFields = fieldData?.[1];

      const isMemberPartToFormatDirty = /[\[]/.test(memberPartToFormat);

      if (isMemberPartToFormatDirty) {
        const index = memberPartToFormat.indexOf("[");
        const memberPart = memberPartToFormat?.substring(0, index);
        const indexPart = memberPartToFormat?.substring(index);

        return [memberPart, indexPart, idPartForMutlipleFields];
      }

      if (id) {
        return id.split(".");
      }

      return [];
    };

    const [memberPart, indexPart, fieldId] = get_fields_in_id(id);

    const index = indexPart?.split("")?.[1];

    if (isMultipleFields) {
      const value = watch()?.[memberPart]?.[index]?.[fieldId];

      if (!value?.value && moment(value).isValid()) {
        return {
          fieldValue: watch(id),
        };
      }
      if (typeof value === "string") {
        return {
          fieldValue: watch(id),
        };
      }
      if (typeof value === "object") {
        return {
          fieldValue: watch(id)?.value,
        };
      }
    }

    if (!watch(id)?.value && moment(watch(id)).isValid()) {
      return {
        fieldValue: watch(id),
      };
    }

    if (typeof watch(id) === "string") {
      return {
        fieldValue: watch(id),
      };
    }

    if (watch && Array.isArray(watch(id))) {
      return {
        fieldValue:
          watch &&
          watch(id)
            ?.map((item) =>
              !!item?.label
                ? convertCamelCaseToNormalStr(item?.label)
                : item?.label
            )
            ?.join(", "),
      };
    }

    if (typeof watch(id) === "object") {
      return {
        fieldValue: watch(id)?.label,
      };
    }

    if (moment(watch(id)).isValid()) {
      return {
        fieldValue: watch(id),
      };
    }
  }
  return {
    fieldValue: "",
  };
};

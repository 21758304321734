import React, { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import ClaimForm from "./ClaimForm";
import useClaimSummary from "./useClaimSummary";
import { Button } from "../../../components";
import {
  CARGO_DETAILS,
  CONTRACTOR_DETAILS,
  EXPENSE_DETAILS,
  fieldLists,
  LOSS_ITEM_DETAILS,
  TRAVEL_DETAILS,
} from "../constants";
import ViewPolicyEntrySummary from "../ViewPolicyEntrySummary";
import { setPolicyViewCards } from "../proposal.slice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";

const Claim = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const lob = searchParams.get("lob");
  const transactionId = searchParams.get("TransactionId");

  const [additionalFileFields, setAdditionalFileFields] = useState([]);
  const finalSubmit = () => {
    const queryParams = new URLSearchParams(location.search);
    const mongoId = queryParams.get("mongoId");

    createFinalSubmit({ id: mongoId, oldTransactionId: transactionId });
  };

  // const { buttonRef } = useClaimSummary();
  const {
    createFinalSubmit,
    isError,
    // updateFinalSubmit,
    // isCreateFinalSubmitLoading,
    isUpdateFinalSubmitLoading,
    allStepsCompleted,
    extraCardTitles,
    masterTableData,
    updateExistingData,
    generateMongoId,
    proposalData,
    isProposalDataLoading,
    isCreateProposalDataLoading,
    isUpdateExistingProposalDataLoading,
    getModalData,
    getVersionData,
    getFuelData,
    manufacturerData,
    modelData,
    versionData,
    fuelData,
    editCard,
    setEditCard,
    activeStep,
    setActiveStep,
    completed,
    setCompleted,
    lobId,
    isLoading,
    current,
    setCurrent,
    isFirstView,
    setIsFirstView,
    mergedSchema,
    setMergedSchema,
    extraProposalCreate,
    mandatoryFields,
    dropdownFields,
    multiSelectFields,
    getOptionKeyByValue,
    getPolicyKeyByValue,
    setCurrentCardIndex,
    getMap,
    buttonRef,
    dateFields,
    // checkPolicyPresent,
    isPolicyPresent,
    isPrevPolicyPresent,
    checkPrevPolicyPresent,
    fileFields,
    members,
    setMembers,
    optionalFields,
    userId,
    setUserId,
    matchedObject,
    setSubmittedAt,
    policyDetails,
    policyPresent,
    dateAndTimeFields,
    lobName,
    isLastTitleInCurrentStep,
    cardTitlesPrevPolicyToggle,
    currentStepTitle,
  } = useClaimSummary();
  const dispatch = useDispatch();
  const { proposalCreateValues, uploadedFileURL, policyViewCards } =
    useSelector((state) => state.proposalConfig);
  const currentURL = window?.location?.pathname;

  const filterEmptyObjects = (obj) => {
    const filtered = {};
    for (const key in obj) {
      if (obj[key] && typeof obj[key] === "object") {
        // Skip empty objects
        continue;
      }
      filtered[key] = obj[key];
    }
    return filtered;
  };
  let summary = filterEmptyObjects(proposalData?.data?.data?.data) || {};
  let expenseSummary = proposalData?.data?.data?.data?.expenses || {};
  let summaryData = filterEmptyObjects(summary);

  const isPrevPolicyFromApi = summaryData?.expenseDetailss === "Yes";
  const expenseData = proposalData?.data?.data?.data?.expenses;
  const contractorDetailsExists = matchedObject[CONTRACTOR_DETAILS];

  const employeeDetailsFromApi =
    summaryData[EXPENSE_DETAILS] && summaryData[EXPENSE_DETAILS];

  const insureYourLiabilityToContractor =
    employeeDetailsFromApi?.members?.[0]?.compensationAct;

  const prevPolicyCardIndex = extraCardTitles?.findIndex(
    (value) => value === EXPENSE_DETAILS
  );
  const contractorDetailsCardIndex = extraCardTitles?.findIndex(
    (value) => value === CONTRACTOR_DETAILS
  );

  const cardFieldsPrevPolicyToggle = contractorDetailsExists
    ? !isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex)
      : isPrevPolicyFromApi && insureYourLiabilityToContractor !== "Yes"
      ? extraProposalCreate?.filter(
          (_, index) => index !== contractorDetailsCardIndex
        )
      : isPrevPolicyFromApi && insureYourLiabilityToContractor === "Yes"
      ? extraProposalCreate
      : extraProposalCreate?.filter(
          (_, index) =>
            index !== prevPolicyCardIndex &&
            index !== contractorDetailsCardIndex
        )
    : isPrevPolicyFromApi
    ? extraProposalCreate
    : extraProposalCreate?.filter((_, index) => index !== prevPolicyCardIndex);

  const allDataObj = {};
  cardTitlesPrevPolicyToggle?.forEach((key, index) => {
    allDataObj[key] = cardFieldsPrevPolicyToggle[index];
  });

  let summaryCardData = {};

  if (Object.keys(summaryData).length) {
    Object.keys(allDataObj).forEach((title) => {
      if (allDataObj.hasOwnProperty(title)) {
        if (title === CARGO_DETAILS) {
          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) => itemx?.key === "modeofTranspo")
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }
          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz) => {
              return allDataObj?.[title]
                ?.filter((itemx) => itemx?.key !== "modeofTranspo")
                ?.map((item) => {
                  return { ...item, value: itemz?.[item?.key] };
                });
            });
          });
        }
        // else if (title === TRAVEL_DETAILS) {

        //   function getFieldList(incidentType) {
        //     return fieldLists[incidentType] || [];
        //   }

        //   console.log("qwert", summaryData?.claimRespectivetravel);
        //   summaryCardData[title] = allDataObj?.[title]
        //     ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //     ?.map((item) => {
        //       return { ...item, value: summaryData?.[item?.key] };
        //     });

        //   if (!summaryCardData[title]) {
        //     summaryCardData[title] = {};
        //   }
        //   if (!summaryCardData[title].customData) {
        //     summaryCardData[title].customData = [];
        //   }

        //   summaryCardData[title].customData.push(
        //     allDataObj?.[title]
        //       ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
        //       ?.map((item) => {
        //         return { ...item, value: summaryData?.[item?.key] };
        //       })
        //   );
        //   const selectedTravelClaim =
        //     summaryData?.claimRespectivetravel?.split("|");

        //   Object.keys(expenseSummary).forEach(() => {
        //     const data = expenseSummary;
        //     summaryCardData[title].customData = data?.map((itemz) => {
        //       if (
        //         selectedTravelClaim?.includes(
        //           "Accident Death or Partial Disability"
        //         )
        //       ) {
        //         return allDataObj?.[title]
        //           ?.filter((itemx) => {
        //             return itemx?.key !== "claimRespectivetravel";
        //           })
        //           ?.filter((itemx) => {
        //             return accidentDeathOrPartialDisabilityFieldList?.includes(
        //               itemx?.key
        //             );
        //           })
        //           ?.map((item) => {
        //             return { ...item, value: itemz?.[item?.key] };
        //           });
        //       }
        //     });
        //   });
        //   // customData = [
        //   //   [{},{},{}],
        //   //   [{},{},{},{},{}],
        //   //   [{},{},{},{}],
        //   // ]
        // }
        else if (title === TRAVEL_DETAILS) {
          function getFieldList(incidentType) {
            return fieldLists[incidentType] || [];
          }

          summaryCardData[title] = allDataObj?.[title]
            ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
            ?.map((item) => {
              return { ...item, value: summaryData?.[item?.key] };
            });

          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].customData) {
            summaryCardData[title].customData = [];
          }

          summaryCardData[title].customData.push(
            allDataObj?.[title]
              ?.filter((itemx) => itemx?.key === "claimRespectivetravel")
              ?.map((item) => {
                return { ...item, value: summaryData?.[item?.key] };
              })
          );
          const selectedTravelClaim =
            summaryData?.claimRespectivetravel?.split("|");

          const selectedFields = new Set();

          selectedTravelClaim?.forEach((incidentType) => {
            const fieldList = getFieldList(incidentType);
            fieldList?.forEach((field) => selectedFields.add(field));
          });

          Object.keys(expenseSummary)?.forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].customData = data?.map((itemz) => {
              return allDataObj?.[title]
                ?.filter((itemx) => itemx?.key !== "claimRespectivetravel")
                ?.filter((itemx) => selectedFields.has(itemx?.key))
                ?.map((item) => {
                  return { ...item, value: itemz?.[item?.key] };
                });
            });
          });
        } else if (
          title !== EXPENSE_DETAILS &&
          title !== LOSS_ITEM_DETAILS &&
          title !== CARGO_DETAILS
          // uncomment this if want multiple travel insured member in view
          // title !== TRAVEL_INSURED_MEMBER
        ) {
          summaryCardData[title] = allDataObj?.[title]?.map((item) => {
            return { ...item, value: summaryData?.[item?.key] };
          });
        } else {
          if (!summaryCardData[title]) {
            summaryCardData[title] = {};
          }
          if (!summaryCardData[title].arrayData) {
            summaryCardData[title].arrayData = [];
          }
          Object.keys(expenseSummary).forEach(() => {
            const data = expenseSummary;
            summaryCardData[title].arrayData = data?.map((itemz) => {
              return allDataObj?.[title]?.map((item) => {
                return { ...item, value: itemz?.[item?.key] };
              });
            });
          });
        }
      }
    });
  }

  const handleFieldEdit = async (title, state, index) => {
    setEditCard((prevValue) => {
      let newEditValues = {};

      for (let key in prevValue) {
        newEditValues[key] = false;
      }

      return {
        ...newEditValues,
        [title]: state,
      };
    });

    setActiveStep(index);

    const updatedState = {};
    for (const key in policyViewCards) {
      updatedState[key] = key == index ? false : true;
    }
    dispatch(setPolicyViewCards(updatedState));
  };

  console.log(cardTitlesPrevPolicyToggle, "cardTitlesPrevPolicyToggle");
  return (
    <>
      <ContainerStyled>
        <ClaimForm
          lobId={lobId}
          extraCardTitles={extraCardTitles}
          masterTableData={masterTableData}
          updateExistingData={updateExistingData}
          generateMongoId={generateMongoId}
          proposalData={proposalData}
          isProposalDataLoading={isProposalDataLoading}
          isLoading={isLoading}
          isCreateProposalDataLoading={isCreateProposalDataLoading}
          isUpdateExistingProposalDataLoading={
            isUpdateExistingProposalDataLoading
          }
          getModalData={getModalData}
          getVersionData={getVersionData}
          getFuelData={getFuelData}
          manufacturerData={manufacturerData}
          modelData={modelData}
          versionData={versionData}
          fuelData={fuelData}
          editCard={editCard}
          setEditCard={setEditCard}
          activeStep={activeStep}
          setActiveStep={setActiveStep}
          setCompleted={setCompleted}
          current={current}
          setCurrent={setCurrent}
          completed={completed}
          isFirstView={isFirstView}
          setIsFirstView={setIsFirstView}
          mergedSchema={mergedSchema}
          setMergedSchema={setMergedSchema}
          extraProposalCreate={extraProposalCreate}
          mandatoryFields={mandatoryFields}
          dropdownFields={dropdownFields}
          multiSelectFields={multiSelectFields}
          getOptionKeyByValue={getOptionKeyByValue}
          getPolicyKeyByValue={getPolicyKeyByValue}
          setCurrentCardIndex={setCurrentCardIndex}
          getMap={getMap}
          dateFields={dateFields}
          // checkPolicyPresent={checkPolicyPresent}
          isPolicyPresent={isPolicyPresent}
          isPrevPolicyPresent={isPrevPolicyPresent}
          checkPrevPolicyPresent={checkPrevPolicyPresent}
          fileFields={fileFields}
          members={members}
          setMembers={setMembers}
          optionalFields={optionalFields}
          setUserId={setUserId}
          setSubmittedAt={setSubmittedAt}
          matchedObject={matchedObject}
          policyDetails={policyDetails}
          policyPresent={policyPresent}
          dateAndTimeFields={dateAndTimeFields}
          lobName={lobName}
          isLastTitleInCurrentStep={isLastTitleInCurrentStep}
          currentURL={currentURL}
          buttonRef={buttonRef}
          finalSubmit={finalSubmit}
          cardTitlesPrevPolicyToggle={cardTitlesPrevPolicyToggle}
          currentStepTitle={currentStepTitle}
          setAdditionalFileFields={setAdditionalFileFields}
        />
      </ContainerStyled>
      {console.log("summaryCardData", summaryCardData)}
      {!isLoading && isLastTitleInCurrentStep && (
        <>
          <Box
            style={{
              // border: "1px solid red",
              height: "460px",
              overflowY: "auto",
            }}
          >
            {cardTitlesPrevPolicyToggle?.map((value, outerIndex) => {
              return Object.entries(summaryCardData)?.map(
                ([key, ObjValue], innerIndex) => {
                  console.log("ObjValue", ObjValue, value === key, value, key);
                  return (
                    value === key && (
                      <ViewPolicyEntrySummary
                        key={innerIndex}
                        title={key}
                        data={ObjValue}
                        index={outerIndex}
                        handleFieldEdit={handleFieldEdit}
                        editCard={editCard}
                        summaryData={summaryData}
                        lobId={lobId}
                        additionalFields={additionalFileFields}
                      />
                    )
                  );
                }
              );
            })}
          </Box>
          <ButtonContainerStyled>
            <Button
              type="submit"
              ref={buttonRef}
              themeType={"theme1"}
              // bgColor={"#11555F"}
              onClick={finalSubmit}
            >
              {currentURL === "/raise-claims" ? "Submit" : "Update"}
            </Button>
            {/* <Button
              themeType={"theme1"}
              bgColor={"#11555F"}
              onClick={() => setActiveStep(cardTitlesPrevPolicyToggle?.length)}
            >
              Back
            </Button> */}
          </ButtonContainerStyled>
        </>
      )}
    </>
  );
};

export default Claim;

const ContainerStyled = styled.div`
  padding-bottom: 30px;
`;

const ButtonContainerStyled = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { TextField, Tooltip } from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Controller } from "react-hook-form";
import Typography from "../../Typography/Typography";
import { CalendarIcon, ClearIcon } from "../../date-picker/DatePicker";
import { CustomLabel, DatePickerWrapper } from "../input/style";
import { useGetConditionalErrorMessage } from "../../../hooks/useGetConditionalErrorMessage";
// import { StyledValue } from "../../../modules/Proposal-Journey/Proposal-Listing/ProposalListingView";
import { useSelector } from "react-redux";
import styled from "styled-components";

export default function DateAndTimePicker({
  name,
  label,
  id,
  control,
  userForm,
  proposalRead,
  readOnly,
  placeholder = "Enter date",
  minDate,
  maxDate,
  testId,
  isRequired,
  customView,
  description,
  errors,
  watch,
  claim,
  ...otherProps
}) {
  const { theme } = useSelector(({ theme }) => theme);

  const { Error } = Typography;

  const { errorMessage } = useGetConditionalErrorMessage({
    errors,
    id,
  });

  const fieldValue = watch && watch(id);

  return (
    <DatePickerWrapper
      proposalRead={proposalRead}
      errorMessage={errorMessage}
      claim={claim}
    >
      <CustomLabel htmlFor={id} theme={theme} claim={claim}>
        {label}
        {isRequired ? <span className="isRequired">*</span> : null}
        {description && !proposalRead && (
          <Tooltip title={description} arrow placement="top">
            <InfoOutlinedIcon
              className="info"
              sx={{
                fontSize: "12px",
                marginLeft: "3px",
                position: "relative",
                top: "2px",
              }}
            />
          </Tooltip>
        )}
      </CustomLabel>
      {proposalRead ? (
        <StyledValue>{fieldValue || "- - -"}</StyledValue>
      ) : (
        <>
          <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange } }) => (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DateTimePicker
                  id={id}
                  name={name}
                  control={control}
                  minDate={minDate}
                  maxDate={maxDate}
                  readOnly={readOnly}
                  views={
                    customView
                      ? customView
                      : ["year", "month", "day", "hours", "minutes"]
                  }
                  format="DD/MM/YYYY hh:mm a"
                  value={value}
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: null,
                  }}
                  slotProps={{
                    textField: {
                      placeholder: placeholder ? placeholder : "",
                      "data-testid": testId,
                    },
                    field: { clearable: !proposalRead },
                  }}
                  slots={{ openPickerIcon: CalendarIcon, clearIcon: ClearIcon }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name={name}
                      data-testid={testId}
                      helperText={params.error && "Please provide a valid date"}
                    />
                  )}
                  onChange={onChange}
                  {...otherProps}
                />
              </LocalizationProvider>
            )}
          />
          {!!errorMessage && <Error>{errorMessage}</Error>}
        </>
      )}
    </DatePickerWrapper>
  );
}

export const StyledValue = styled("span")({
  gridArea: "1 / 1 / 2 / 3",
  maxWidth: "100%",
  overflow: "hidden",
  whiteSpace: "pre-line",
  color: "#000c",
  marginLeft: "3px",
  marginRight: "2px",
  boxSizing: "border-box",
  fontWeight: "600",
  fontSize: "14px",
  display: "inline-block",
  wordWrap: "break-word",
});

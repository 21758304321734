import _ from "lodash";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import ErrorBoundary from "../../hoc/ErrorBoundary";

const Authentication = () => {
  const token = sessionStorage.getItem("token");

  if (_.isEmpty(token)) {
    return <Navigate to="/login" />;
  } else {
    return (
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    );
  }
};

export default Authentication;

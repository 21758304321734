import React from "react";
import { Box, Card, CardContent, Skeleton } from "@mui/material";

export default function CardSkeleton({ extra }) {
  return (
    <Box>
      {Array(5)
        .fill("")
        .map((_, index) => (
          <Card key={index} variant="outlined" sx={{ mb: 2 }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Skeleton variant="rectangular" width={83.15} height={45} />
                <Box ml={2}>
                  <Skeleton variant="text" width={112} height={36} />
                  <Skeleton variant="text" width={100} height={20} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignContent: "center",
                  width: "70%",
                  flexWrap: "wrap",
                  flexGrow: 1,
                }}
                mt={2}
              >
                {Array(5)
                  .fill("")
                  .map((_, subIndex) => (
                    <Skeleton
                      key={subIndex}
                      sx={{ margin: "0px 5px 0px 0px" }}
                      variant="text"
                      height={50}
                      width={120}
                    />
                  ))}
              </Box>
              {extra && (
                <Box mt={2} display="flex" justifyContent="space-between">
                  <Skeleton variant="rectangular" width={120} height={20} />
                  <Skeleton variant="rectangular" width={120} height={20} />
                </Box>
              )}
            </CardContent>
          </Card>
        ))}
    </Box>
  );
}

import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    id: null,
    proposalCreateValues: [],
    proposalEditValues: [],
    proposalError: null,
    proposalSuccess: null,
    proposalPartialUpdateSuccess: false,
    uploadedFileURL: {},
    isPrevPolicyVisible: false,
    inactivePolicyEntrySuccess: false,
    inactivePolicyEntryError: false,
    brokerage: {
      companyId: "",
      transactionTypeId: "",
      transactionId: "",
      userId: "",
    },
    ocrLoader: null,
    policyEditIndex: 0,
    policyViewCards: {},
  },
  reducers: {
    setProposalPartialUpdateSuccess: (state, action) => {
      state.proposalPartialUpdateSuccess = action.payload;
    },
    setIdAction: (state, action) => {
      state.id = action.payload;
    },
    setInactivePolicyEntrySuccessAction: (state, { payload }) => {
      state.inactivePolicyEntrySuccess = payload;
      state.inactivePolicyEntryError = null;
    },
    setInactivePolicyEntryErrorAction: (state, { payload }) => {
      state.inactivePolicyEntryError = serializeError(payload);
      state.inactivePolicyEntrySuccess = null;
    },
    setProposalCreateValuesAction: (state, action) => {
      if (action.payload === "reset") {
        state.proposalCreateValues = [];
      } else {
        state.proposalCreateValues = [
          { ...state.proposalCreateValues?.[0], ...action.payload },
        ];
      }
    },
    setProposalEditValuesAction: (state, action) => {
      state.proposalEditValues = [
        { ...state.proposalEditValues, ...action.payload },
      ];
    },
    setProposalSuccessAction: (state, { payload }) => {
      state.proposalSuccess = payload;
      state.proposalError = null;
    },
    setProposalErrorAction: (state, { payload }) => {
      state.proposalError = serializeError(payload);
      state.proposalSuccess = null;
    },
    setBrokerage: (state, { payload }) => {
      state.brokerage = { ...state.brokerage, ...payload };
    },
    setUploadedFileURLAction: (state, { payload }) => {
      console.log("payload", payload);
      state.uploadedFileURL = { ...state.uploadedFileURL, ...payload };
    },
    setIsPrevPolicyVisibleAction: (state, { payload }) => {
      state.isPrevPolicyVisible = payload;
    },
    setOcrLoader: (state, { payload }) => {
      state.ocrLoader = payload;
    },
    setPolicyEditIndex: (state, { payload }) => {
      state.policyEditIndex = payload;
    },
    setPolicyViewCards: (state, { payload }) => {
      state.policyViewCards = payload;
    },
    resetAllData: (state) => {
      state.id = null;
      state.proposalCreateValues = [];
      state.proposalEditValues = [];
      state.proposalError = null;
      state.proposalSuccess = null;
      state.proposalPartialUpdateSuccess = false;
      state.uploadedFileURL = {};
      state.isPrevPolicyVisible = false;
      state.inactivePolicyEntrySuccess = false;
      state.inactivePolicyEntryError = false;
      state.brokerage = {
        companyId: "",
        transactionTypeId: "",
        transactionId: "",
        userId: "",
      };
      state.ocrLoader = null;
      state.policyEditIndex = 0;
      state.policyViewCards = {};
    },
  },
});

export const {
  setProposalPartialUpdateSuccess,
  setProposalCreateValuesAction,
  setProposalEditValuesAction,
  setIdAction,
  setProposalSuccessAction,
  setProposalErrorAction,
  setBrokerage,
  setUploadedFileURLAction,
  setIsPrevPolicyVisibleAction,
  setInactivePolicyEntryErrorAction,
  setInactivePolicyEntrySuccessAction,
  setOcrLoader,
  setPolicyEditIndex,
  setPolicyViewCards,
  resetAllData,
} = proposalSlice.actions;
export default proposalSlice.reducer;

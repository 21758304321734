import { createSlice } from "@reduxjs/toolkit";
import { serializeError } from "../../utils";

const customer = createSlice({
  name: "customer",
  initialState: {
    customerError: null,
    customerSuccess: null,
    memberFormDetails: [],
    memberCount: [],
    customerSearchInputData: {},
  },
  reducers: {
    setCustomerSuccessAction: (state, { payload }) => {
      state.customerSuccess = payload;
      state.customerError = null;
    },
    setCustomerErrorAction: (state, { payload }) => {
      state.loading = null;
      state.customerError = serializeError(payload);
      state.customerSuccess = null;
    },
    setFamilyMemberDetails: (state, { payload }) => {
      // const newCount = state.memberCountArr[state.memberCountArr.length - 1];
      state.memberFormDetails = payload;
    },
    setMemberCount: (state, { payload }) => {
      state.memberCount = payload;
    },
    removeMemberCount: (state, { payload }) => {
      // const newCount=memberCountArr[memberCountArr.length-1];
      state.memberFormDetails.pop();
    },
    setCustomerSearchInput: (state, { payload }) => {
      state.customerSearchInputData = payload;
    },
  },
});

export const {
  setCustomerSuccessAction,
  setCustomerErrorAction,
  setFamilyMemberDetails,
  removeMemberCount,
  setMemberCount,
  setCustomerSearchInput,
} = customer.actions;
export default customer.reducer;

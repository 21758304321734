import { BiSolidFileJpg, BiSolidFilePdf, BiSolidFilePng } from "react-icons/bi";

//Function to display file icon based on extension
export const setfileIcon = (data) => {
  if (data.includes(".jpg") || data.includes(".jpeg")) {
    return (
      <BiSolidFileJpg
        style={{ height: "40px", width: "36px", color: "#E28500" }}
      />
    );
  } else if (data.includes(".pdf")) {
    return (
      <BiSolidFilePdf
        style={{ height: "40px", width: "36px", color: "#CB0606" }}
      />
    );
  } else if (data.includes(".png")) {
    return (
      <BiSolidFilePng
        style={{ height: "40px", width: "36px", color: "#D15AE3" }}
      />
    );
  } else {
    return null;
  }
};

export const trimFileName = (data) => {
  const parts = data.split("/");
  const lastPart = parts[parts.length - 1];

  let trimValue = "";
  if (lastPart.length > 20) {
    trimValue = lastPart.slice(0, 20) + "...";
    return trimValue;
  } else {
    return data;
  }
};

export const formatUploadTime = (data) => {
  const part = data.split("T");
  const [date, time] = part;
  const [year, month, day] = date.split("-");
  const [hours, minutes] = time.split(":");
  const ampm = hours >= 12 ? "PM" : "AM";
  const hour = hours % 12 || 12;
  const formattedDate = `${day}-${month}-${year}`;
  const formattedTime = `${hour}:${minutes} ${ampm}`;
  return `${formattedDate} ${formattedTime}`;
};

import { useMutation, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";
import Swal from "sweetalert2";

export const useSupportRequestCustomer = () => {
  //   const queryClient = useQueryClient();
  const { data, mutate, isLoading, isError, isFetching } = useMutation(
    (payload) => {
      return httpClient("SupportRequestCustomer/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (response) => {
        Swal.fire({
          position: "center",
          icon: response.success ? "success" : "error",
          title: response?.message,
          showConfirmButton: false,
          timer: 1500
        });
      }
    }
    // {
    //   onSuccess: (data) => {
    //     queryClient.invalidateQueries(["key"]);
    //   },
    // }
  );
  return { data, mutate, isLoading, isError };
};

export const useGetQueryTypeMaster = () => {
  const { data, mutate, isLoading, isError, isFetching } = useMutation(
    (payload) => {
      return httpClient("query_type_master/listing", {
        method: "POST",
        data: payload,
      });
    }
  );
  return { data, mutate, isLoading, isError };
};

export const useGetAllPolicyNumbers = () => {
  const { data } = useQuery(["policyNumber"], () => {
    return httpClient(`policy_master/listPaging`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          lobId: 0,
          policynumber: "",
          bookDateTo: "",
          bookDateFrom: "",
          status: "",
        },
      },
    });
  });
  return { data };
};

import React from "react";
import styled from "styled-components";

const ProposalShrink = React.forwardRef(({ title }, ref) => {
  return (
    <ProposalShrinkStyled ref={ref}>
      <p>{title}</p>
    </ProposalShrinkStyled>
  );
});
ProposalShrink.displayName = "ProposalShrink";
export default ProposalShrink;

export const ProposalShrinkStyled = styled.div`
  display: flex;
  padding: 20px 16px;
  border-radius: 4px;
  background: #fff;
  margin-bottom: 16px;
  p {
    margin: 0;
    color: #178e80;
    
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.15px;
  }
`;

import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import {
  useGetFuelData,
  useGetManufacturerData,
  useGetModelDataForCustomer,
  useGetVersionDataForCustomer,
} from "../service";
import useGetLOBMaster from "../../../hooks/useGetLOBMaster";
import {
  allCapitalize,
  allowOnlyAlphabetsNumberNoSpace,
  chassisNumber,
  engineNumber,
} from "../../../HelperFunctions/HelperFunctions";

const VehicleForm = ({
  formKey,
  watch,
  control,
  register,
  setValue,
  vehicleData,
  reset,
  errors,
  trigger,
}) => {
  // const { register, formState: { errors }, handleSubmit, watch, control } = useForm();
  // console.log('vehicleData', vehicleData, formKey)
  const selectedLob = watch(`${formKey}.lob_id`);
  const selectedManufacturer = watch(`${formKey}.make`);
  const selectedModel = watch(`${formKey}.model`);
  const selectedVariant = watch(`${formKey}.version`);

  const { data: LOBData } = useGetLOBMaster();
  const LOB_DATA = LOBData?.data?.data;
  const LOB_OBJ = LOB_DATA?.filter((item) =>
    ["Car", "Bike", "GCV", "PCV"].includes(item?.lob)
  )?.map((filteredItem) => {
    return {
      label: filteredItem?.lob,
      value: filteredItem?.lob_id,
    };
  });
  const selectedLobObject = LOB_OBJ?.filter(
    (item) => item?.value === selectedLob
  );
  // console.log(selectedLob)
  const { data: manufacturerData } = useGetManufacturerData(selectedLob);
  const ManufacturerData = manufacturerData?.data;

  const ManufacturerObj = ManufacturerData?.map((item) => {
    return {
      label: item?.manf_name,
      value: item?.manf_id,
    };
  });

  const { mutate: getModel, data: modelData } = useGetModelDataForCustomer();
  const ModelData = modelData?.data;
  const modelObj = Array.isArray(ModelData)
    ? ModelData.map((item) => {
        return {
          label: item?.model_name,
          value: item?.model_id,
        };
      })
    : [];

  const { mutate: getVersion, data: versionData } =
    useGetVersionDataForCustomer();
  const VersionData = versionData?.data;
  const versionObj = Array.isArray(VersionData)
    ? VersionData?.map((item) => {
        return {
          label: item?.version_name,
          value: item?.version_id,
        };
      })
    : [];

  const { mutate: getFuelData, data: fuelData } = useGetFuelData();
  const FuelObj = fuelData?.data;

  const handleChange = (fieldName) => (event) => {
    // preventWhiteSpaceInInput(event)
    setTimeout(() => {
      trigger(`${formKey}.${fieldName}`);
    }, 100);
    if (fieldName === "chassisNumber") {
      allowOnlyAlphabetsNumberNoSpace(event);
      allCapitalize(event);
      setValue(`${formKey}.${fieldName}`, event.target.value);
      return;
    }
    setValue(`${formKey}.${fieldName}`, event.target.value);
  };

  useEffect(() => {
    if (selectedManufacturer && selectedLob) {
      getModel({ lobid: selectedLob, manufacturer_id: selectedManufacturer });
    }
  }, [selectedManufacturer, selectedLob]);

  useEffect(() => {
    if (selectedModel && selectedLob) {
      getVersion({ lobid: selectedLob, model_id: selectedModel });
      getFuelData({ lobid: selectedLob, model_id: selectedModel });
    }
  }, [selectedModel, selectedLob]);

  useEffect(() => {
    setValue(`${formKey}.lob`, selectedLobObject[0]?.label);
  }, [selectedLob]);
  useEffect(() => {
    reset({
      [formKey]: {
        lob: vehicleData?.lob,
        lob_id: vehicleData?.lobId,
        make: vehicleData?.make,
        model: vehicleData?.model,
        version: vehicleData?.version,
        fueltype: vehicleData?.fueltype,
        kwh: vehicleData?.kwh,
        seatingCapacity: vehicleData?.seatingCapacity,
        engineNumber: vehicleData?.engineNumber,
        chassisNumber: vehicleData?.chassisNumber,
      },
    });
  }, [formKey, vehicleData, reset]);

  if (!watch(formKey)) return null;

  // const chassiswatch = watch(`${formKey}.chassisNumber`);
  // console.log("chassiswatch", chassiswatch);
  // const onNameChange = async () => {
  //   const valid = await trigger(`${formKey}.chassisNumber`);
  //   console.log("valid", valid);
  //   if (!valid) {
  //     // @todo: bug here? valid only correct after submitting
  //     return;
  //   }
  // };

  return (
    <Grid container xs={12} spacing={2} paddingX={3} paddingY={2}>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "bold",
              transform: "unset",
              fontSize: "0.75rem",
            }}
          >
            LOB <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name={`${formKey}.lob_id`}
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                // {...register(`${formKey}.lob_id`)}
                value={field.value}
                onChange={handleChange("lob_id")}
                style={{ paddingTop: "2px" }}
                variant="standard"
                autoWidth
                placeholder="Select"
                label="LOB"
              >
                {LOB_OBJ?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {errors[formKey]?.["lob_id"] && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>
              {errors[formKey]["lob_id"].message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "bold",
              transform: "unset",
              fontSize: "0.75rem",
            }}
          >
            Manufacturer <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name={`${formKey}.make`}
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                // {...register(`${formKey}.make`)}
                value={field.value}
                onChange={handleChange("make")}
                style={{ paddingTop: "2px" }}
                variant="standard"
                autoWidth
                placeholder="Select"
                label="Manufacturer"
              >
                {ManufacturerObj?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {errors[formKey]?.["make"] && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>
              {errors[formKey]["make"].message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "bold",
              transform: "unset",
              fontSize: "0.75rem",
            }}
          >
            Model <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name={`${formKey}.model`}
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                // {...register(`${formKey}.model`)}
                onChange={handleChange("model")}
                value={field.value}
                style={{ paddingTop: "2px" }}
                variant="standard"
                autoWidth
                placeholder="Select"
                label="Model"
              >
                {modelObj?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {errors[formKey]?.["model"] && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>
              {errors[formKey]["model"].message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "bold",
              transform: "unset",
              fontSize: "0.75rem",
            }}
          >
            Variant <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name={`${formKey}.version`}
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                // {...register(`${formKey}.version`)}
                onChange={handleChange("version")}
                value={field.value}
                style={{ paddingTop: "2px" }}
                variant="standard"
                autoWidth
                placeholder="Select"
                label="Variant"
              >
                {versionObj?.map((item) => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {errors[formKey]?.["version"] && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>
              {errors[formKey]["version"].message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth>
          <InputLabel
            style={{
              color: "black",
              fontWeight: "bold",
              transform: "unset",
              fontSize: "0.75rem",
            }}
          >
            Fuel Type <span style={{ color: "red" }}>*</span>
          </InputLabel>
          <Controller
            name={`${formKey}.fueltype`}
            control={control}
            render={({ field }) => (
              <CustomSelect
                {...field}
                // {...register(`${formKey}.fueltype`)}
                onChange={handleChange("fueltype")}
                value={field.value}
                style={{ paddingTop: "2px" }}
                variant="standard"
                autoWidth
                placeholder="Select"
                label="Fuel"
              >
                {FuelObj?.map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </CustomSelect>
            )}
          />
          {errors[formKey]?.["fueltype"] && (
            <p style={{ color: "red", fontSize: "0.75rem" }}>
              {errors[formKey]["fueltype"].message}
            </p>
          )}
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          {...register(`${formKey}.kwh`)}
          onChange={handleChange("kwh")}
          size="medium"
          variant="standard"
          type={"number"}
          label={
            <>
              CC/Kwh <span style={{ color: "red" }}>*</span>
            </>
          }
          //   label={`CC/Kwh ${(<span style={{ color: "red" }}>*</span>)}`}
          name={`${formKey}.kwh`}
          InputLabelProps={{ shrink: true }}
          value={watch(`${formKey}.kwh`)}
          fullWidth
          InputProps={{
            inputProps: {
              style: {
                MozAppearance: "textfield",
              },
            },
            sx: {
              "& input[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "&::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            },
          }}
        />
        {errors[formKey]?.["kwh"] && (
          <p style={{ color: "red", fontSize: "0.75rem" }}>
            {errors[formKey]["kwh"].message}
          </p>
        )}
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          {...register(`${formKey}.seatingCapacity`)}
          onChange={handleChange("seatingCapacity")}
          size="medium"
          variant="standard"
          type={"number"}
          label={"Seating Capacity"}
          name={`${formKey}.seatingCapacity`}
          InputLabelProps={{ shrink: true }}
          value={watch(`${formKey}.seatingCapacity`)}
          fullWidth
          InputProps={{
            inputProps: {
              style: {
                MozAppearance: "textfield",
              },
            },
            sx: {
              "& input[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "&::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            },
          }}
        />
        {errors[formKey]?.["seatingCapacity"] && (
          <p style={{ color: "red", fontSize: "0.75rem" }}>
            {errors[formKey]["seatingCapacity"].message}
          </p>
        )}
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          {...register(`${formKey}.engineNumber`)}
          onChange={
            // handleChange('engineNumber')
            (e) => engineNumber(e)
          }
          size="medium"
          variant="standard"
          type={"text"}
          label={"Engine Number"}
          name={`${formKey}.engineNumber`}
          InputLabelProps={{ shrink: true }}
          // value={watch(`${formKey}.engineNumber`)}
          fullWidth
          InputProps={{
            inputProps: {
              style: {
                MozAppearance: "textfield",
              },
            },
            sx: {
              "& input[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "&::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            },
          }}
        />
        {errors[formKey]?.["engineNumber"] && (
          <p style={{ color: "red", fontSize: "0.75rem" }}>
            {errors[formKey]["engineNumber"].message}
          </p>
        )}
      </Grid>
      <Grid item xs={3}>
        <StyledTextField
          {...register(`${formKey}.chassisNumber`)}
          // onChange={(e) => {
          //   allowOnlyAlphabetsNumberNoSpace(e);
          //   allCapitalize(e);
          //   handleChange("chassisNumber");
          // }}
          // onChange={(e) => onNameChange(e)}
          onChange={handleChange("chassisNumber")}
          maxlength={"25"}
          size="medium"
          variant="standard"
          type={"text"}
          control={control}
          label={"Chassis Number"}
          name={`${formKey}.chassisNumber`}
          InputLabelProps={{ shrink: true }}
          // value={watch(`${formKey}.chassisNumber`)}
          fullWidth
          InputProps={{
            inputProps: {
              style: {
                MozAppearance: "textfield",
              },
            },
            sx: {
              "& input[type=number]": {
                MozAppearance: "textfield",
                "&::-webkit-outer-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
                "&::-webkit-inner-spin-button": {
                  WebkitAppearance: "none",
                  margin: 0,
                },
              },
            },
          }}
        />
        {errors[formKey]?.["chassisNumber"] && (
          <p style={{ color: "red", fontSize: "0.75rem" }}>
            {errors[formKey]["chassisNumber"].message}
          </p>
        )}
      </Grid>
      <input
        type="hidden"
        {...register(`${formKey}.lob`)}
        name={`${formKey}.lob`}
        value={""}
      ></input>
      {/* <StyledTextField type='hidden' hidden {...register(`${formKey}.lob`)} value={selectedLob?.label}>
            </StyledTextField> */}
    </Grid>
  );
};

export default VehicleForm;

const CustomSelect = styled(Select)(() => ({
  "& .MuiSelect-select": {
    color: "#454545",
  },
}));

const StyledTextField = styled(TextField)({
  "& .MuiInputBase-root": {
    backgroundColor: "transparent",
    color: "#454545",
  },
  "& .MuiInputLabel-root": {
    fontWeight: "bold",
    color: "black",
  },
});

import { useQuery } from "@tanstack/react-query";
import HttpClient from "../api/httpClient";

const useGetLOBMaster = () => {
  const { data, isFetching } = useQuery({
    queryKey: ["LOB"],
    queryFn: () => HttpClient("lob/list"),
  });

  return { data, isFetching };
};

export default useGetLOBMaster;

export const useGetLobById = (id) => {
  const { data } = useQuery({
    queryKey: ["lobById", id],
    queryFn: () => HttpClient(`/common_master/lob/list/${id}`),
    enabled: id ? true : false,
  });

  return { data };
};

import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const DoughnutChart = () => {
  const data = {
    labels: [
      "Motor",
      "Fire",
      "Liability",
      "Health",
      "Travel",
      "Crop",
      "Cattle",
      "Marine",
      "Misc",
    ],
    datasets: [
      {
        label: "poll",
        data: [
          420000, 470000, 390000, 500000, 495000, 520000, 420000, 370000,
          390000,
        ],
        backgroundColor: [
          "#5042A7",
          "#F9AE2B",
          "#F92B5C",
          "#DBC60D",
          "#36C9C0",
          "#D202BE",
          "#8B2781",
          "#937590",
          "#471F43",
          "#EEEEEE",
        ],
        borderColor: [
          "#5042A7",
          "#F9AE2B",
          "#F92B5C",
          "#DBC60D",
          "#36C9C0",
          "#D202BE",
          "#8B2781",
          "#937590",
          "#471F43",
          "#EEEEEE",
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    responsive: true,
    legend: {
      display: true,
      position: "right",
    },

    plugins: {
      labels: {
        render: "percentage",
      },
      margin: "100px",
      legend: {
        position: "right",
      },
    },
    title: {
      display: true,
      fontSize: 20,
      paddingLeft: 20,
      text: "Tickets",
    },
  };
  return (
    <div style={{ width: "auto", height: "250px", marginLeft: "20px" }}>
      <Doughnut data={data} options={options} />
    </div>
  );
};

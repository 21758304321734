import { Container, Grid, Typography } from "@mui/material";
import React from "react";
import vectorSVG from "./styles/Vector.svg";
import partyHornSVG from "./styles/party-horn 1.svg";
import golfHoleSVG from "./styles/golf-hole 2.svg";
import { formatDate } from "../../HelperFunctions/HelperFunctions";
import moment from "moment";
import { useGetNotification } from "./service";
import { isEmpty } from "lodash";

const randomBackgroundColors = ["#FF3A29", "#D0121E", "rgb(245 93 4)"];
const randomBackgroundIcons = [golfHoleSVG, partyHornSVG];

const Notification = () => {
  const { data } = useGetNotification();
  // console.log('data', data)

  // This converts the ScheduledAt to proper date.
  const parsedData = data.map((item) => ({
    ...item,
    scheduledAt: new Date(item.scheduledAt),
  }));

  // Sorts the Date
  const sortedData = parsedData.sort((a, b) => b.scheduledAt - a.scheduledAt);

  // Creates an Object of Datelabel and Message.
  const groupedData = sortedData.reduce((acc, curr) => {
    const dateLabel = formatDate(curr.scheduledAt);

    // If the date label does not exist in the accumulator, It adds a new key to it.
    if (!acc[dateLabel]) {
      acc[dateLabel] = [];
    }
    // If key exists we push the message to that key's array.
    acc[dateLabel].push(curr.content);

    return acc;
  }, {});

  const notificationData = {
    "total records": 1,
    "data": [
      {
        "scheduledCommunicationsAudiencesId": 15482,
        "content": "<message>",
        "scheduledAt": "2024-06-21T06:03:37.344+00:00"
      }
    ],
    "message": "Fetched Notifications",
    "status": true
  }

  const parsedNotificationData = notificationData?.data?.map(item => ({
    ...item,
    scheduledAt: new Date(item?.scheduledAt)
  }))

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "20px",
      }}
    >
      {!isEmpty(groupedData) ? (
        Object.entries(groupedData)?.map((notification) => (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Grid
              container
              lg={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // borderBottom: "1px solid #CBCBCB",
                padding: "8px 0px",
              }}
            >
              <Typography variant="h6" fontSize={16} color="#4373EB">
                {notification[0]}
              </Typography>
            </Grid>
            {notification[1].map((event) => (
              <Grid
                container
                lg={12}
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "12px",
                  //   borderBottom: "1px solid #F4F4F4",
                  //   padding: "8px 0px",
                  //   backgroundColor:"#FFFFFF"
                }}
              >
                <Grid
                  container
                  lg={12}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderBottom: "1px solid #F4F4F4",
                    padding: "8px 0px",
                    backgroundColor: "transparent",
                    borderRadius: "8px",
                  }}
                >
                  <Grid lg={1} xs={3}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        backgroundColor: `${randomBackgroundColors[Math.floor(Math.random() * 3)]
                          }`,
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        // position:"relative",
                        margin: "auto",
                      }}
                    >
                      <img
                        src={
                          randomBackgroundIcons[Math.floor(Math.random() * 2)]
                        }
                      />
                    </div>
                  </Grid>
                  <Grid lg={11} xs={9}>
                    <Typography fontWeight={400} fontSize={16} color="#393939">
                      {event}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          </Container>
        ))
      ) : (
        <Grid item lg={12} xs={12} sx={{ margin: "auto" }}>
          <Grid
            container
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              style={{
                objectFit: "contain",
                height: "50%",
                width: "50%",
              }}
              src="/assets/layout/no-notification.png"
            ></img>
          </Grid>
          <Grid
            item
            lg={12}
            xs={12}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h3>No Notifications !</h3>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export default Notification;

import React, { useEffect, useState } from "react";
import {
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  FormControl,
  MenuItem,
  Select,
  InputLabel,
  Chip,
  Divider,
  LinearProgress,
  Avatar,
  Stack,
  Rating,
  Skeleton,
} from "@mui/material";
import SupportIcon from "@mui/icons-material/Support";
import PersonIcon from "@mui/icons-material/Person";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import DescriptionIcon from "@mui/icons-material/Description";
import styled from "styled-components";
import Claim from "./Claim";
import { useGetClaimCount, useGetClaimProcessDataById } from "./service";
import { useLocation } from "react-router-dom";
import { TfiHeadphoneAlt } from "react-icons/tfi";
import { BsPerson } from "react-icons/bs";
import { FaCheck, FaRegHospital } from "react-icons/fa";
import { HiOutlineDocumentDownload } from "react-icons/hi";
import { IoMdArrowDropdownCircle } from "react-icons/io";
import { themeObj } from "../../theme/theme";
import SkeletonLoader from "../../../components/SkeletonLoader/SkeletonLoader";

const Container = styled(Box)`
  display: flex;
  //   padding: 20px;
  gap: 20px;
  //   border-radius: 10px;
`;

const Sidebar = styled(Box)`
  width: 40%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 12px 16px 12px 16px;
  border-radius: 10px;
  background: #fbfbfb;
  //   border: 1px solid red;
`;

const Content = styled(Box)`
  width: 80%;
  background-color: #ffffff;
  border-radius: 24px;
  border: 1px solid #56565633;
  filter: drop-shadow(0px 0px 20px #0000001a);
  min-width: 500px;
`;

const Title = styled.h6`
  margin: 0;
  font-weight: 700;
  font-size: 24px;
  line-height: 32.78px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const SubTitle = styled.h4`
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  line-height: 24.59px;
  color: #959595;
`;

const Para = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 16.94px;
  color: ${({ theme }) => theme?.secondaryColor};
`;

const FeatureTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 21.86px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const FeatureSubTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: ${({ theme }) => theme?.secondaryColor};
`;

const FeatureLink = styled.a`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: ${({ theme }) => theme?.ascentColor};
  text-decoration: underline;
  cursor: pointer;
`;

const RatingMainBox = styled(Box)`
  padding: 8px 10px 8px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0px;
  border-radius: 10px;
  background: #eef2ff;
  border: 1px solid #c5dcff;
`;

const RatingTitle = styled.p`
  margin: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const RatingNumber = styled.p`
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  line-height: 27.32px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const RatingNumberTitle = styled.p`
  margin: 0;
  font-size: 11px;
  font-weight: 500;
  line-height: 13.31px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const StyledDocument = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px 9px 5px 9px;
  gap: 10px;
  border-radius: 7px;
  background: #ffffff;
  border: 0.8px solid #d9d9d9;
  font-size: 12px;
  font-weight: 600;
  line-height: 14px;
  color: ${({ theme }) => theme?.primaryColor};
  cursor: pointer;
`;

const DocumentReq = styled.div`
  margin: 0;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 14.52px;
  color: ${({ theme }) => theme?.primaryColor};
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const FormSection = styled(Box)`
  //   display: flex;
  flex-direction: column;
  gap: 20px;
`;

const StyledChip = styled(Chip)`
  // margin: 5px 0;
  border-radius: 15px;
`;

const ProgressContainer = styled(Box)`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  background-color: #e2e8f0 !important;
`;

const ClaimStats = styled.div`
  text-align: center;
  margin-left: 10px;
`;

const ClaimStatsBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  // width: 160px;
  // border: 1px solid red;
`;

const ClaimProcess = ({ isBasicDetailsLoading = true }) => {
  const theme = themeObj;

  const [open, setOpen] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lob");
  const transactionIdParams = queryParams.get("TransactionId");
  const {
    data: claimProcessData,
    mutate: claimProcess,
    isLoading,
  } = useGetClaimProcessDataById();
  const claimProcessDataToSet = claimProcessData?.data?.data;

  const payload = { lobId: lobId, transactionId: transactionIdParams };

  const {
    mutate: claimCount,
    data: claimCountData,
    isLoading: claimCountLoading,
  } = useGetClaimCount();

  const claimCountToSet = claimCountData?.data?.data;
  const approvedCount = claimCountToSet?.find(
    (item) => item?.claimStatus === "Approved"
  )?.total;

  const totalClaimCount = claimCountData?.data?.totalrecords;
  useEffect(() => {
    claimProcess(payload);
    claimCount({});
  }, [lobId, transactionIdParams]);

  const renderFeature = (feature) => {
    switch (feature.featureType) {
      case "STATIC":
        return (
          <Box style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            <StyledAvatar>
              <TfiHeadphoneAlt
                style={{ color: theme?.ascentColor, width: 21, height: 21 }}
              />
            </StyledAvatar>
            <Box>
              <FeatureTitle variant="body1">
                {feature?.featureTitle}
              </FeatureTitle>
              <FeatureSubTitle variant="body2">
                {feature?.featureDescription}
              </FeatureSubTitle>
            </Box>
          </Box>
        );
      case "RM_DETAILS":
        return (
          <Box style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            <StyledAvatar>
              <BsPerson
                style={{ color: theme?.ascentColor, width: 21, height: 21 }}
              />
            </StyledAvatar>
            <Box>
              <FeatureTitle variant="body1">
                {feature?.featureTitle}
              </FeatureTitle>
              <FeatureSubTitle variant="body2">
                {feature.firstName}
              </FeatureSubTitle>
              <FeatureSubTitle variant="body2">
                {feature.emailId}
              </FeatureSubTitle>
              <FeatureSubTitle variant="body2">
                {feature.mobileNo}
              </FeatureSubTitle>
            </Box>
          </Box>
        );
      case "HOSPITALS":
        return (
          <Box style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            <StyledAvatar>
              <FaRegHospital
                style={{ color: theme?.ascentColor, width: 21, height: 21 }}
              />
            </StyledAvatar>
            <Box>
              <FeatureTitle variant="body1">
                {feature?.featureTitle}
              </FeatureTitle>
              <FeatureSubTitle variant="body2">
                {feature.HospitalCount}+ Network Hospitals
              </FeatureSubTitle>
              <FeatureLink
                color="primary"
                href={feature.HospitalLink}
                target="_blank"
              >
                Know More
              </FeatureLink>
            </Box>
          </Box>
        );
      case "DOCUMENTS":
        return (
          <>
            <Box style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
              <StyledAvatar>
                <HiOutlineDocumentDownload
                  style={{ color: theme?.ascentColor, width: 21, height: 21 }}
                />
              </StyledAvatar>
              <Box>
                <FeatureTitle variant="body2">
                  {feature.featureTitle}
                </FeatureTitle>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "10px",
                  }}
                >
                  {feature?.policyDoc && (
                    <StyledDocument
                      onClick={() => window.open(feature?.policyDoc, "_blank")}
                    >
                      <DescriptionIcon
                        style={{ color: "red", fontSize: "20px" }}
                      />
                      <span>Policy Document</span>
                    </StyledDocument>
                  )}
                  {feature?.claimform && (
                    <StyledDocument
                      onClick={() => window.open(feature?.claimform, "_blank")}
                    >
                      <DescriptionIcon
                        style={{ color: "red", fontSize: "20px" }}
                      />
                      <span>Claim Document</span>
                    </StyledDocument>
                  )}
                  <StyledDocument onClick={() => setOpen(!open)}>
                    <span>Document Checklist</span>
                    <IoMdArrowDropdownCircle
                      style={{ color: theme?.ascentColor, fontSize: "20px" }}
                    />
                  </StyledDocument>
                  {open &&
                    Object.keys(feature?.DocumentRequired).map((key, index) => {
                      return (
                        <DocumentReq key={index}>
                          <FaCheck style={{ color: "green" }} />
                          <span>{key}</span>
                        </DocumentReq>
                      );
                    })}
                </div>
              </Box>
            </Box>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <Container>
      <Sidebar>
        <div>
          <Title variant="h6">Claim Process</Title>
          <SubTitle variant="subtitle1">
            {isLoading ? <Skeleton width="40%" /> : claimProcessDataToSet?.lob}
          </SubTitle>
          {isLoading ? (
            <Skeleton width="40%" />
          ) : (
            <div
              dangerouslySetInnerHTML={{
                __html: claimProcessDataToSet?.lobDescription,
              }}
              style={{ maxHeight: "200px", overflowY: "auto" }}
              variant="subtitle1"
            ></div>
          )}
        </div>
        <div>
          {isLoading ? (
            <>
              {Array.from(new Array(4)).map((_, index) => (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={55}
                  style={{ marginBottom: "20px" }}
                />
              ))}
            </>
          ) : (
            claimProcessDataToSet?.configuratorFeatureRelation
              ?.sort((a, b) => a.sequence - b.sequence)
              ?.map((feature) => (
                <div key={feature.claimProcessConfiguratorFeatureRelationId}>
                  {renderFeature(feature)}
                </div>
              ))
          )}
          <RatingMainBox>
            <Stack>
              <RatingTitle variant="subtitle1">
                Trusted by thousands
              </RatingTitle>
              {isLoading ? (
                <Skeleton variant="rectangular" width={20} height={24} />
              ) : (
                <Rating
                  name="half-rating-read"
                  defaultValue={4.5}
                  precision={0.5}
                  readOnly
                />
              )}
            </Stack>
            <ClaimStatsBox>
              <ClaimStats>
                <RatingNumber variant="h6">
                  {claimCountLoading ? (
                    <Skeleton width={30} />
                  ) : (
                    totalClaimCount
                  )}
                </RatingNumber>
                <RatingNumberTitle variant="body2">
                  Total Claims
                </RatingNumberTitle>
              </ClaimStats>
              <ClaimStats>
                <RatingNumber variant="h6">
                  {claimCountLoading ? <Skeleton width={30} /> : approvedCount}
                </RatingNumber>
                <RatingNumberTitle variant="body2">
                  Claims Settled
                </RatingNumberTitle>
              </ClaimStats>
            </ClaimStatsBox>
          </RatingMainBox>
        </div>
      </Sidebar>
      {isBasicDetailsLoading ? (
        <SkeletonLoader arraySize={15} />
      ) : (
        <Content>
          <Claim />
        </Content>
      )}
    </Container>
  );
};

export default ClaimProcess;

import React, { useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useLoginCustomer } from "./service";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import fyntune from "../../utils/fyntune.png";
//prettier-ignore
import {LoginHeading,  LoginSection,  LoginWrapper,  StyledFont,  StyledImage,  LoginContainer } from "./LoginStyles";

const Login = () => {
  const token = sessionStorage.getItem("token");
  const navigate = useNavigate();

  const loginValidation = yup.object({
    username: yup.string().required("username is required"),
    password: yup.string().required("password is required"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(loginValidation) });

  const { mutate, data } = useLoginCustomer();
  const onLogin = (data) => {
    mutate(data);
  };
  const [showPassword, setShowPassword] = useState(false);
  useEffect(() => {
    if (!_.isEmpty(token)) {
      navigate("/");
    }
  }, [token]);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  return (
    <>
      <div
        style={{
          display: "flex",
          margin: 0,
        }}
      >
        <LoginWrapper>
          <LoginHeading>
            All Your Insurance Policies in
            <StyledFont className="animate-charcter">
              One Convenient Dashboard.
            </StyledFont>
          </LoginHeading>
        </LoginWrapper>
        <LoginSection>
          {/* <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
            <LockOutlinedIcon />
          </Avatar> */}
          <LoginContainer>
            <StyledImage src={fyntune} alt="fyntune" />
            <Typography component="h1" variant="h5" align="left">
              Welcome Back
            </Typography>

            <Box
              component="form"
              onSubmit={handleSubmit(onLogin)}
              sx={{ mt: 1, width: "300px" }}
            >
              <TextField
                margin="normal"
                fullWidth
                id="Username"
                label="Username"
                name="Username"
                autoComplete="Username"
                autoFocus
                {...register("username")}
              />
              {errors && errors?.username && (
                <span style={{ color: "red" }}>
                  {errors?.username?.message}
                </span>
              )}
              <TextField
                margin="normal"
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? "text" : "password"}
                id="password"
                autoComplete="current-password"
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              {errors && errors?.password && (
                <span style={{ color: "red" }}>
                  {errors?.password?.message}
                </span>
              )}

              <Grid container>
                <Grid item xs>
                  <Typography
                    variant="body2"
                    align="right"
                    style={{ marginTop: "10px" }}
                  >
                    <Link to="/forgot-password" style={{ color: "blue" }}>
                      Forgot password?
                    </Link>
                  </Typography>
                </Grid>
              </Grid>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2, backgroundColor: "#2061f8" }}
              >
                LOGIN
              </Button>
            </Box>
          </LoginContainer>
        </LoginSection>
      </div>
    </>
  );
};

export default Login;

import * as yup from "yup";
export const familyFields = [
  { label: "Customer Id", name: "customerId", type: "hidden" },
  { label: "First Name", name: "firstName", type: "text" },
  { label: "Last Name", name: "lastName", type: "text" },
  { label: "Date of Birth", name: "dob", type: "date" },
  { label: "Mobile Number", name: "mobileNumber", type: "number" },
  { label: "Email Id", name: "emailId", type: "text" },
  {
    label: "Gender",
    name: "gender",
    type: "select",
    options: ["Male", "Female", "Other"],
  },
  {
    label: "Relation",
    name: "relation",
    type: "select",
    options: ["Brother", "Sister", "Father", "Mother", "Aunt", "Uncle"],
  },
];
export const profileFields = [
  { label: "First Name", name: "firstName", type: "text" },
  { label: "Last Name", name: "lastName", type: "text" },
  { label: "Date of Birth", name: "dob", type: "date" },
  {
    label: "Gender",
    name: "gender",
    type: "select",
    options: ["Male", "Female", "Other"],
  },
  { label: "Email", name: "emailId", type: "email" },
  { label: "Mobile Number", name: "mobileNumber", type: "number" },
  { label: "Annual Income", name: "annualIncome", type: "number" },
];
export const familyMemberSchema = yup.object().shape({
  customerId: yup.string().nullable(), // Assuming customerId can be nullable
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)?$/, "Invalid First Name")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)?$/, "Invalid Last Name")
    .required("Last name is required"),
  dob: yup
    .date()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .max(new Date(), "Date of birth must be in the past")
    .nullable()
    .required("Date of birth is required"),
  mobileNumber: yup
    .string()
    .matches(/^\d{10}$/, "Invalid mobile number")
    .required("Mobile number is required"),
  emailId: yup
    .string()
    .email("Invalid email format")
    .required("Email is required"),
  relation: yup
    .string()
    .oneOf(["Brother", "Sister", "Father", "Mother", "Aunt", "Uncle"])
    .required("Relation is required"),
  gender: yup
    .string()
    .oneOf(["Male", "Female", "Other"])
    .required("Gender is required"),
});
const personalDetailsSchema = yup.object().shape({
  gender: yup
    .string()
    .oneOf(["Male", "Female", "Other"])
    .required("Gender is required"),
  firstName: yup
    .string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)?$/, "Invalid First Name")
    .required("First name is required"),
  lastName: yup
    .string()
    .matches(/^[A-Za-z]+(?: [A-Za-z]+)?$/, "Invalid Last Name")
    .required("Last name is required"),
  dob: yup
    .date()
    .transform((value, originalValue) => {
      return originalValue === "" ? null : value;
    })
    .max(new Date(), "Date of birth must be in the past")
    .nullable()
    .required("Date of birth is required"),
  emailId: yup
    .string()
    .email("Invalid email")
    .required("Email is required")
    .test("email", "Invalid Email", function (value) {
      // console.log('value', value)
      const emailPattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\.com$/;
      return emailPattern.test(value) || !value;
    }),
  // mobileNumber: yup.string().matches(/^\formatd{10}$/, 'Invalid mobile numbers').required('Mobile number is required'),
  mobileNumber: yup
    .string()
    .required("Mobile number is required")
    .matches(/^[56789]\d{9}/, "Invalid Mobile Number"),
  annualIncome: yup
    .number()
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable()
    .required("Annual income is required")
    .positive("Annual income must be positive"),
});
export const vehicleSchema = yup.object().shape({
  lob_id: yup.string().required("LOB is required"),
  make: yup
    .string()
    .max(50, "Make must be at most 50 characters")
    .required("Vehicle Manufacturer is required"),
  model: yup
    .string()
    .max(50, "Model must be at most 50 characters")
    .required("Vehicle Model is required"),
  version: yup
    .string()
    .max(50, "Variant must be at most 50 characters")
    .required("Vehicle Variant is required"),
  fueltype: yup.string().required("Fuel Type is required"),
  // vehicleRegNo: yup.string().matches(/^[A-Z0-9-]+$/, 'Invalid registration number format').required('Vehicle Registration No is required'),
  kwh: yup
    .number()
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable()
    .required("CC/Kwh is required")
    .positive("CC/Kwh must be a positive number"),
  // bodyType: yup.string().max(50, 'Body Type must be at most 50 characters').required('Vehicle type is required'),
  engineNumber: yup
    .string()
    .max(50, "Engine Number must be at most 50 characters"),
  // .required('Engine Number is required'),
  chassisNumber: yup
    .string()
    // .required("Chassis Number is required")
    // .min(17, "Chassis Number must be atleast 17dsa characters")
    // .max(25, "Chassis Number must be at most 50 characters"),
    .transform((value) => (value ? value.trim() : value))
    .test(
      "len",
      "Chassis Number must be between 17 and 25 characters",
      (value) => {
        return value && value.length >= 17 && value.length <= 25;
      }
    ),
  // .required("Chassis Number is required"),

  // .test("len", "Chassis Number must be at least 17 characters", (val) => {
  //   if (val && val.length >= 17) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // }),
  // .required('Chassis Number is required'),
  // yearOfManufacturing: yup.number().transform((value) => isNaN(value) ? null : parseFloat(value))
  //   .nullable()
  //   .required('Year Of Manufacturing is required')
  //   .integer('Year Of Manufacturing must be an integer')
  //   .min(1886, 'Year must be from 1886 onwards')
  //   .max(new Date().getFullYear(), `Year must be ${new Date().getFullYear()} or earlier`),
  seatingCapacity: yup
    .number()
    .transform((value) => (isNaN(value) ? null : parseFloat(value)))
    .nullable()
    // .required('Seating Capacity is required')
    .integer("Seating Capacity must be an integer")
    .positive("Seating Capacity must be a positive number"),
});
export const addMemberSchema = yup.object().shape({
  add_member: familyMemberSchema,
});
export const editMemberSchema = yup.object().shape({
  edit_member: familyMemberSchema,
});

export const addVehicleSchema = yup.object().shape({
  add_vehicle: vehicleSchema,
});
export const editVehicleSchema = yup.object().shape({
  edit_vehicle: vehicleSchema,
});
export const schema = yup.object().shape({
  personal_details: personalDetailsSchema,
});

export const familyMemberSampleObject = {
  leadRefId: "",
  firstName: "",
  middleName: "",
  lastName: "",
  fullName: "",
  emailId: "",
  mobile_number: "",
  alternateEmailId: "",
  alternateMobileNumber: "",
  gender: "",
  dob: "",
  anniversaryDate: "",
  education: "",
  occupation: "",
  designation: "",
  annualIncome: "",
  panNumber: "",
  aadharNumber: "",
  ucc_number: "",
  businessChannel: "",
  pinCode: 0,
  city: "",
  state: "",
  remark: "",
  isParent: "N",
  hasChild: "N",
  status: "N",
  relation: "",
  imagePath: "",
  userGroup: "",
  address_line1: "",
  address_line2: "",
  landmark: "",
  ckycRefId: "",
  driving_license: "",
  passport_number: "",
  nationality: "",
  maritial_status: "",
  customer_referred_from: "",
  title: "",
  age: 0,
  userTypes: "",
  userTypesId: 0,
  assignedTo: 0,
  assignedToName: "",
};

import { Button, Grid } from "@mui/material";
import React from "react";
import styled from "styled-components";

const FilterButton = ({
  filterTags,
  handleFilterClick,
  filterSelected = "",
  data,
  activeLength,
  inActiveLength,
  notShow,
}) => {
  return (
    <>
      {/* <Grid container lg={12} gap={3}> */}
      {filterTags &&
        filterTags?.map((tag) => {
          return (
            <FilterTagGrid
              key={tag}
              lg={"auto"}
              xs={12}
              active={filterSelected === tag}
            >
              <FilterTagButton
                filterSelected={filterSelected}
                tag={tag}
                onClick={() => handleFilterClick(tag)}
              >
                <FilterTagName active={filterSelected === tag}>
                  {tag}
                </FilterTagName>
                {notShow !== tag && (
                  <FilterTagCount active={filterSelected === tag}>
                    {tag === "Active"
                      ? activeLength
                      : tag === "Inactive"
                      ? inActiveLength
                      : data}
                  </FilterTagCount>
                )}
              </FilterTagButton>
            </FilterTagGrid>
          );
        })}
      {/* </Grid> */}
    </>
  );
};

export default FilterButton;

export const FilterTagName = styled.span`
  color: ${({ active }) => (active ? "#000000DE" : "#00000052")};
  font-weight: 600;
  font-size: 0.8rem;
  line-height: 1rem;
  text-transform: capitalize;
`;

export const FilterTagButton = styled(Button)`
  width: 100%;
  padding: 4px 12px 4px 12px;
  min-height: 46px;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FilterTagGrid = styled(Grid)`
  &::after {
    content: "";
    display: block;
    height: ${({ active }) => active && "4px"};
    border-radius: 4px 4px 0px 0px;
    background: #2c65f5;
    transition: width 0.3s;
    width: ${({ active }) => active && "100%"};
    font-family: ${({ theme }) => theme?.regularFont || "Manrope"};
  }
`;

export const FilterTagCount = styled.span`
  width: 26px;
  height: 22px;
  border-radius: 30px;
  color: ${({ active }) => (active ? "#FFFFFF" : "#00000052")};
  background: ${({ active }) => (active ? "#344bfd73" : "#EBEBEB")};
`;

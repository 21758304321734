import React from "react";
import { Select } from "../../components";
import styled from "styled-components";
import { Grid, StyledEngineProvider } from "@mui/material";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  LineElement,
  PointElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend
);

const optionsData = [
  {
    id: "",
    name: "Select",
    value: "",
  },
  {
    id: 2,
    name: "Data",
    value: "data",
  },
];

export const LineChart = () => {
  const data = {
    labels: [
      "Motor",
      "Fire",
      "Liability",
      "Health",
      "Travel",
      "Crop",
      "Cattle",
      "Marine",
      "Misc",
    ],
    datasets: [
      {
        label: "Dataset 1",
        data: [
          420000, 470000, 390000, 500000, 495000, 520000, 420000, 370000,
          390000,
        ],
        tension: 0.4,
        borderColor: "#FF004D",
        // backgroundColor: ["red", "blue", "green"],
      },
    ],
  };

  const options = {
    legend: { display: true, position: "right" },

    datalabels: {
      display: true,
      color: "white",
    },
    tooltips: {
      backgroundColor: "#5a6e7f",
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: false,
        },
      },
    },
  };
  return (
    <>
      <InputWrape>
        <div>
          <Select
            themeType="theme1"
            name="select_channel"
            id="select1"
            value="select_channel"
            label="Select Channel"
            options={optionsData}
          />
        </div>
        <div>
          <Select
            themeType="theme1"
            name="select_region"
            id="select2"
            value="select_region"
            label="Select Region"
            options={optionsData}
          />
        </div>
        <div>
          <Select
            themeType="theme1"
            name="select_type"
            id="select3"
            value="select_type"
            label="Business Type"
            options={optionsData}
          />
        </div>
      </InputWrape>
      <div style={{ width: "auto", height: "250px", marginLeft: "20px" }}>
        <Line data={data} options={options}></Line>
      </div>
    </>
  );
};

const InputWrape = styled.div`
  display: flex;

  gap: 10px;
  margin-top: 10px;
  margin-bottom: 20px;
  @media (max-width: 1420px) {
    flex-wrap: wrap;
  }
  div {
    flex: 1;
  }
`;

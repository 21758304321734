import React from "react";
import "./loading.css";

const LoadingComponent = () => {
  return (
    // <div class="container-loader">
    //   <div class="ring"></div>
    //   <div class="ring"></div>
    //   <div class="ring"></div>
    // </div>
    <>
      <div class="parent-loader-container">
        <div class="loader">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>

        <div class="loader loader--reflect">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
      </div>
    </>
  );
};

export default LoadingComponent;

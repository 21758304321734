import { useMutation, useQueries, useQuery } from "@tanstack/react-query";
import httpClient from "../../api/httpClient";

export const useGetPolicyListing = () => {
  const { data } = useQuery(["policyListing"], () =>
    httpClient(`policy_master/listPaging`, {
      method: "POST",
      data: {
        page: 0,
        size: 0,
        sortBy: "",
        sortOrder: "",
        search: {
          lobId: 0,
          policynumber: "",

          status: "",
        },
      },
    })
  );
  return { data };
};

export const useAddPolicyPdf = () => {
  const { mutate, data } = useMutation((payload) =>
    httpClient(`policy_master/add`, { method: "POST", data: payload })
  );
  return { mutate, data };
};

export const useGetLobList = () => {
  const { data } = useQuery(["lobList"], () => httpClient(`lob/list`));
  return { data };
};

export const useGetMasterData = (tableName) => {
  const fetchMenuMasterData = (tableName) => {
    return httpClient(
      `segregation/get_data/${tableName?.masterSlug}/${tableName.fieldSlug}?lobId=${tableName.lobId}`
    ).then((response) => response.data);
  };

  const data = useQueries({
    queries: tableName.map((tableName) => {
      return {
        queryKey: [
          "master-table",
          tableName?.lobId
            ? tableName?.masterSlug + tableName?.fieldSlug + tableName?.lobId
            : tableName?.masterSlug + tableName?.fieldSlug,
        ],
        queryFn: () => fetchMenuMasterData(tableName),
      };
    }),
  });
  return { data };
};

import {
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
// import HttpClient from "../../../api/httpClient";
// import {
//   setBrokerage,
//   setIdAction,
//   setProposalErrorAction,
//   setProposalPartialUpdateSuccess,
//   setProposalSuccessAction,
// } from "../proposal.slice";
import Swal from "sweetalert2";
import HttpClient from "../../../api/httpClient";
import {
  setBrokerage,
  setIdAction,
  setProposalErrorAction,
  setProposalPartialUpdateSuccess,
  setProposalSuccessAction,
} from "../proposal.slice";
import httpClient from "../../../api/httpClient";

export const useGetProposalValues = ({ lobId, userId }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["proposal_view_values"],
    queryFn: () =>
      HttpClient(`transaction_summary/list/${lobId}?id=${userId}`, {
        method: "POST",
      }),
    enabled: lobId && userId ? true : false,
    cacheTime: 0,
    staleTime: 0,
  });
  return { data, isLoading };
};

export const useCreateProposalData = () => {
  const dispatch = useDispatch();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient("claim_draft/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        if (!data.status) {
          dispatch(setProposalErrorAction(data.message));
          return;
        }
        dispatch(setIdAction(data?.data?.data));
        dispatch(setProposalSuccessAction(data.message));
      },
    }
  );

  return { mutate };
};

export const useCreateProposalMongoId = () => {
  const currentLocation = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { mutate, isLoading, isError } = useMutation(
    (payload) => {
      // return HttpClient("/transaction-field-draft/update-draft", {
      return HttpClient("claim_draft/add", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        const mongoId = data?.data?.data;
        const queryParams = new URLSearchParams(currentLocation.search);
        queryParams.set("mongoId", mongoId);
        const setMongoId = queryParams.toString();
        navigate(`?${setMongoId}`);
        dispatch(setProposalPartialUpdateSuccess(true));
      },
    }
  );
  return { mutate, isLoading, isError };
};

export const useUpdateExistingProposalData = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, data } = useMutation(
    ({ payload, mongoId: id }) => {
      // return HttpClient(`/transaction-field-draft/${id}`, {
      return HttpClient(`claim_draft/update/${id}`, {
        method: "PUT",
        data: payload,
      });
    },
    {
      onSuccess: () => {
        // sessionStorage.setItem("id", data?.data?.data?.user_temp_id);
        // dispatch(setProposalPartialUpdateSuccess(true));
        queryClient.invalidateQueries(["proposalGetById"]);
      },
    }
  );
  return { mutate, isLoading, isError, data };
};

export const useCreateFinalSubmit = (mongodbId) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const { mutate, isLoading, data } = useMutation(
    ({ id }) => {
      return HttpClient(`claim-final/Final-Submit/${mongodbId}`, {
        method: "POST",
        data: { id },
      });
    },
    {
      onSuccess: (data) => {
        // if (!data?.data?.status) {
        //   dispatch(
        //     setProposalErrorAction(
        //       data?.data?.errorData
        //         ? Object.values(data?.data?.errorData)
        //         : data?.data?.message,
        //     ),
        //   );
        //   return;
        // }
        Swal.fire({
          text: data?.data?.message,
          icon: data?.data?.errortype,
        });
        const transactionId = data?.data?.data;
        const queryParams = new URLSearchParams(location.search);
        // queryParams.set("transactionId", transactionId ? transactionId : "");
        navigate("/claims");
        dispatch(setBrokerage({ transactionId: data?.data?.data }));
        // dispatch(setProposalSuccessAction(data.message));
      },
    }
  );
  return { mutate, isLoading, data };
};

// export const useCreateFinalSubmitInEditFromRenewal = MongoId => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const location = useLocation();

//   const { mutate, isLoading } = useMutation(
//     ({ id, oldTransactionId }) => {
//       return HttpClient(`claim-final/Final-Submit/${MongoId}`, {
//         method: "POST",
//         data: { id, oldTransactionId },
//       });
//     },
//     {
//       onSuccess: data => {
//         if (!data?.data?.status) {
//           dispatch(
//             setProposalErrorAction(
//               data?.data?.errorData
//                 ? Object.values(data?.data?.errorData)
//                 : data?.data?.message,
//             ),
//           );
//           return;
//         }
//         const transactionId = data?.data?.data;
//         const queryParams = new URLSearchParams(location.search);
//         queryParams.set("transactionId", transactionId);
//         navigate(`?${queryParams.toString()}`);
//         dispatch(setBrokerage({ transactionId: data?.data?.data }));
//         dispatch(setProposalSuccessAction(data.message));
//       },
//     },
//   );
//   return { mutate, isLoading };
// };

export const useUpdateFinalSubmit = () => {
  const { mutate, isLoading, data } = useMutation(
    (payload) => {
      const { lobid, data } = payload;
      return HttpClient(
        `/transaction-field-draft/final-submit/update/${payload.id}`,
        {
          method: "PUT",
          data: { lobid, data },
        }
      );
    }
    // {
    //   onSuccess: (data) => {
    //     if (!data?.data?.status) {
    //       dispatch(setProposalErrorAction(data.message));
    //       return;
    //     }
    //     dispatch(setProposalSuccessAction(data.message));
    //   },
    // }
  );
  return { mutate, isLoading, data };
};

export const useGetProposalDataById = (id) => {
  const { data, isError, isLoading } = useQuery({
    queryKey: ["proposalGetById", id],
    // queryFn: () => HttpClient(`transaction-field-draft/${id}`),
    queryFn: () => HttpClient(`claim_draft/listById/${id}`),
    enabled: id ? true : false,
  });

  return { data, isError, isLoading };
};

export const useGetRestructuredBulkUploadProposalData = (isBulkUploadData) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (payload) => {
      return HttpClient("transaction-field-draft/process_bulk_upload", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["proposalGetById"]);
      },
    },
    {
      enabled: isBulkUploadData,
    }
  );
  return { mutate };
};

export const useGetManufacturerData = (lobid) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getManufacturerData", lobid],
    queryFn: () =>
      HttpClient("lob_manufacturer/get-manufacturer", {
        method: "POST",
        data: { lobid },
      }),
    enabled: lobid ? true : false,
  });
  return { data, isLoading };
};

export const useGetModelData = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-model", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionData = () => {
  // version numbers based on model no
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-version", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetFuelData = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("/lob_manufacturer/get-version-fuel-type", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetVersionDetails = () => {
  // entire mmv data using version number
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("lob_manufacturer/get-version-details", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};
export const useGetRewardCalculate = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("reward_configurator/calculator", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetBrokerageCalculate = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient("brokerage_Configurator/calculate", {
      method: "POST",
      data: payload,
    });
  });
  return { data, mutate, isLoading };
};

export const useGetTransactionType = (lobId) => {
  const { data } = useQuery({
    queryKey: ["transactionType", lobId],
    queryFn: () =>
      HttpClient("transaction/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchParam: {
            lobId: lobId,
            transactionTypeId: "",
          },
        },
      }),
  });
  return { data };
};

export const useGetBankDetails = (ifscCode) => {
  const { data } = useQuery({
    queryKey: ["ifscCode", ifscCode],
    queryFn: () =>
      HttpClient("/master_bank/get_master_bank", {
        method: "POST",
        data: { ifscCode: ifscCode },
      }),
    enabled: Boolean(ifscCode && ifscCode.length === 11),
  });
  return { data };
};

export const useGetBankDataFromIfsc = (ifscCodeArray) => {
  const ifscCodeDestructed = Array.isArray(ifscCodeArray)
    ? ifscCodeArray?.map((value) => value.ifscCode)
    : [];

  const fetchMenuMasterData = (ifscCode) => {
    return HttpClient("/master_bank/get_master_bank", {
      method: "POST",
      data: { ifscCode },
    }).then((response) => response.data);
  };

  const data = useQueries({
    queries: ifscCodeDestructed.map((ifscCode) => {
      return {
        queryKey: ["ifsc-code", ifscCode],
        queryFn: () => fetchMenuMasterData(ifscCode),
        enabled: Boolean(ifscCode && ifscCode.length === 11),
        cacheTime: 0,
        staleTime: 0,
      };
    }),
  });
  return { data };
};

export const useGetICBranchDetails = (companyId) => {
  const { data } = useQuery({
    // queryKey: ["branchDetails"],
    queryKey: ["branchDetails", companyId],
    queryFn: () => HttpClient(`masterBranch/list_ic_id/${companyId}`),
    enabled: companyId ? true : false,
  });
  return { data };
};

export const useGetBranchLocation = () => {
  const payload = {
    page: 0,
    size: 0,
    sortBy: "",
    sortOrder: "",
    officeCode: "",
    pincode: "",
  };
  const { data } = useQuery({
    queryKey: ["branchLocation"],
    queryFn: () =>
      HttpClient(`location/list`, {
        method: "POST",
        data: payload,
      }),
  });
  return { data: data?.data?.data };
};

export const useCheckIfPolicyIsPresent = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data, mutate, isLoading } = useMutation(
    (payload) => {
      return HttpClient("transaction-field-draft/policy_check", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        const transactionId = data?.data?.data?.transaction_id?.toString();
        const queryParams = new URLSearchParams(location.search);
        // queryParams.set("transactionId", transactionId && transactionId);
        navigate(`?${queryParams.toString()}`);
      },
    }
  );
  return { data, mutate, isLoading };
};

// export const useCheckIfPolicyIsPresentOrNot = (payloadObject) => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const { data, mutate, isLoading } = useMutation(
//     (payload) => {
//       return HttpClient(
//         `policy_master/list/${payloadObject?.lobId}?id=${payloadObject?.transactionIdParams}`,
//         {
//           method: "POST",
//           data: payload,
//         }
//       );
//     },
//     {
//       onSuccess: (data) => {
//         const transactionId = data?.data?.data?.transaction_id?.toString();
//         const queryParams = new URLSearchParams(location.search);
//         // queryParams.set("transactionId", transactionId && transactionId);
//         navigate(`?${queryParams.toString()}`);
//       },
//     }
//   );
//   return { data, mutate, isLoading };
// };

export const useCheckIfPolicyIsPresentOrNot = (payloadObject) => {
  const navigate = useNavigate();
  const location = useLocation();

  const fetchPolicyData = async () => {
    const response = await HttpClient(
      `policy_master/list/${payloadObject?.lobId}?is_claim_basic=Y&id=${payloadObject?.transactionIdParams}`,
      // `policy_master/list/${payloadObject?.lobId}/${payloadObject?.transactionIdParams}`,
      {
        method: "POST",
        // method: "GET",
        // data: payloadObject,
      }
    );
    return response.data;
  };

  const { data, isLoading, error } = useQuery(
    ["fetchPolicyData", payloadObject],
    fetchPolicyData,
    {
      onSuccess: (data) => {
        const transactionId = data?.data?.transaction_id?.toString();
        const queryParams = new URLSearchParams(location.search);
        // queryParams.set("transactionId", transactionId && transactionId);
        navigate(`?${queryParams.toString()}`);
      },
    }
  );

  return { data, isLoading, error };
};

export const useUploadProposalFile = () => {
  const { data, mutate, isLoading, isError } = useMutation(
    (payload) => {
      return HttpClient("segregation/upload-file", {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        Swal.fire({
          text: data?.data?.message,
          icon: data?.data?.errortype,
        });
      },
    }
  );
  return { data, mutate, isLoading, isError };
};

export const useGetUserById = ({ userId }) => {
  const { data } = useQuery({
    queryKey: ["user_by_id", userId],
    queryFn: () => HttpClient(`users/user_type/${userId}`),
    enabled: userId ? true : false,
  });
  return { data };
};

export const useGetAllCustomers = () => {
  const { data, mutate, isLoading } = useMutation(
    ({ firstName, customerRefId }) => {
      return HttpClient("customer_records/listPaging", {
        method: "POST",
        data: customerRefId
          ? {
            firstName: "",
            customerRefId,
          }
          : {
            firstName,
            customerRefId: "",
          },
      });
    }
  );
  return { data, mutate, isLoading };
};

export const useAddCustomerFromPolicyEntry = () => {
  const { data, mutate, isLoading } = useMutation((payload) => {
    return HttpClient(`/customer_records/add?${payload}`, {
      method: "POST",
    });
  });
  return { data, mutate, isLoading };
};

export const useGetProductsList = (searchValue) => {
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["products", searchValue],
    queryFn: () =>
      HttpClient("product_master/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: searchValue ? { searchValue } : {},
        },
      }),
  });
  return { data, refetch, isFetching };
};

export const useGetProductsListPolicyEntry = ({ icId, product }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const lobId = queryParams.get("lobId");
  const { data, refetch, isFetching } = useQuery({
    queryKey: ["products", icId, product],
    queryFn: () =>
      HttpClient("product_master/list", {
        method: "POST",
        data: {
          page: 0,
          size: 0,
          sortBy: "",
          sortOrder: "",
          searchProduct: { productName: product, companyId: icId, lobId } || {},
        },
      }),
    // enabled: !!icId,
  });
  return { data, refetch, isFetching };
};

export const useGetCustomer = () => {
  // customer ref id or firstName
  const { mutate, data, isLoading } = useMutation((payload) => {
    return HttpClient(`claim_details/listPaging`, {
      method: "POST",
      data: payload,
    });
  });

  return { mutate, data, isLoading };
};
export const useGetBankDetailsByIFSC = () => {
  const { mutate, data, isLoading } = useMutation((ifsc) => {
    return httpClient("common_master/get_master_bank", {
      method: "POST",
      data: { ifscCode: ifsc },
    });
  });
  return { mutate, data, isLoading };
};
export const useUpdateTransactionStage = (transactionStage) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { mutate, isLoading } = useMutation(
    (payload) => {
      return HttpClient(`transaction-field-draft/updateTransactionStage`, {
        method: "POST",
        data: payload,
      });
    },
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(["proposalGetById"]);
        Swal.fire({
          text: data?.data?.status ? data?.data?.data : data?.data?.message,
          icon: data?.data?.errortype,
          confirmButtonText: "OK",
          confirmButtonColor: "#DC004E",
          showCancelButton: false,
          reverseButtons: true,
          focusConfirm: true,
          focusCancel: false,
          scrollbarPadding: false,
          showCloseButton: true,
        }).then(() => {
          if (transactionStage && transactionStage != "Policy Issued") {
            navigate("/proposals");
          }
        });
      },
    }
  );
  return {
    updateTransactionStage: mutate,
    isLoadingUpdateTransactionStage: isLoading,
  };
};

export const useGetCustomerById = ({ id }) => {
  // customer id
  const { data, isLoading } = useQuery({
    queryKey: ["customer_by_id", id],
    queryFn: () => HttpClient(`/customer_records/List/${id}`),
    enabled: id ? true : false,
  });
  return { data, isLoading };
};

export const useGetStateCityFromPincode = (pincode, key, setValue) => {
  const { data } = useQuery({
    queryKey: ["pincode", pincode],
    queryFn: () => HttpClient(`pincode/list/${pincode}`),
    enabled: Boolean(pincode && String(pincode).length === 6),
    onSuccess: (data) => {
      if (!data?.data.status && key && setValue) {
        Swal.fire({
          text: `Data for pincode (${pincode}) is not available`,
          icon: data?.alertType,
          confirmButtonText: "OK",
          confirmButtonColor: "#3085d6",
          // confirmButtonColor:"#11555F",
        }).then(() => setValue(key, null));
      }
    },
  });

  return { data };
};

export const useGetLeadById = (id) => {
  const { data } = useQuery({
    queryKey: ["LeadById", id],
    queryFn: () => HttpClient(`lead_master_controller/list/${id}`),
    enabled: id ? true : false,
  });
  return { data };
};

export const useGetClaimProcessDataById = () => {
  const { mutate, data, isLoading } = useMutation((payload) => {
    return httpClient("listByLobId", {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data, isLoading };
};

export const useGetClaimCount = () => {
  const { mutate, data, isLoading } = useMutation((payload) => {
    return httpClient("fetch_customer_claim_count", {
      method: "POST",
      data: payload,
    });
  });
  return { mutate, data, isLoading };
};

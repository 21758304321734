import { Box, Grid, Typography } from '@mui/material';

function NoRowsOverlay() {
    return (
        <Grid>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%',
                }}
            >
                <Typography variant="h6" fontWeight={"bold"} color={"gray"}>No Data Available</Typography>
            </Box>
        </Grid>
    );
}

export default NoRowsOverlay;